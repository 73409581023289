import { useState, useEffect, useRef, useContext } from "react"

// Libraries
import { v4 as uuidv4 } from "uuid"
import { useLocation } from "react-router-dom"
import Papa from "papaparse"

// MUI
import { DataGrid } from "@mui/x-data-grid"

// Context
import AppContext from "../../../AppContext"

// Data
import { apiCalls, QueryParamTypes } from "../../DataService"
import SAPBR_RMD_Data_Loader from "./SAPBR_RMD_Data_Loader"

// Custom
import RFIGridToolbar from "../../RFIs/RFIGridToolbar"
import { RequestResubmissionWarningModal } from "../../RFIs/RequestResubmissionWarningModal"

import SnackbarMessages from "../../SnackbarMessages"
import { TableControls } from "../../RFIs/TableControls"
import SAPBR_Utils from "./SAPBR_Utils"
import { SubmitRFIWarningModal } from "../../RFIs/SubmitRFIWarningModal"
import UploadProgressModal from "../../RFIs/UploadProgressModal"
import ReviewWarningsModal from "../../RFIs/ReviewWarningsModal"
import {
  DASA_DEC_ORG_ID,
  SubmitStatus,
  MinTableHeight,
  getQueryParamsOnTableUpdateFromDB,
  updateMUITableWithWarningsErrorsRows,
  CSVBulkUploadReqHeaders,
  formatHeader,
  getWarningsErrorsTableRowsFromUploadedTableRows,
  UploadSubmitEnabledStatuses,
  SelectedTabToRFIMapping,
  returnErrorMessageFromAPIError,
  fetchPrevCsvFileData,
  CurrentFiscalYear,
  CSVUploadProgressMessageTypes,
} from "../../../Utils"

// Styles
import "../../RFIs/RFI.css"
import { useQueries } from "@tanstack/react-query"
import { Box, Stack, Tab, Typography, Button } from "@mui/material"
import { TabContext, TabList } from "@mui/lab"
import { Help } from "@mui/icons-material"
import RfiStatusChip from "../../custom/RfiStatusChip"

const SAPBR = () => {
  // Initialize App-level Data
  const context = useContext(AppContext)
  const { filterOrganizations, activeFiscalCycle } = context

  // For now, going to make each page responsible for providing what it supports but there is a mapping in utils (RFIViewOrgMappings)
  useEffect(() => {
    filterOrganizations(["DASA DE&C"])
  }, [])

  // ** --------------***     [ ~ HOOKS ~ ]     ***-------------- **
  // Location
  const location = useLocation()

  // Refs
  const renderingDBRows = useRef(true)
  const numTotalTableRows = useRef({
    [SAPBR_Utils.Tabs.SAPBR]: 0,
    [SAPBR_Utils.Tabs.RMD]: 0,
  })
  const matchedHeaderCount = useRef(0)
  const pageSize = useRef(100)
  const pageNumber = useRef(0)
  const fileSelector = useRef(null)
  const onlyWarningsInUpload = useRef(false)

  // State
  const [submitStatus, setSubmitStatus] = useState({})
  const [canUploadCSVData, setCanUploadCSVData] = useState({})
  const [canSubmitRFIData, setCanSubmitRFIData] = useState({})
  const [canExportRFIData, setCanExportRFIData] = useState({})
  const [selectedTableCols, setSelectedTableCols] = useState(SAPBR_Utils.SAPBR_COLS)
  const [selectedTableRows, setSelectedTableRows] = useState([])
  const [uploadedTableRows, setUploadedTableRows] = useState([])
  const [warningsErrorsTableRows, setWarningsErrorsTableRows] = useState([])
  const [warningsErrorsTableRowsWarningsErrorsOnly, setWarningsErrorsTableRowsWarningsErrorsOnly] = useState([])
  const [showWarningUploadModal, setShowWarningUploadModal] = useState(false)
  const [showRequestResubmissionWarningModal, setShowRequestResubmissionWarningModal] = useState(false)
  const [showUploadProgressModal, setShowUploadProgressModal] = useState(false)
  const [loadingTitle, setLoadingTitle] = useState("")
  const [selectedRFI, setSelectedRFI] = useState({})
  const [MUITableHeight, setMUITableHeight] = useState(800)
  const [selectedTab, setSelectedTab] = useState(SAPBR_Utils.Tabs.SAPBR)
  const [fileSize, setFileSize] = useState(0)
  const [uploadCompleted, setUploadCompleted] = useState(false)
  const [MUISortModel, setMUISortModel] = useState([])
  const [showingWarningErrorUpload, setShowingWarningErrorUpload] = useState(false)
  const [toggleWarningsErrors, setToggleWarningsErrors] = useState(false)
  const [MUIFilter, setMUIFilter] = useState({})
  const [showSnackbarSuccess, setShowSnackbarSuccess] = useState(false)
  const [showSnackbarError, setShowSnackbarError] = useState(false)
  const [snackbarMessage, setSnackbarMessage] = useState("")
  const [showSubmitRFIWarningModal, setShowSubmitRFIWarningModal] = useState(false)
  const [showBusyDialog, setShowBusyDialog] = useState(false)
  const [disclaimerModalOpen, setDisclaimerModalOpen] = useState(false)

  // ** --------------***     [ ~ VARS ~ ]     ***-------------- **

  const results = useQueries({
    queries: [
      { queryKey: [SAPBR_Utils.UrlApiTypes[selectedTab].rfi], queryFn: () => apiCalls.fetchData(SAPBR_Utils.UrlApiTypes[selectedTab].rfi) },
      { queryKey: ["Accounts/me"], queryFn: () => apiCalls.fetchData("Accounts/me") },
    ],
  })

  // ** --------------***     [ ~ EFFECTS ~ ]     ***-------------- **
  useEffect(() => {
    updateTableRows()
  }, [])

  useEffect(() => {
    if (MUIFilter?.value != null) {
      pageNumber.current = 0
      updateTableRows()
    }
  }, [MUIFilter])

  useEffect(() => {
    if (MUISortModel[0]) {
      pageNumber.current = 0
      setShowBusyDialog(true)
      updateTableRows()
    }
  }, [MUISortModel])

  useEffect(() => {
    getTableSubmitStatus()
  }, [selectedTab])

  useEffect(() => {
    renderingDBRows.current = true
    onlyWarningsInUpload.current = false
    setShowingWarningErrorUpload(false)
    if (activeFiscalCycle?.id) {
      getSelectedRFI()
    }
    setMUISortModel([])
  }, [activeFiscalCycle, selectedTab])

  useEffect(() => {
    setWarningsErrorsTableRows([])
    if (uploadedTableRows?.length > 0) {
      persistDBData()
    } else {
      if (madeFirstSubmissionOfSession.current) {
        setShowSnackbarError(true)
        setSnackbarMessage("Some or all of the headers are incorrect or missing in the uploaded file. Please check the file and try again.")
      }
      setShowUploadProgressModal(false)
    }
  }, [uploadedTableRows])

  useEffect(() => {
    const warningErrorsTableRowsForDisplay = toggleWarningsErrors ? warningsErrorsTableRowsWarningsErrorsOnly : warningsErrorsTableRows
    pageNumber.current = 0
    const skip = 0
    updateMUITableWithWarningsErrorsRows(
      MUIFilter,
      MUISortModel,
      warningErrorsTableRowsForDisplay,
      origionalWarningsErrorsTableRows,
      numTotalTableRows,
      setSelectedTableRows,
      selectedTab,
      skip,
      pageSize,
      SAPBR_Utils.NumericFields[selectedTab],
      setShowSnackbarError,
      setSnackbarMessage
    )
  }, [showingWarningErrorUpload, toggleWarningsErrors])

  useEffect(() => {
    if (warningsErrorsTableRows?.length > 0) {
      updateTableRows()
    }
  }, [warningsErrorsTableRows])

  useEffect(() => {
    getTableSubmitStatus()
  }, [selectedTableRows])

  useEffect(() => {
    setShowBusyDialog(true)
    if (MUIFilter?.value && MUIFilter.value.length > 0) {
      setMUIFilter({
        tableField: "",
        dataField: "",
        operator: "equals",
        value: "",
      })
    } else {
      updateTableRows()
    }
    getTableSubmitStatus()
  }, [selectedRFI])

  useEffect(() => {
    adjustTableHeight()
    if (location.state?.selectedTab) {
      setSelectedTab(location.state.selectedTab)
    }
  }, [location])

  useEffect(() => {
    window.addEventListener("resize", adjustTableHeight)
    // getFiscalCyclesFromDB()
  }, [])

  // ** --------------***     [ ~ FUNCTIONS ~ ]     ***-------------- **
  const getSelectedRFI = async () => {
    const selectedTabRFIs = await apiCalls.getAll(SAPBR_Utils.UrlApiTypes[selectedTab].rfi, {})

    const RFI = selectedTabRFIs.find((RFI) => RFI.fiscalCycleId === activeFiscalCycle.id)

    if (!RFI) {
      setSelectedRFI({})
      return
    }
    setSelectedRFI(RFI)
    pageNumber.current = 0
  }

  const getTableSubmitStatus = async () => {
    setSubmitStatus({ ...submitStatus, [selectedTab]: selectedRFI?.status ? selectedRFI.status : SubmitStatus.NOT_SUBMITTED })

    setCanSubmitRFIData({
      ...canSubmitRFIData,
      [selectedTab]:
        (renderingDBRows.current || onlyWarningsInUpload.current) &&
        (!selectedRFI?.status || UploadSubmitEnabledStatuses.includes(selectedRFI?.status)) &&
        selectedTableRows.length > 0
          ? true
          : false,
    })
    setCanUploadCSVData({
      ...canUploadCSVData,
      [selectedTab]: !selectedRFI?.status || UploadSubmitEnabledStatuses.includes(selectedRFI?.status) ? true : false,
    })
    setCanExportRFIData({ ...canExportRFIData, [selectedTab]: selectedTableRows.length > 0 })
  }

  // Overwrite any existing csv entries in the DB with the new data
  const submitRFIData = async () => {
    setShowBusyDialog(true)
    apiCalls
      .put(`${SAPBR_Utils.UrlApiTypes[selectedTab].rfi}/${selectedRFI.id}`, { ...selectedRFI, status: SubmitStatus.SUBMITTED })
      .then(() => {
        getSelectedRFI()
        setShowSnackbarSuccess(true)
        setSnackbarMessage("RFI Submitted Successfully!")
      })
      .catch(() => {
        setShowSnackbarError(true)
        setSnackbarMessage("Failed to submit RFI data")
      })
      .finally(() => setShowBusyDialog(false))
  }

  const requestResubmission = () => {
    setShowBusyDialog(true)
    apiCalls
      .put(`${SAPBR_Utils.UrlApiTypes[selectedTab].rfi}/${selectedRFI.id}`, { ...selectedRFI, status: SubmitStatus.REQUEST_RESUBMISSION })
      .then(() => {
        getSelectedRFI()
        setShowSnackbarSuccess(true)
        setSnackbarMessage("Resubmission requested for RFI!")
      })
      .catch(() => {
        setShowSnackbarError(true)
        setSnackbarMessage("Failed to request resubmission for RFI")
      })
      .finally(() => setShowBusyDialog(false))
  }

  const restorePrevDBData = async (tmpSelectedRFI) => {
    setShowUploadProgressModal(true)
    //setLoadingTitle(CSVUploadProgressMessageTypes.RESTORE)
  }

  const bulkUploadCSVDataToDB = () => {
    apiCalls
      .getAll(`${SAPBR_Utils.UrlApiTypes[selectedTab].rfi}`, {})
      .then((RFIs) => {
        const tmpSelectedRFI = RFIs.find((RFI) => RFI.fiscalCycleId === activeFiscalCycle.id)
        let formData = new FormData()
        formData.append("FormFile", new Blob([fileSelector.current.files[0]]))
        let warningsList = null
        let errorsList = null
        apiCalls
          .post(
            `${SAPBR_Utils.UrlApiTypes[selectedTab].record}/${tmpSelectedRFI.id}/BulkUpload?fiscalYear=${activeFiscalCycle.fiscalYear}&replaceData=false`,
            formData,
            CSVBulkUploadReqHeaders
          )
          .then((res) => {
            warningsList = res.data.warningsList
            if (warningsList && warningsList.length > 0) {
              renderingDBRows.current = false
              onlyWarningsInUpload.current = true
              setShowingWarningErrorUpload(true)
              setShowWarningUploadModal(true)
            } else renderingDBRows.current = true
            setShowSnackbarSuccess(true)
            setSnackbarMessage(`Successfully uploaded ${selectedTab} data`)
            getSelectedRFI()
            setUploadCompleted(true)
            setShowUploadProgressModal(false)
          })
          .catch((e) => {
            const errObj = e.response.data
            setShowSnackbarError(true)
            renderingDBRows.current = false
            restorePrevDBData(tmpSelectedRFI)
            const errMessage = returnErrorMessageFromAPIError(e)
            if (errMessage) {
              setSnackbarMessage(errMessage)
            }
            if (errObj.topLevelError) {
              setSnackbarMessage(errObj.topLevelError)
              return
            }
            if (errObj.error) {
              setSnackbarMessage(errObj.error)
              return
            }
            if (errObj?.fileError) {
              setSnackbarMessage(errObj.fileError)
              return
            }
            if (errObj && errObj?.fileErrors) {
              // If there are file errors, display them in a snackbar message
              let msg = "Some or all of the headers are incorrect or missing in the uploaded file. Please check the file and try again."
              setSnackbarMessage(msg)
            } else {
              // If there are row errors, display them in the table
              if (errObj && errObj.length > 0) {
                errorsList = errObj.filter((err) => err.code === "Error")
                warningsList = errObj.filter((err) => err.code === "Warning")
                setShowingWarningErrorUpload(true)
                setSnackbarMessage(`Errors found in ${fileSelector.current.files[0].name}`)
              } else {
                setSnackbarMessage("unknown error: please check your internet connection and make sure your file is formatted correctly")
              }
            }
          })
          .finally(() => {
            if (errorsList || warningsList) {
              const { tmpWarningsErrorsTableRows, tmpWarningsErrorsTableRowsWarningsErrorsOnly } = getWarningsErrorsTableRowsFromUploadedTableRows(
                uploadedTableRows,
                errorsList,
                warningsList,
                headerRowNumber.current + 1
              )
              setWarningsErrorsTableRows(tmpWarningsErrorsTableRows)
              setWarningsErrorsTableRowsWarningsErrorsOnly(tmpWarningsErrorsTableRowsWarningsErrorsOnly)
            }
            setFileSize(0)
            fileSelector.current.value = null
          })
      })
      .catch((e) => {
        const errMessage = returnErrorMessageFromAPIError(e)
        setShowSnackbarError(true)
        if (errMessage) {
          setSnackbarMessage(errMessage)
        } else {
          setSnackbarMessage("Unable to load data, please refresh the page and try again")
        }
      })
  }

  const persistDBData = () => {
    apiCalls.getAll(`${SAPBR_Utils.UrlApiTypes[selectedTab].rfi}`, {}).then((allRFIs) => {
      if (allRFIs?.length <= 0) {
        apiCalls
          .post(`${SAPBR_Utils.UrlApiTypes[selectedTab].rfi}`, {
            organizationId: DASA_DEC_ORG_ID,
            fiscalCycleId: activeFiscalCycle.id,
            poc: null,
          })
          .then(() => {
            setLoadingTitle(CSVUploadProgressMessageTypes.POST)
            bulkUploadCSVDataToDB()
          })
      } else {
        const FCIdPairs = allRFIs.map((data) => {
          return { id: data.id, fcId: data.fiscalCycleId }
        })
        const selectedFCIdPair = FCIdPairs.find((pair) => pair.fcId === activeFiscalCycle.id)
        if (!selectedFCIdPair) {
          apiCalls
            .post(`${SAPBR_Utils.UrlApiTypes[selectedTab].rfi}`, {
              organizationId: DASA_DEC_ORG_ID,
              fiscalCycleId: activeFiscalCycle.id,
              poc: null,
            })
            .then(() => {
              setLoadingTitle(CSVUploadProgressMessageTypes.POST)
              bulkUploadCSVDataToDB()
            })
        } else {
          setLoadingTitle(CSVUploadProgressMessageTypes.DELETE)
          apiCalls
            .delete(`${SAPBR_Utils.UrlApiTypes[selectedTab].record}/${selectedFCIdPair.id}/BulkDelete`)
            .then(() => {
              setLoadingTitle(CSVUploadProgressMessageTypes.POST)
              bulkUploadCSVDataToDB()
            })
            .catch(() => {
              setShowSnackbarError(true)
              setSnackbarMessage(
                "Server timeout when attempting to delete existing data. No data changes have been made. Check your connection and try again."
              )
              setShowUploadProgressModal(false)
            })
        }
      }
    })
  }

  const handleLoadCSVDataIntoTable = (data) => {
    if (selectedTab === SAPBR_Utils.Tabs.SAPBR) {
      data.forEach((record) => {
        delete record.__parsed_extra
        const pattern = /FY\d{2}/
        Object.keys(record).forEach((key) => {
          if (pattern.test(key)) {
            if (key.includes("CIV Approved WYs Funding Memo (with BPC excluded)")) {
              record[SAPBR_Utils.SAPBRTableFields.APPROVED_WORK_YEAR_ONE.replace(/\s/g, "")] = record[key]
              delete record[key]
            } else if (key.includes("CIV Approved WYs Increase (CIV only from RMD decisions)")) {
              record[SAPBR_Utils.SAPBRTableFields.APPROVED_WORK_YEAR_TWO.replace(/\s/g, "")] = record[key]
              delete record[key]
            } else if (key.includes("- Approved CIV WYs")) {
              record[SAPBR_Utils.SAPBRTableFields.APPROVED_WORK_YEAR_THREE.replace(/\s/g, "")] = record[key]
              delete record[key]
            } else if (key.includes("DASA DECISION Approved WYs in")) {
              record[SAPBR_Utils.SAPBRTableFields.APPROVED_WORK_YEAR_FOUR.replace(/\s/g, "")] = record[key]
              delete record[key]
            }
          }
        })
        record[SAPBR_Utils.SAPBRTableFields.FY.replace(/\s/g, "")] = activeFiscalCycle[SAPBR_Utils.SAPBRDataFields.FY]
      })
    }
    pageNumber.current = 0
    renderingDBRows.current = true
    setUploadedTableRows(data)
    setCanSubmitRFIData({ ...canSubmitRFIData, [selectedTab]: renderingDBRows.current })
  }

  const madeFirstSubmissionOfSession = useRef(false)
  const headerRowNumber = useRef(0)
  const handleReadCSVFile = (file) => {
    setFileSize(file?.size)
    setMUISortModel([])
    setMUIFilter({})
    setShowingWarningErrorUpload(false)
    setToggleWarningsErrors(false)
    onlyWarningsInUpload.current = false
    madeFirstSubmissionOfSession.current = true
    let tmpParsedRows = []
    let parsedHeaders = []
    let headerRowFound = false
    headerRowNumber.current = 0
    // Ignore all other file types that are not 'text/csv'
    if (file.type === "text/csv") {
      setLoadingTitle(CSVUploadProgressMessageTypes.PREPARE)
      setShowUploadProgressModal(true)
      setUploadCompleted(false)
      Papa.parse(file, {
        header: false,
        worker: true,
        delimiter: "",
        skipEmptyLines: true,
        step: (row) => {
          if (!headerRowFound) {
            headerRowNumber.current += 1
            // Find the header row
            parsedHeaders = row.data.map((header) => formatHeader(header)?.replace(/\s/g, "")).slice(1)
            parsedHeaders.map((potentialHeader) => {
              potentialHeader = potentialHeader.replace("[", "").replace("]", "")
              for (let i = 0; i < Object.values(SAPBR_Utils.UrlApiTypes[selectedTab].headers).length; i++) {
                const headerValue = SAPBR_Utils.correctFYInHeader(
                  Object.values(SAPBR_Utils.UrlApiTypes[selectedTab].headers)[i],
                  activeFiscalCycle
                ).replace(/\s/g, "")
                if (headerValue.toLowerCase() === potentialHeader.toLowerCase()) {
                  matchedHeaderCount.current += 1
                  if (matchedHeaderCount.current >= Math.floor(SAPBR_Utils.ExpectedHeaders[selectedTab].length * 0.75)) {
                    headerRowFound = true
                  }
                  break
                }
              }
            })
            matchedHeaderCount.current = 0
          } else {
            // Data Row
            let parsedRow = {}
            for (let i = 0; i < Object.values(SAPBR_Utils.UrlApiTypes[selectedTab].headers).length; i++) {
              const fieldValue = row?.data[i] ? row.data[i].trim() : ""
              parsedRow[Object.values(SAPBR_Utils.UrlApiTypes[selectedTab].headers)[i]] = fieldValue === "#" ? null : fieldValue
            }
            tmpParsedRows.push(parsedRow)
          }
        },
        complete: () => {
          setTimeout(() => {
            const trimmedParsedRows = tmpParsedRows.filter((row) => Object.values(row).filter((value) => value !== "").length > 0)
            handleLoadCSVDataIntoTable(trimmedParsedRows)
            headerRowFound = false
          }, 1000)
        },
      })
    } else {
      setShowSnackbarError(true)
      setSnackbarMessage("Cannot upload data: The file type is not supported. Please upload a CSV file.")
    }
  }

  const origionalWarningsErrorsTableRows = useRef([])

  const updateTableForTab = (apiType, rfiIdFieldName, skip) => {
    if (renderingDBRows.current) {
      const queryParams = getQueryParamsOnTableUpdateFromDB(
        MUIFilter,
        MUISortModel,
        pageSize,
        skip,
        rfiIdFieldName,
        selectedRFI,
        SAPBR_Utils.NumericFields[selectedTab]
      )
      if (selectedRFI.id) {
        apiCalls
          .getAllCount(apiType, { [QueryParamTypes.FILTER]: queryParams[QueryParamTypes.FILTER] })
          .then((count) => {
            apiCalls
              .getAll(apiType, queryParams)
              .then((pageRows) => {
                let pageTableRows = []
                numTotalTableRows.current = { ...numTotalTableRows.current, [selectedTab]: count }
                pageTableRows = SAPBR_RMD_Data_Loader.convertDataToTableRows(selectedTab, pageRows, activeFiscalCycle)
                setShowBusyDialog(false)
                setSelectedTableRows(pageTableRows)
                if (MUIFilter?.value && MUIFilter.value.length > 0) {
                  setShowSnackbarSuccess(true)
                  setSnackbarMessage(`Filtered ${selectedTab} data`)
                }
              })
              .finally(() => setShowBusyDialog(false))
          })
          .catch((e) => {
            console.error(e)
            setShowSnackbarError(true)
            if (MUIFilter?.value && MUIFilter.value.length > 0) {
              setSnackbarMessage(
                `Failed to filter field: the entered value ${MUIFilter?.value ? `'${MUIFilter.value}'` : ""} is not allowed or the ${
                  MUIFilter?.operator ? `'${MUIFilter.operator}'` : ""
                } operator is not allowed for the ${MUIFilter?.tableField ? `'${MUIFilter.tableField}'` : ""} field.`
              )
            } else {
              setSnackbarMessage(`Failed to retrieve ${selectedTab} data`)
            }
          })
      } else {
        setSelectedTableRows([])
        pageNumber.current = 0
        numTotalTableRows.current = { ...numTotalTableRows.current, [selectedTab]: 0 }
        setShowBusyDialog(false)
      }
    } else {
      const warningsErrorsTableRowsForDisplay = toggleWarningsErrors ? warningsErrorsTableRowsWarningsErrorsOnly : warningsErrorsTableRows
      updateMUITableWithWarningsErrorsRows(
        MUIFilter,
        MUISortModel,
        warningsErrorsTableRowsForDisplay,
        origionalWarningsErrorsTableRows,
        numTotalTableRows,
        setSelectedTableRows,
        selectedTab,
        skip,
        pageSize,
        SAPBR_Utils.NumericFields[selectedTab],
        setShowSnackbarError,
        setSnackbarMessage
      )
      setShowBusyDialog(false)
    }
  }

  const updateTableRows = () => {
    if (!activeFiscalCycle?.id || !selectedTab || pageNumber?.current == null || pageNumber.current < 0 || pageSize?.current == null) {
      setShowBusyDialog(false)
      return
    }
    const skip = pageNumber.current * pageSize.current
    if (selectedTab === SAPBR_Utils.Tabs.SAPBR) {
      let newCols = SAPBR_Utils.UrlApiTypes[selectedTab].columns
      if (newCols) {
        newCols = newCols.map((col) => {
          let tmpField = col?.field
          tmpField = SAPBR_Utils.correctFYInHeader(tmpField, activeFiscalCycle)
          return { ...col, headerName: tmpField }
        })
      }
      setSelectedTableCols(newCols)
    } else {
      setSelectedTableCols(SAPBR_Utils.UrlApiTypes[selectedTab].columns)
    }
    updateTableForTab(SAPBR_Utils.UrlApiTypes[selectedTab].record, SAPBR_Utils.UrlApiTypes[selectedTab].rfiId, skip)
  }

  const handleOnPageSizeChange = (pgSize) => {
    pageSize.current = pgSize
    if (pageNumber.current * pgSize + 1 > numTotalTableRows.current[selectedTab]) {
      pageNumber.current = 0
    }
    handleOnPageChange(pageNumber.current)
  }

  const handleOnPageChange = (pgNumber) => {
    if (pgNumber < 0) pgNumber = 0
    pageNumber.current = pgNumber
    updateTableRows()
  }

  const adjustTableHeight = () => {
    setMUITableHeight(window.innerHeight - 425)
  }

  const handleOnSortModelChange = (MUISortModel) => {
    const fieldKey = Object.keys(SAPBR_Utils.UrlApiTypes[selectedTab].headers).find(
      (key) => SAPBR_Utils.UrlApiTypes[selectedTab].headers[key] === MUISortModel[0]?.field
    )
    if (fieldKey) {
      const updatedSortModel = [{ ...MUISortModel[0], dataField: SAPBR_Utils.UrlApiTypes[selectedTab].dataHeaders[fieldKey] }]
      setMUISortModel(updatedSortModel)
    } else {
      setMUISortModel([{}])
    }
  }

  const handleFilterRFIData = (tableField, dataField, operator, value) => {
    setMUIFilter({ tableField, dataField, operator, value })
  }

  const handleExportRFIData = () => {
    //setLoadingTitle(CSVUploadProgressMessageTypes.EXPORT)
  }

  const handleChange = (event, newValue) => {
    setSelectedTab(newValue)
  }

  // ** --------------***     [ ~ RENDER ~ ]     ***-------------- **
  return (
    <Box
      container
      margin={2}
      spacing={2}
    >
      <Typography
        align="left"
        variant="h4"
      >
        SAPBR
      </Typography>
      <TabContext value={selectedTab}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList
            variant="scrollable"
            scrollButtons="auto"
            onChange={handleChange}
          >
            <Tab
              label={SAPBR_Utils.Tabs.SAPBR}
              value={SAPBR_Utils.Tabs.SAPBR}
            />
            <Tab
              label={SAPBR_Utils.Tabs.RMD}
              value={SAPBR_Utils.Tabs.RMD}
            />
          </TabList>
        </Box>
      </TabContext>
      <div className="--RFI-table-actions">
        <div />
        <Stack
          direction="row"
          spacing={1}
        >
          <Button
            onClick={() => setShowRequestResubmissionWarningModal(true)}
            variant="outlined"
            hidden={submitStatus[selectedTab] !== SubmitStatus.SUBMITTED}
          >
            REQUEST RESUBMISSION
          </Button>
          <Button
            variant="outlined"
            disabled={!canUploadCSVData[selectedTab]}
            onClick={() => fileSelector.current.click()}
          >
            UPLOAD CSV FILE
            <input
              type="file"
              ref={fileSelector}
              onChangeCapture={(e) => handleReadCSVFile(e.target.files[0])}
              hidden
            />
          </Button>
          <Button
            onClick={() => setShowSubmitRFIWarningModal(true)}
            variant="contained"
            disabled={!canSubmitRFIData[selectedTab]}
          >
            SUBMIT RFI DATA
          </Button>
          <RfiStatusChip status={submitStatus[selectedTab] ? submitStatus[selectedTab] : SubmitStatus.NOT_SUBMITTED} />
        </Stack>
      </div>
      <div className="--RFI-table-container">
        {/* DataGrid */}
        <div
          style={{ width: "100%", height: MUITableHeight, minHeight: MinTableHeight, alignSelf: "center", display: "flex", flexDirection: "column" }}
        >
          <RFIGridToolbar
            selectedTab={selectedTab}
            selectedFY={activeFiscalCycle}
            selectedOrgName={null}
            headers={Object.values(SAPBR_Utils.UrlApiTypes[selectedTab].headers)}
            dataHeaders={Object.values(SAPBR_Utils.UrlApiTypes[selectedTab].dataHeaders).filter((h) => h !== SAPBR_Utils.RMDDataFields.FC_END_YEAR)}
            handleExportRFIData={handleExportRFIData}
            handleFilterRFIData={handleFilterRFIData}
            showingWarningErrorUpload={showingWarningErrorUpload}
            toggleWarningsErrors={toggleWarningsErrors}
            setToggleWarningsErrors={setToggleWarningsErrors}
            warningsOnly={onlyWarningsInUpload.current}
            canExportRFIData={canExportRFIData[selectedTab]}
          />
          <DataGrid
            rows={selectedTableRows}
            columns={selectedTableCols}
            density="compact"
            sortingMode="server"
            onSortModelChange={handleOnSortModelChange}
            rowsPerPageOptions={[25, 40, 50, 60, 75, 100]}
            onPageSizeChange={(newPageSize) => handleOnPageSizeChange(newPageSize)}
            pagination
            loading={showBusyDialog}
            getRowId={() => uuidv4()}
            getRowClassName={(params) => {
              if (params?.row?.Warnings_Errors && params?.row?.Warnings_Errors?.errors?.length > 0) {
                return "--RFI-warnings-errors-row"
              } else if (params?.row?.Warnings_Errors && params?.row?.Warnings_Errors.warnings?.length > 0) {
                return "--RFI-warnings-row"
              }
              if (params.indexRelativeToCurrentPage % 2 === 0) {
                return "even-row"
              } else return "odd-row"
            }}
            getCellClassName={(params) => {
              if (params.field === "Warnings_Errors") {
                return "--RFI-warning-errors-cell"
              }
            }}
            sx={{
              border: 2,
              borderColor: "black",
            }}
            initialState={{
              columns: {
                columnVisibilityModel: { Id: false },
              },
            }}
          />
          <TableControls
            pageNumber={pageNumber.current}
            pageSize={pageSize.current}
            numTotalTableRows={numTotalTableRows.current[selectedTab]}
            handleOnPageChange={handleOnPageChange}
          />
        </div>
      </div>
      <UploadProgressModal
        open={showUploadProgressModal}
        title={loadingTitle}
        setTitle={setLoadingTitle}
        fileSize={fileSize}
        uploadCompleted={uploadCompleted}
        trackProgress={true}
      />
      <SnackbarMessages
        showSnackbarSuccess={showSnackbarSuccess}
        setShowSnackbarSuccess={setShowSnackbarSuccess}
        showSnackbarError={showSnackbarError}
        setShowSnackbarError={setShowSnackbarError}
        snackbarMessage={snackbarMessage}
      />
      <RequestResubmissionWarningModal
        open={showRequestResubmissionWarningModal}
        setOpen={setShowRequestResubmissionWarningModal}
        requestResubmission={requestResubmission}
      />
      <SubmitRFIWarningModal
        showSubmitRFIWarningModal={showSubmitRFIWarningModal}
        setShowSubmitRFIWarningModal={setShowSubmitRFIWarningModal}
        msg={`If you submit ${selectedTab} data, you won't be able to reupload data unless an administrator puts the ${selectedTab} table back to a "Not Submitted" state.`}
        submitRFIData={submitRFIData}
      />
      <ReviewWarningsModal
        showWarningUploadModal={showWarningUploadModal}
        setShowWarningUploadModal={setShowWarningUploadModal}
      />
    </Box>
  )
}

export default SAPBR
