///////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////                                  Imports                                  //////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////

// Global Stuff
import React, { useState, useEffect, useRef, useContext } from "react"
import AppContext from "../../../AppContext"
import DSAMSTableUtils from "./DSAMS_Table_Utils"
import {
  SubmitStatus,
  getQueryParamsOnTableUpdateFromDB,
  updateMUITableWithWarningsErrorsRows,
  CSVBulkUploadReqHeaders,
  getWarningsErrorsTableRowsFromUploadedTableRows,
  UploadSubmitEnabledStatuses,
  returnErrorMessageFromAPIError,
  CSVUploadProgressMessageTypes,
} from "../../../Utils"
import { TabContext, TabList } from "@mui/lab"

// CSVs
import ResponseAnalyzer from "./ResponseAnalyzer"
import Papa from "papaparse"
import DSAMS_CISIL_CSV_Data_Loader from "./DSAMS-CISIL_CSV_Data_Loader"

// Data Calls
import { apiCalls, QueryParamTypes } from "../../DataService"

// Modals & Messaging
import ReviewWarningsModal from "../ReviewWarningsModal"
import UploadProgressModal from "../UploadProgressModal"
import { SubmitRFIWarningModal } from "../SubmitRFIWarningModal"
import SnackbarMessages from "../../SnackbarMessages"
import { RequestResubmissionWarningModal } from "../RequestResubmissionWarningModal"
import DSAMS_RFI_Instructions_Modal from "./DSAMS_RFI_Instructions_Modal"

// DataGrid
import { TableControls } from "../TableControls"

import "../RFI.css"
import { Box, Tab, Button, Stack, Typography } from "@mui/material"
import { Help } from "@mui/icons-material"
import RfiStatusChip from "../../custom/RfiStatusChip"
import CustomDataGrid from "../../elements/custom-grid/CustomDataGrid"

// Main Component
// Component for uploading and viewing DSAMS and CISIL RFI Responses
//
const DSAMS_RFI = () => {
  // Initialize App-level Data
  const context = useContext(AppContext)
  const { selectedOrg, activeFiscalCycle, filterOrganizations } = context

  // For now, going to make each page responsible for providing what it supports but there is a mapping in utils (RFIViewOrgMappings)
  useEffect(() => {
    filterOrganizations(["USASAC"])
  }, [])

  ///////////////////////////////////////////////////////////////////////////////////////////////////////
  //////////////                           Component Variables                             //////////////
  ///////////////////////////////////////////////////////////////////////////////////////////////////////

  const [selectedTab, setSelectedTab] = useState(DSAMSTableUtils.Tabs.MILESTONES)
  const [selectedTableCols, setSelectedTableCols] = useState(DSAMSTableUtils.DSAMSTables.MILESTONES_COLS)
  const [selectedTableRows, setSelectedTableRows] = useState([])
  const [uploadedTableRows, setUploadedTableRows] = useState([])
  const [warningsErrorsTableRows, setWarningsErrorsTableRows] = useState([])
  const [warningsErrorsTableRowsWarningsErrorsOnly, setWarningsErrorsTableRowsWarningsErrorsOnly] = useState([])
  const [selectedRFI, setSelectedRFI] = useState({})
  const renderingDBRows = useRef(true)
  const [showWarningUploadModal, setShowWarningUploadModal] = useState(false)
  const [showRequestResubmissionWarningModal, setShowRequestResubmissionWarningModal] = useState(false)
  const [MUITableHeight, setMUITableHeight] = useState(800)

  // FileSize is used for the progress modal
  const [fileSize, setFileSize] = useState(0)

  const [uploadCompleted, setUploadCompleted] = useState(false)
  const onlyWarningsInUpload = useRef(false)
  const [MUIFilter, setMUIFilter] = useState({})
  const [MUISortModel, setMUISortModel] = useState([])
  const [showingWarningErrorUpload, setShowingWarningErrorUpload] = useState(false)
  const [toggleWarningsErrors, setToggleWarningsErrors] = useState(false)

  const pageSize = useRef(100)
  const pageNumber = useRef(0)

  const numTotalTableRows = useRef({
    [DSAMSTableUtils.Tabs.MILESTONES]: 0,
    [DSAMSTableUtils.Tabs.PREPARING_ACTIVITY_DATA]: 0,
    [DSAMSTableUtils.Tabs.CISIL_CCOPES_LINE_DATA]: 0,
    [DSAMSTableUtils.Tabs.CISIL_CASES_CERT_CLOSURE]: 0,
    [DSAMSTableUtils.Tabs.CISIL_CASES_CERT_CLOSURE_WO_PRI_ONE_DATE]: 0,
    [DSAMSTableUtils.Tabs.CISIL_REQUISITIONS]: 0,
    [DSAMSTableUtils.Tabs.CISIL_SDRs]: 0,
    [DSAMSTableUtils.Tabs.CISIL_DATA_DESCRIPTIONS]: 0,
  })

  const [canUploadCSVData, setCanUploadCSVData] = useState({})
  const [canSubmitRFIData, setCanSubmitRFIData] = useState({})
  const [canExportRFIData, setCanExportRFIData] = useState({})
  const [submitStatus, setSubmitStatus] = useState({})
  const [showSnackbarSuccess, setShowSnackbarSuccess] = useState(false)
  const [showSnackbarError, setShowSnackbarError] = useState(false)
  const [snackbarMessage, setSnackbarMessage] = useState("")
  const [showSubmitRFIWarningModal, setShowSubmitRFIWarningModal] = useState(false)
  const [showBusyDialog, setShowBusyDialog] = useState(false)
  const [showUploadProgressModal, setShowUploadProgressModal] = useState(false)
  const [loadingTitle, setLoadingTitle] = useState("")

  const [disclaimerRFIModalOpen, setDisclaimerRFIModalOpen] = useState(false)

  // CSV Uploading
  const fileSelector = useRef(null)
  const csvFile = useRef(null)
  const csvFileUploadAttempted = useRef(false)
  const headerRowFound = useRef(false)
  const headerRowNumber = useRef(0)

  let origionalWarningsErrorsTableRows = useRef([])

  ///////////////////////////////////////////////////////////////////////////////////////////////////////
  //////////////                           Component Functions                             //////////////
  ///////////////////////////////////////////////////////////////////////////////////////////////////////

  // ------------------------------------  Uploads and Submissions  -------------------------------------

  const submitRFIData = () => {
    setShowBusyDialog(true)
    apiCalls
      .put(`${DSAMSTableUtils.UrlApiTypes[selectedTab].rfi}/${selectedRFI.id}`, { ...selectedRFI, status: SubmitStatus.SUBMITTED, poc: null })
      .then(() => {
        getSelectedRFI()
        setShowSnackbarSuccess(true)
        setSnackbarMessage("RFI Submitted Successfully!")
      })
      .catch(() => {
        setShowSnackbarError(true)
        setSnackbarMessage("Failed to submit RFI data")
      })
      .finally(() => setShowBusyDialog(false))
  }

  const requestResubmission = () => {
    setShowBusyDialog(true)
    apiCalls
      .put(`${DSAMSTableUtils.UrlApiTypes[selectedTab].rfi}/${selectedRFI.id}`, { ...selectedRFI, status: SubmitStatus.REQUEST_RESUBMISSION })
      .then(() => {
        getSelectedRFI()
        setShowSnackbarSuccess(true)
        setSnackbarMessage("Resubmission requested for RFI!")
      })
      .catch(() => {
        setShowSnackbarError(true)
        setSnackbarMessage("Failed to request resubmission for RFI")
      })
      .finally(() => setShowBusyDialog(false))
  } // End of

  // Overwrite any existing csv entries in the DB with the new data
  const persistDBData = () => {
    //console.log("persistDBData")
    //Make sure an organization is selected
    if (!selectedOrg.id) {
      setShowSnackbarError(true)

      setSnackbarMessage("Organization not selected, please select an organization from the Organization POCs page")

      return
    }

    apiCalls
      .getAll(`${DSAMSTableUtils.UrlApiTypes[selectedTab].rfi}`, {})

      .then((allSelectedRfiResponses) => {
        // If there are no responses
        if (allSelectedRfiResponses.length <= 0) {
          apiCalls
            .post(`${DSAMSTableUtils.UrlApiTypes[selectedTab].rfi}`, {
              organizationId: selectedOrg.id,
              fiscalCycleId: activeFiscalCycle.id,
              poc: null,
            })

            .then(() => {
              setLoadingTitle(CSVUploadProgressMessageTypes.POST)

              bulkUploadCSVDataToDB()
            })
        } // End of

        // If there is a previous response
        if (allSelectedRfiResponses.length > 0) {
          // Get the Response Id, Organization Id, and Fiscal Cycle Id of all responses for selected RFI
          const responses = allSelectedRfiResponses.map((response) => {
            return { id: response.id, orgId: response.organizationId, fcId: response.fiscalCycleId }
          })

          let responseExists = false
          let responseId = null

          // Check if the selected Organization has a response for the selected Fiscal Cycle
          for (let index = 0; index < responses.length; index++) {
            if (responses[index].orgId === selectedOrg.id && responses[index].fcId === activeFiscalCycle.id) {
              responseExists = true

              responseId = responses[index].id

              break
            }
          } // End of for loop

          if (!responseExists) {
            // ? Make a function out of the following call since it is used twice in this persistDBData. ?
            // ? Why is there a trailing comma after userObj.name ?
            //   It's now null but some javascript coding standards require the trailing comma for ease of adding a new data line
            //   It is interpreted the same with or without - BTA
            apiCalls
              .post(`${DSAMSTableUtils.UrlApiTypes[selectedTab].rfi}`, {
                organizationId: selectedOrg.id,
                fiscalCycleId: activeFiscalCycle.id,
                poc: null,
              })
              .then(() => {
                // ? Can the following two functions be called on the same line ?
                setLoadingTitle(CSVUploadProgressMessageTypes.POST)
                bulkUploadCSVDataToDB()
              })
          } else {
            setLoadingTitle(CSVUploadProgressMessageTypes.DELETE)

            // Delete previous response
            apiCalls
              .delete(`${DSAMSTableUtils.UrlApiTypes[selectedTab].record}/${responseId}/BulkDelete`)
              .then(() => {
                setLoadingTitle(CSVUploadProgressMessageTypes.POST)
                bulkUploadCSVDataToDB()
              })
              .catch(() => {
                setShowSnackbarError(true)
                // ? how do we identify what type of error occured ?
                setSnackbarMessage(
                  "Server timeout when attempting to delete existing data. No data changes have been made. Check your connection and try again."
                )
                setShowUploadProgressModal(false)
              })
          } // End of if responseExists
        } // End of if allSelectedRfiResponses.length > 0
      }) // End of first apicalls
  }

  const restorePrevDBData = async (tmpSelectedRFI) => {
    setShowUploadProgressModal(true)

    // Removed AWSS3 call. Probably want to eventually remove this entirely but leaving it stubbed.
    //setLoadingTitle(CSVUploadProgressMessageTypes.RESTORE)
  }

  const handleExportRFIData = () => {
    // Removed AWSS3 call. Probably want to eventually remove this entirely but leaving it stubbed.
    //setLoadingTitle(CSVUploadProgressMessageTypes.EXPORT)
  }

  // ------------------------------------------  CSV Uploads  -------------------------------------------

  // Function Called when a csv file is uploaded
  const handleReadCSVFile = (file) => {
    //console.log("handleReadCSVFile")
    // Reset variables
    csvFile.current = null
    fileSelector.current.value = null

    // Check if file is a csv file
    if (file.type !== "text/csv") {
      setShowSnackbarError(true)
      setSnackbarMessage('Cannot upload the "' + file.name + '" file.  The file type is not supported. Please upload a CSV file.')

      return
    } // End of else file is text/csv

    /******* SHOULD SHOW FILE DATA IN A POP UP AND ASK FOR CONFIRMATION THAT USER IS UPLOADING THE NAMED FILE FOR THE NAMED RFI FOR THE NAMED FISCAL YEAR*/

    // Set file size variable for progress modal
    csvFile.current = file
    setFileSize(file?.size)

    // ? Data Grid
    setMUISortModel([])
    setMUIFilter({})

    // ? What are the following four function doing?  Ask People
    setShowingWarningErrorUpload(false)
    setToggleWarningsErrors(false)

    // Modal Updates
    setLoadingTitle(CSVUploadProgressMessageTypes.PREPARE)
    setShowUploadProgressModal(true)
    setUploadCompleted(false)

    let rfiName = selectedTab

    const responseAnalyzer = new ResponseAnalyzer(rfiName)

    // Reset useRef variables
    // ? Why is this variable being used in the use effect?
    csvFileUploadAttempted.current = true
    onlyWarningsInUpload.current = false
    headerRowFound.current = false
    headerRowNumber.current = 0

    // Set configuration variable for parsing of csv file
    var config = {
      header: false,
      worker: true,
      delimiter: "",
      skipEmptyLines: true,
      step: function (results, parser) {
        responseAnalyzer.analyze(results, parser)
      },
      complete: function (results, file) {
        setTimeout(() => {
          loadCSVDataIntoTable(responseAnalyzer)
        }, 1000)
      },
    } // End of config

    //console.log("parsing")
    // Execute Parse
    Papa.parse(file, config)
  } // End of handleReadCSVFile

  // Checks Headers
  // Sets updatedTableRows
  const loadCSVDataIntoTable = (responseAnalyzer) => {
    //console.log("loadCSVDataIntoTable")
    // -----    Declare Local Variables    ----- //

    let data = []
    let rfiName = ""

    // -----    Set Variables    ----- //

    // Set Local Variables //

    rfiName = responseAnalyzer.rfiName

    // Filter out values - TODO:  This may be a shallow copy of the array,
    // update this so a copy of the array which doesn't reference the original is created
    data = responseAnalyzer.responseData.filter((row) => Object.values(row).filter((value) => value !== "").length > 0)

    // Remove the first 1st row in the array for the specific RFI
    if (rfiName === DSAMSTableUtils.Tabs.CISIL_CCOPES_LINE_DATA) {
      data = data.slice(1)
    }

    // Set Component Variables //

    headerRowFound.current = responseAnalyzer.headerFound
    headerRowNumber.current = responseAnalyzer.headerRow
    pageNumber.current = 0
    renderingDBRows.current = true

    switch (rfiName) {
      case DSAMSTableUtils.Tabs.CISIL_CASES_CERT_CLOSURE:
      case DSAMSTableUtils.Tabs.CISIL_CASES_CERT_CLOSURE_WO_PRI_ONE_DATE:
        let responseDataCopy = []

        responseDataCopy = changeCisilCasesCertificationClosureHeaders(data)

        setUploadedTableRows(responseDataCopy)

        break

      default:
        setUploadedTableRows(data)
    } // End of switch

    setCanSubmitRFIData({ ...canSubmitRFIData, [rfiName]: renderingDBRows.current })
  } // End of loadCSVDataIntoTable

  const bulkUploadCSVDataToDB = () => {
    apiCalls
      .getAll(`${DSAMSTableUtils.UrlApiTypes[selectedTab].rfi}`, {})

      .then((allRFIs) => {
        const tmpSelectedRFI = allRFIs?.find((RFI) => RFI.organizationId === selectedOrg.id && RFI.fiscalCycleId === activeFiscalCycle.id)

        let formData = new FormData()

        formData.append("FormFile", new Blob([csvFile.current]))

        let queryParamsStr = "replaceData=false"

        const fiscalCycleStartQueryParamTabs = [
          DSAMSTableUtils.Tabs.MILESTONES,
          DSAMSTableUtils.Tabs.PREPARING_ACTIVITY_DATA,
          DSAMSTableUtils.Tabs.CISIL_CCOPES_LINE_DATA,
          DSAMSTableUtils.Tabs.CISIL_CASES_CERT_CLOSURE,
          DSAMSTableUtils.Tabs.CISIL_CASES_CERT_CLOSURE_WO_PRI_ONE_DATE,
        ]

        if (fiscalCycleStartQueryParamTabs.includes(selectedTab)) {
          queryParamsStr += `&fiscalCycleId=${activeFiscalCycle?.id}`
        }

        let warningsList = null
        let errorsList = null
        let bulkUploadURL = `${DSAMSTableUtils.UrlApiTypes[selectedTab].record}/${tmpSelectedRFI.id}/BulkUpload?${queryParamsStr}`

        apiCalls

          .post(bulkUploadURL, formData, CSVBulkUploadReqHeaders)

          .then((res) => {
            warningsList = res.data.warningsList

            if (warningsList && warningsList.length > 0) {
              renderingDBRows.current = false
              onlyWarningsInUpload.current = true
              setShowingWarningErrorUpload(true)
              setShowWarningUploadModal(true)
            } else renderingDBRows.current = true

            setShowSnackbarSuccess(true)

            setSnackbarMessage(`Successfully uploaded ${selectedTab} data.`)

            getSelectedRFI()

            setUploadCompleted(true)
            setShowUploadProgressModal(false)
          })

          .catch((e) => {
            const errObj = e.response.data

            setShowSnackbarError(true)

            renderingDBRows.current = false

            restorePrevDBData(tmpSelectedRFI)

            const errMessage = returnErrorMessageFromAPIError(e)

            if (errMessage) {
              setSnackbarMessage(errMessage)
              return
            }

            if (errObj.topLevelError) {
              setSnackbarMessage(errObj.topLevelError)
              return
            }

            if (errObj.error) {
              setSnackbarMessage(errObj.error)
              return
            }

            if (errObj?.fileError) {
              setSnackbarMessage(errObj.fileError)
              return
            }

            if (errObj && errObj?.fileErrors) {
              // If there are file errors, display them in a snackbar message
              let msg = "Some or all of the headers are incorrect or missing in the uploaded file. Please check the file and try again."
              setSnackbarMessage(msg)
            } else {
              // If there are row errors, display them in the table
              if (errObj && errObj.length > 0) {
                errorsList = errObj.filter((err) => err.code === "Error")
                warningsList = errObj.filter((err) => err.code === "Warning")
                setShowingWarningErrorUpload(true)
                setSnackbarMessage(`Errors found in ${csvFile.current.name}`)
              } else {
                setSnackbarMessage("unknown error: please check your internet connection and make sure your file is formatted correctly")
              }
            }
          })

          .finally(() => {
            if (errorsList || warningsList) {
              const headerRowNumberToPass =
                selectedTab === DSAMSTableUtils.Tabs.CISIL_CCOPES_LINE_DATA ? headerRowNumber.current + 2 : headerRowNumber.current + 1

              const { tmpWarningsErrorsTableRows, tmpWarningsErrorsTableRowsWarningsErrorsOnly } = getWarningsErrorsTableRowsFromUploadedTableRows(
                uploadedTableRows,
                errorsList,
                warningsList,
                headerRowNumberToPass
              )

              setWarningsErrorsTableRows(tmpWarningsErrorsTableRows)
              setWarningsErrorsTableRowsWarningsErrorsOnly(tmpWarningsErrorsTableRowsWarningsErrorsOnly)
            } // End of if

            setFileSize(0)

            csvFile.current = null
          })
      })

      .catch((e) => {
        const errMessage = returnErrorMessageFromAPIError(e)

        setShowSnackbarError(true)

        if (errMessage) {
          setSnackbarMessage(errMessage)
        } else {
          setSnackbarMessage("Unable to load data, please refresh the page and try again")
        }
      })
  } // End of bulkUploadCSVDataToDB

  // make a copy of the response data object array
  // delete headers for some unkown reason.  This looks like a scary trap.
  function changeCisilCasesCertificationClosureHeaders(data) {
    let responseDataCopy = [...data]

    // iterate through each object in the response data object array
    responseDataCopy.forEach((mappedRow) => {
      delete mappedRow.__parsed_extra

      // Iterate through the mapped Row keys (headers)
      Object.keys(mappedRow).forEach((header) => {
        // If whitespace do something
        if (mappedRow[header.replace(/\s/g, "")] !== mappedRow[header]) {
          mappedRow[header.replace(/\s/g, "")] = mappedRow[header]

          delete mappedRow[header]
        } // End of If

        //
        for (let index = 0; index < Object.values(DSAMSTableUtils.CisilCasesCertClosuresCSVFields).length; index++) {
          let certClosureCsvField = Object.values(DSAMSTableUtils.CisilCasesCertClosuresCSVFields)[index]
          let certClosureNonCsvField = Object.values(DSAMSTableUtils.CisilCasesCertClosureFields)[index]

          if (mappedRow[header.replace(certClosureCsvField, certClosureNonCsvField)] !== mappedRow[header]) {
            mappedRow[header.replace(certClosureCsvField, certClosureNonCsvField)] = mappedRow[header]

            delete mappedRow[header]
          } // End of if
        } // End of for loop
      }) // End of Object.keys(mappedRow)
    }) // End of responseDataCopy.forEach

    return responseDataCopy
  } // End of changeCisilCasesCertificationClosureHeaders

  // ----------------------------------------  State Functions  -----------------------------------------

  const getSelectedRFI = async () => {
    //console.log("getSelectedRFI")
    const selectedTabRFIs = await apiCalls.getAll(DSAMSTableUtils.UrlApiTypes[selectedTab].rfi, {})
    const RFI = selectedTabRFIs?.find((RFI) => RFI.organizationId === selectedOrg?.id && RFI.fiscalCycleId === activeFiscalCycle?.id)
    if (!RFI) {
      setSelectedRFI({})
      return
    }
    setSelectedRFI(RFI)
    pageNumber.current = 0
  }

  const getTableSubmitStatus = async () => {
    setSubmitStatus({ ...submitStatus, [selectedTab]: selectedRFI?.status ? selectedRFI.status : SubmitStatus.NOT_SUBMITTED })
    setCanSubmitRFIData({
      ...canSubmitRFIData,
      [selectedTab]:
        (renderingDBRows.current || onlyWarningsInUpload.current) &&
        (!selectedRFI?.status || UploadSubmitEnabledStatuses.includes(selectedRFI?.status)) &&
        selectedTableRows.length > 0
          ? true
          : false,
    })
    //console.log(selectedTab)
    //console.log(selectedRFI)
    //console.log(UploadSubmitEnabledStatuses)
    setCanUploadCSVData({
      ...canUploadCSVData,
      [selectedTab]: !selectedRFI?.status || UploadSubmitEnabledStatuses.includes(selectedRFI?.status) ? true : false,
    })
    setCanExportRFIData({ ...canExportRFIData, [selectedTab]: selectedTableRows.length > 0 })
  }

  // --------------------------------------  Data Grid Functions  ---------------------------------------

  const updateTableForTab = (apiType, rfiIdFieldName, skip) => {
    if (renderingDBRows.current) {
      const queryParams = getQueryParamsOnTableUpdateFromDB(
        MUIFilter,
        MUISortModel,
        pageSize,
        skip,
        rfiIdFieldName,
        selectedRFI,
        DSAMSTableUtils.NumericFields[selectedTab]
      )
      if (selectedRFI.id) {
        apiCalls
          .getAllCount(apiType, { [QueryParamTypes.FILTER]: queryParams[QueryParamTypes.FILTER] })
          .then((count) => {
            apiCalls
              .getAll(apiType, queryParams)
              .then((pageRows) => {
                let pageTableRows = []
                numTotalTableRows.current = { ...numTotalTableRows.current, [selectedTab]: count }
                pageTableRows = DSAMS_CISIL_CSV_Data_Loader.convertDataToTableRows(selectedTab, pageRows)
                setShowBusyDialog(false)
                setSelectedTableRows(pageTableRows)
                if (MUIFilter?.value && MUIFilter.value.length > 0) {
                  setShowSnackbarSuccess(true)
                  setSnackbarMessage(`Filtered ${selectedTab} data`)
                }
              })
              .finally(() => setShowBusyDialog(false))
          })
          .catch((e) => {
            console.error(e)
            setShowSnackbarError(true)
            if (MUIFilter?.value && MUIFilter.value.length > 0) {
              setSnackbarMessage(
                `Failed to filter field: the entered value ${MUIFilter?.value ? `'${MUIFilter.value}'` : ""} is not allowed or the ${
                  MUIFilter?.operator ? `'${MUIFilter.operator}'` : ""
                } operator is not allowed for the ${MUIFilter?.tableField ? `'${MUIFilter.tableField}'` : ""} field.`
              )
            } else {
              setSnackbarMessage(`Failed to retrieve ${selectedTab} data`)
            }
          })
      } else {
        setSelectedTableRows([])
        pageNumber.current = 0
        numTotalTableRows.current = { ...numTotalTableRows.current, [selectedTab]: 0 }
        setShowBusyDialog(false)
      }
    } else {
      const warningsErrorsTableRowsForDisplay = toggleWarningsErrors ? warningsErrorsTableRowsWarningsErrorsOnly : warningsErrorsTableRows
      updateMUITableWithWarningsErrorsRows(
        MUIFilter,
        MUISortModel,
        warningsErrorsTableRowsForDisplay,
        origionalWarningsErrorsTableRows,
        numTotalTableRows,
        setSelectedTableRows,
        selectedTab,
        skip,
        pageSize,
        DSAMSTableUtils.NumericFields[selectedTab],
        setShowSnackbarError,
        setSnackbarMessage
      )
      setShowBusyDialog(false)
    }
  }

  const updateTableRows = () => {
    if (!selectedOrg?.id || !selectedTab || pageNumber?.current == null || pageNumber.current < 0 || pageSize?.current == null) {
      setShowBusyDialog(false)
      return
    }
    const skip = pageNumber.current * pageSize.current
    setSelectedTableCols(DSAMSTableUtils.UrlApiTypes[selectedTab].columns)
    updateTableForTab(DSAMSTableUtils.UrlApiTypes[selectedTab].record, DSAMSTableUtils.UrlApiTypes[selectedTab].rfiId, skip)
  }

  const handleFilterRFIData = (tableField, dataField, operator, value) => {
    setMUIFilter({ tableField, dataField, operator, value })
  }

  const handleOnPageSizeChange = (pgSize) => {
    pageSize.current = pgSize
    if (pageNumber.current * pgSize + 1 > numTotalTableRows.current[selectedTab]) {
      pageNumber.current = 0
    }
    handleOnPageChange(pageNumber.current)
  }

  const handleOnPageChange = (pgNumber) => {
    if (pgNumber < 0) pgNumber = 0
    pageNumber.current = pgNumber
    setShowBusyDialog(true)
    updateTableRows()
  }

  const adjustTableHeight = () => {
    setMUITableHeight(window.innerHeight - 425)
  }

  const handleOnSortModelChange = (MUISortModel) => {
    if (!MUISortModel[0]) {
      setMUISortModel([{}])
    } else {
      setMUISortModel(MUISortModel)
    }
  }

  // --------------------------------------  UseEffect Functions  ---------------------------------------

  // Generates a string containing a list of the expected csv headers for the RFI Response
  function generateListOfHeaders(rfiName) {
    // Array to hold rfi headers
    let rfiHeaders = []
    let rfiHeadersList = ""

    // Create an array of expected headers for the RFI
    switch (rfiName) {
      case DSAMSTableUtils.Tabs.CISIL_CCOPES_LINE_DATA:
        rfiHeaders = Object.values(DSAMSTableUtils.CcopesLineDataCSVFields)
        break

      case DSAMSTableUtils.Tabs.CISIL_CASES_CERT_CLOSURE_WO_PRI_ONE_DATE:
      case DSAMSTableUtils.Tabs.CISIL_CASES_CERT_CLOSURE:
        rfiHeaders = Object.values(DSAMSTableUtils.CisilCasesCertClosuresCSVFields)
        break

      default:
        rfiHeaders = Object.values(DSAMSTableUtils.UrlApiTypes[rfiName].headers)
    } // End of switch

    // Generate a string with the list of headers
    rfiHeadersList = createRfiHeadersString(rfiHeaders)

    return rfiHeadersList
  }
  // Returns a string with a list of headers
  function createRfiHeadersString(rfiHeaders) {
    let listOfHeaders = ""

    // append opening bracket to string
    listOfHeaders = " [ "

    // Create string of expected header names
    for (let indexNumber = 0; indexNumber < rfiHeaders.length; indexNumber++) {
      let elementNumber = indexNumber + 1

      listOfHeaders = listOfHeaders + elementNumber + ". " + rfiHeaders[indexNumber] + " "
    }

    // append closing bracket to string
    listOfHeaders = listOfHeaders + " ] "

    return listOfHeaders
  }

  ///////////////////////////////////////////////////////////////////////////////////////////////////////
  //////////////                               Component Hooks                             //////////////
  ///////////////////////////////////////////////////////////////////////////////////////////////////////

  useEffect(() => {
    if (MUIFilter?.value != null) {
      pageNumber.current = 0
      updateTableRows()
    }
  }, [MUIFilter])

  useEffect(() => {
    if (MUISortModel[0]) {
      pageNumber.current = 0
      setShowBusyDialog(true)
      updateTableRows()
    }
  }, [MUISortModel])

  // This useEffect is meant to be executed when a csv file is uploaded
  useEffect(() => {
    setWarningsErrorsTableRows([])

    // If there are updated table rows after the csv file was uploaded
    if (selectedOrg?.id && uploadedTableRows?.length > 0) {
      persistDBData()
    }

    // If there are NO updated table rows after the csv file was uploaded
    if (selectedOrg?.id && uploadedTableRows?.length <= 0) {
      // csvFileUploadAttempted (madeFirstSubmissionOfSession) is set to true when a csv file is uploaded
      if (csvFileUploadAttempted.current == true) {
        let rfiName = selectedTab

        let expectedRfiHeaders = generateListOfHeaders(rfiName)

        setShowSnackbarError(true)
        setSnackbarMessage(
          "Some or all of the headers are incorrect or missing in the uploaded file. Please check the file and try again. Expected Headers:" +
            expectedRfiHeaders
        )
      }

      setShowUploadProgressModal(false)
    }
  }, [uploadedTableRows])

  useEffect(() => {
    const warningErrorsTableRowsForDisplay = toggleWarningsErrors ? warningsErrorsTableRowsWarningsErrorsOnly : warningsErrorsTableRows
    pageNumber.current = 0
    const skip = 0
    updateMUITableWithWarningsErrorsRows(
      MUIFilter,
      MUISortModel,
      warningErrorsTableRowsForDisplay,
      origionalWarningsErrorsTableRows,
      numTotalTableRows,
      setSelectedTableRows,
      selectedTab,
      skip,
      pageSize,
      DSAMSTableUtils.NumericFields[selectedTab],
      setShowSnackbarError,
      setSnackbarMessage
    )
  }, [showingWarningErrorUpload, toggleWarningsErrors])

  useEffect(() => {
    if (warningsErrorsTableRows?.length > 0) {
      updateTableRows()
    }
  }, [warningsErrorsTableRows])

  useEffect(() => {
    getTableSubmitStatus()
  }, [selectedTableRows])

  useEffect(() => {
    renderingDBRows.current = true
    onlyWarningsInUpload.current = false
    setShowingWarningErrorUpload(false)
    if (selectedOrg?.id) {
      getSelectedRFI()
    }
    setMUISortModel([])
  }, [selectedOrg, selectedTab])

  useEffect(() => {
    setShowBusyDialog(true)
    if (MUIFilter?.value && MUIFilter.value.length > 0) {
      setMUIFilter({
        tableField: "",
        dataField: "",
        operator: "equals",
        value: "",
      })
    } else {
      updateTableRows()
    }
    getTableSubmitStatus()
  }, [selectedRFI])

  useEffect(() => {
    window.addEventListener("resize", adjustTableHeight)
    if (selectedOrg?.id) {
      getSelectedRFI()
    }
    getTableSubmitStatus()
  }, [])

  useEffect(() => {
    getTableSubmitStatus()
    //console.log(selectedTab)
  }, [selectedTab])

  const handleChange = (event, newValue) => {
    setSelectedTab(newValue)
  }
  ///////////////////////////////////////////////////////////////////////////////////////////////////////
  //////////////                              Component Render                             //////////////
  ///////////////////////////////////////////////////////////////////////////////////////////////////////
  return (
    <Box
      container
      // margin={2}
      // spacing={2}
      sx={{
        width: "100%",
      }}
    >
      <Typography
        align="left"
        variant="h4"
      >
        DSAMS/CISIL RFI CSV Upload
      </Typography>

      <TabContext value={selectedTab}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList
            variant="scrollable"
            scrollButtons="auto"
            onChange={handleChange}
            aria-label="DSAMS/CISIL RFI Tabs"
          >
            <Tab
              label={DSAMSTableUtils.Tabs.MILESTONES}
              value={DSAMSTableUtils.Tabs.MILESTONES}
            />
            <Tab
              label={DSAMSTableUtils.Tabs.PREPARING_ACTIVITY_DATA}
              value={DSAMSTableUtils.Tabs.PREPARING_ACTIVITY_DATA}
            />
            <Tab
              label={DSAMSTableUtils.Tabs.CISIL_CCOPES_LINE_DATA}
              value={DSAMSTableUtils.Tabs.CISIL_CCOPES_LINE_DATA}
            />
            <Tab
              label={DSAMSTableUtils.Tabs.CISIL_CASES_CERT_CLOSURE}
              value={DSAMSTableUtils.Tabs.CISIL_CASES_CERT_CLOSURE}
            />
            <Tab
              label={DSAMSTableUtils.Tabs.CISIL_CASES_CERT_CLOSURE_WO_PRI_ONE_DATE}
              value={DSAMSTableUtils.Tabs.CISIL_CASES_CERT_CLOSURE_WO_PRI_ONE_DATE}
            />
            <Tab
              label={DSAMSTableUtils.Tabs.CISIL_REQUISITIONS}
              value={DSAMSTableUtils.Tabs.CISIL_REQUISITIONS}
            />
            <Tab
              label={DSAMSTableUtils.Tabs.CISIL_SDRs}
              value={DSAMSTableUtils.Tabs.CISIL_SDRs}
            />
            <Tab
              label={DSAMSTableUtils.Tabs.CISIL_DATA_DESCRIPTIONS}
              value={DSAMSTableUtils.Tabs.CISIL_DATA_DESCRIPTIONS}
            />
          </TabList>
        </Box>
        {/* TODO: Move the tables to their individual tabs 
        
        <TabPanel value={DSAMSTableUtils.Tabs.MILESTONES}>
          {DSAMSTableUtils.Tabs.MILESTONES}
        </TabPanel>
        <TabPanel value={DSAMSTableUtils.Tabs.PREPARING_ACTIVITY_DATA}>
          {DSAMSTableUtils.Tabs.PREPARING_ACTIVITY_DATA}
        </TabPanel>
        <TabPanel value={DSAMSTableUtils.Tabs.CISIL_CCOPES_LINE_DATA}>
          {DSAMSTableUtils.Tabs.CISIL_CCOPES_LINE_DATA}
        </TabPanel> 
        
        */}
      </TabContext>

      <div className="--RFI-table-actions">
        {/************************************************ [RFI Instructions Modal] ************************************************/}
        <DSAMS_RFI_Instructions_Modal
          selectedTab={selectedTab}
          disclaimerRFIModalOpen={disclaimerRFIModalOpen}
          setDisclaimerRFIModalOpen={setDisclaimerRFIModalOpen}
        />
        <Button
          variant="outlined"
          color="info"
          startIcon={<Help />}
          onClick={() => setDisclaimerRFIModalOpen(true)}
        >
          RFI Instructions
        </Button>
        {/************************************** Import CSV file here ********************************************************/}
        <Stack
          spacing={1}
          direction="row"
        >
          <Button
            variant="outlined"
            onClick={() => setShowRequestResubmissionWarningModal(true)}
            hidden={submitStatus[selectedTab] !== SubmitStatus.SUBMITTED}
          >
            REQUEST RESUBMISSION
          </Button>
          <Button
            variant="outlined"
            disabled={!canUploadCSVData[selectedTab]}
            onClick={() => {
              fileSelector.current.click()
            }}
          >
            UPLOAD CSV FILE
            <input
              type="file"
              ref={fileSelector}
              name="files"
              onChangeCapture={(e) => {
                handleReadCSVFile(e.target.files[0])
              }}
              hidden
            />
          </Button>
          <Button
            variant="contained"
            onClick={() => setShowSubmitRFIWarningModal(true)}
            // disabled={!canSubmitRFIData[selectedTab]}
          >
            SUBMIT RFI DATA
          </Button>
          <RfiStatusChip status={submitStatus[selectedTab] ? submitStatus[selectedTab] : SubmitStatus.NOT_SUBMITTED} />
        </Stack>
      </div>

      <div className="--RFI-table-container">
        {/* DataGrid */}
        <div style={{ minHeight: "500px", maxHeight: "70vh", width: "100%", display: "flex", flexDirection: "column" }}>
          <CustomDataGrid
            data={selectedTableRows}
            columns={selectedTableCols}
            initialState={{
              columns: {
                columnVisibilityModel: { Id: false },
              },
              // pinnedColumns: {
              //   right: ['Warnings_Errors']
              // }
            }}
          />
          <TableControls
            pageNumber={pageNumber.current}
            pageSize={pageSize.current}
            numTotalTableRows={numTotalTableRows.current[selectedTab]}
            handleOnPageChange={handleOnPageChange}
          />
        </div>
      </div>
      <UploadProgressModal
        open={showUploadProgressModal}
        title={loadingTitle}
        setTitle={setLoadingTitle}
        fileSize={fileSize}
        uploadCompleted={uploadCompleted}
        trackProgress={true}
      />
      <SnackbarMessages
        showSnackbarSuccess={showSnackbarSuccess}
        setShowSnackbarSuccess={setShowSnackbarSuccess}
        showSnackbarError={showSnackbarError}
        setShowSnackbarError={setShowSnackbarError}
        snackbarMessage={snackbarMessage}
      />
      <RequestResubmissionWarningModal
        open={showRequestResubmissionWarningModal}
        setOpen={setShowRequestResubmissionWarningModal}
        requestResubmission={requestResubmission}
      />
      <SubmitRFIWarningModal
        showSubmitRFIWarningModal={showSubmitRFIWarningModal}
        setShowSubmitRFIWarningModal={setShowSubmitRFIWarningModal}
        msg={`If you submit RFI data, you won't be able to reupload data unless an administrator puts the ${selectedTab} table back to a "Not Submitted" state in the ${selectedOrg.name} organization.`}
        submitRFIData={submitRFIData}
      />
      <ReviewWarningsModal
        showWarningUploadModal={showWarningUploadModal}
        setShowWarningUploadModal={setShowWarningUploadModal}
      />
    </Box>
  )
}

export default DSAMS_RFI
