import React, { useState, useEffect, useRef, useContext } from "react"
import { useLocation } from "react-router-dom"
import ACC_Utils from "./ACC_Utils"
import "../RFI.css"
import { v4 as uuidv4 } from "uuid"
import Papa from "papaparse"
import { apiCalls, QueryParamTypes } from "../../DataService"
import {
  SubmitStatus,
  getQueryParamsOnTableUpdateFromDB,
  updateMUITableWithWarningsErrorsRows,
  formatHeader,
  CSVBulkUploadReqHeaders,
  getWarningsErrorsTableRowsFromUploadedTableRows,
  UploadSubmitEnabledStatuses,
  returnErrorMessageFromAPIError,
  CSVUploadProgressMessageTypes,
} from "../../../Utils"
import ACC_Instructions_Modal from "./ACC_Instructions_Modal"
import { TableControls } from "../TableControls"
import ReviewWarningsModal from "../ReviewWarningsModal"
import UploadProgressModal from "../UploadProgressModal"
import { SubmitRFIWarningModal } from "../SubmitRFIWarningModal"
import SnackbarMessages from "../../SnackbarMessages"
import { RequestResubmissionWarningModal } from "../RequestResubmissionWarningModal"
import AppContext from "../../../AppContext"
import { Box, Button, Stack, Typography } from "@mui/material"
import { Help } from "@mui/icons-material"
import RfiStatusChip from "../../custom/RfiStatusChip"
import { DataGridPremium } from "@mui/x-data-grid-premium"

const ACC = () => {
  // Initialize App-level Data
  const context = useContext(AppContext)
  const { selectedOrg, activeFiscalCycle, filterOrganizations } = context

  useEffect(() => {
    filterOrganizations(["ACC"])
  }, [])

  const [selectedRFI, setSelectedRFI] = useState({})
  const [canUploadCSVACCData, setCanUploadCSVACCData] = useState(false)
  const [canSubmitRFIACCData, setCanSubmitRFIACCData] = useState(false)
  const [canExportRFIACCData, setCanExportRFIACCData] = useState(false)
  const [ACCDataSubmitStatus, setACCDataSubmitStatus] = useState(SubmitStatus.NOT_SUBMITTED)
  const [ACCDataTableRows, setACCDataTableRows] = useState([])
  const [uploadedTableRows, setUploadedTableRows] = useState([])
  const [warningsErrorsTableRows, setWarningsErrorsTableRows] = useState([])
  const [warningsErrorsTableRowsWarningsErrorsOnly, setWarningsErrorsTableRowsWarningsErrorsOnly] = useState([])
  const renderingDBRows = useRef(true)
  const [showWarningUploadModal, setShowWarningUploadModal] = useState(false)
  const [showRequestResubmissionWarningModal, setShowRequestResubmissionWarningModal] = useState(false)

  const matchedHeaderCount = useRef(0)
  const numTotalTableRows = useRef(0)
  const pageSize = useRef(100)
  const pageNumber = useRef(0)
  const [MUITableHeight, setMUITableHeight] = useState(800)
  const location = useLocation()

  const [showSnackbarSuccess, setShowSnackbarSuccess] = useState(false)
  const [showSnackbarError, setShowSnackbarError] = useState(false)
  const [snackbarMessage, setSnackbarMessage] = useState("")
  const [showSubmitRFIWarningModal, setShowSubmitRFIWarningModal] = useState(false)
  const [showBusyDialog, setShowBusyDialog] = useState(false)
  const [showUploadProgressModal, setShowUploadProgressModal] = useState(false)
  const [loadingTitle, setLoadingTitle] = useState("")

  const [disclaimerRFIModalOpen, setDisclaimerRFIModalOpen] = useState(false)

  const fileSelector = useRef(null)
  const [fileSize, setFileSize] = useState(0)
  const [uploadCompleted, setUploadCompleted] = useState(false)
  const onlyWarningsInUpload = useRef(false)
  const [MUISortModel, setMUISortModel] = useState([])
  const [MUIFilter, setMUIFilter] = useState({})
  const [showingWarningErrorUpload, setShowingWarningErrorUpload] = useState(false)
  const [toggleWarningsErrors, setToggleWarningsErrors] = useState(false)

  const getSelectedRFI = async () => {
    const selectedTabRFIs = await apiCalls.getAll(ACC_Utils.UrlApiTypes.rfi, {})
    const RFI = selectedTabRFIs.find((RFI) => RFI.organizationId === selectedOrg.id && RFI.fiscalCycleId === activeFiscalCycle.id)
    if (!RFI) {
      setSelectedRFI({})
      return
    }
    setSelectedRFI(RFI)
    pageNumber.current = 0
  }

  const getTableSubmitStatus = async () => {
    setACCDataSubmitStatus(selectedRFI?.status ? selectedRFI.status : SubmitStatus.NOT_SUBMITTED)
    setCanSubmitRFIACCData(
      (renderingDBRows.current || onlyWarningsInUpload.current) &&
        (!selectedRFI?.status || UploadSubmitEnabledStatuses.includes(selectedRFI?.status)) &&
        ACCDataTableRows.length > 0
        ? true
        : false
    )
    setCanUploadCSVACCData(!selectedRFI?.status || UploadSubmitEnabledStatuses.includes(selectedRFI?.status) ? true : false)
    setCanExportRFIACCData(() => ACCDataTableRows.length > 0)
  }

  // Overwrite any existing csv entries in the DB with the new data
  const submitRFIData = () => {
    setShowBusyDialog(true)
    apiCalls
      .put(`${ACC_Utils.UrlApiTypes.rfi}/${selectedRFI.id}`, { ...selectedRFI, status: SubmitStatus.SUBMITTED })
      .then(() => {
        getSelectedRFI()
        setShowSnackbarSuccess(true)
        setSnackbarMessage("RFI Submitted Successfully!")
      })
      .catch(() => {
        setShowSnackbarError(true)
        setSnackbarMessage("Failed to submit RFI data")
      })
      .finally(() => setShowBusyDialog(false))
  }

  const restorePrevDBData = async (tmpSelectedRFI) => {
    setShowUploadProgressModal(true)
  }

  const bulkUploadCSVDataToDB = () => {
    apiCalls
      .getAll(`${ACC_Utils.UrlApiTypes.rfi}`, {})
      .then((RFIs) => {
        const tmpSelectedRFI = RFIs.find((RFI) => RFI.organizationId === selectedOrg.id && RFI.fiscalCycleId === activeFiscalCycle.id)
        let formData = new FormData()
        formData.append("FormFile", new Blob([fileSelector.current.files[0]]))
        let warningsList = null
        let errorsList = null
        apiCalls
          .post(`${ACC_Utils.UrlApiTypes.record}/${tmpSelectedRFI.id}/BulkUpload?replaceData=false`, formData, CSVBulkUploadReqHeaders)
          .then((res) => {
            warningsList = res.data.warningsList
            if (warningsList && warningsList.length > 0) {
              renderingDBRows.current = false
              onlyWarningsInUpload.current = true
              setShowingWarningErrorUpload(true)
              setShowWarningUploadModal(true)
            } else renderingDBRows.current = true
            setShowSnackbarSuccess(true)
            setSnackbarMessage("Data successfully uploaded!")
            getSelectedRFI()
            setUploadCompleted(true)
            setShowUploadProgressModal(false)
          })
          .catch((e) => {
            const errObj = e.response.data
            setShowSnackbarError(true)
            renderingDBRows.current = false
            restorePrevDBData(tmpSelectedRFI)
            const errMessage = returnErrorMessageFromAPIError(e)
            if (errMessage) {
              setSnackbarMessage(errMessage)
              return
            }
            if (errObj.topLevelError) {
              setSnackbarMessage(errObj.topLevelError)
              return
            }
            if (errObj.error) {
              setSnackbarMessage(errObj.error)
              return
            }
            if (errObj?.fileError) {
              setSnackbarMessage(errObj.fileError)
              return
            }
            if (errObj && errObj?.fileErrors) {
              // If there are file errors, display them in a snackbar message
              let msg = "Some or all of the headers are incorrect or missing in the uploaded file. Please check the file and try again."
              setSnackbarMessage(msg)
            } else {
              // If there are row errors, display them in the table
              if (errObj && errObj.length > 0) {
                errorsList = errObj.filter((err) => err.code === "Error")
                warningsList = errObj.filter((err) => err.code === "Warning")
                setShowingWarningErrorUpload(true)
                setSnackbarMessage(`Errors found in ${fileSelector.current.files[0].name}`)
              } else {
                setSnackbarMessage("unknown error: please check your internet connection and make sure your file is formatted correctly")
              }
            }
          })
          .finally(() => {
            if (errorsList || warningsList) {
              const { tmpWarningsErrorsTableRows, tmpWarningsErrorsTableRowsWarningsErrorsOnly } = getWarningsErrorsTableRowsFromUploadedTableRows(
                uploadedTableRows,
                errorsList,
                warningsList,
                headerRowNumber.current + 1
              )
              setWarningsErrorsTableRows(tmpWarningsErrorsTableRows)
              setWarningsErrorsTableRowsWarningsErrorsOnly(tmpWarningsErrorsTableRowsWarningsErrorsOnly)
            }
            setFileSize(0)
            fileSelector.current.value = null
          })
      })
      .catch((e) => {
        const errMessage = returnErrorMessageFromAPIError(e)
        setShowSnackbarError(true)
        if (errMessage) {
          setSnackbarMessage(errMessage)
        } else {
          setSnackbarMessage("Unable to load data, please refresh the page and try again")
        }
      })
  }

  // Overwrite any existing csv entries in the DB with the new data
  const persistDBData = () => {
    //Make sure an organization is selected
    if (!selectedOrg.id) {
      setShowSnackbarError(true)
      setSnackbarMessage("Organization not selected, please select an organization from the Organization POCs page")
      return
    }
    apiCalls.getAll(`${ACC_Utils.UrlApiTypes.rfi}`, {}).then(async (allRFIs) => {
      if (allRFIs.length <= 0) {
        apiCalls
          .post(`${ACC_Utils.UrlApiTypes.rfi}`, { organizationId: selectedOrg.id, fiscalCycleId: activeFiscalCycle.id, poc: null })
          .then(() => bulkUploadCSVDataToDB())
      } else {
        const orgFCIdPairs = allRFIs.map((data) => {
          return { id: data.id, orgId: data.organizationId, fcId: data.fiscalCycleId }
        })
        let rfiId = null
        let orgFCIdPairExists = false
        for (let i = 0; i < orgFCIdPairs.length; i++) {
          if (orgFCIdPairs[i].orgId === selectedOrg.id && orgFCIdPairs[i].fcId === activeFiscalCycle.id) {
            orgFCIdPairExists = true
            rfiId = orgFCIdPairs[i].id
            break
          }
        }
        if (!orgFCIdPairExists) {
          apiCalls
            .post(`${ACC_Utils.UrlApiTypes.rfi}`, { organizationId: selectedOrg.id, fiscalCycleId: activeFiscalCycle.id, poc: null })
            .then(() => bulkUploadCSVDataToDB)
        } else {
          setLoadingTitle(CSVUploadProgressMessageTypes.DELETE)
          apiCalls
            .delete(`${ACC_Utils.UrlApiTypes.record}/${rfiId}/BulkDelete`)
            .then(() => {
              setLoadingTitle(CSVUploadProgressMessageTypes.POST)
              bulkUploadCSVDataToDB()
            })
            .catch(() => {
              setShowSnackbarError(true)
              setSnackbarMessage(
                "Server timeout when attempting to delete existing data. No data changes have been made. Check your connection and try again."
              )
              setShowUploadProgressModal(false)
            })
        }
      }
    })
  }

  const handleLoadCSVDataIntoTable = (data) => {
    pageNumber.current = 0
    renderingDBRows.current = true
    setUploadedTableRows(data)
    setCanSubmitRFIACCData(renderingDBRows.current)
  }

  const madeFirstSubmissionOfSession = useRef(false)
  const headerRowNumber = useRef(0)
  const handleReadCSVFile = (file) => {
    setFileSize(file?.size)
    setMUISortModel([])
    setMUIFilter({})
    setShowingWarningErrorUpload(false)
    setToggleWarningsErrors(false)
    onlyWarningsInUpload.current = false
    madeFirstSubmissionOfSession.current = true
    let tmpParsedRows = []
    let parsedHeaders = []
    let headerRowFound = false
    headerRowNumber.current = 0
    // Ignore all other file types that are not 'text/csv'
    if (file.type === "text/csv") {
      setLoadingTitle(CSVUploadProgressMessageTypes.PREPARE)
      setShowUploadProgressModal(true)
      setUploadCompleted(false)
      Papa.parse(file, {
        header: false,
        worker: true,
        delimiter: "",
        skipEmptyLines: true,
        step: (row) => {
          if (!headerRowFound) {
            headerRowNumber.current += 1
            // Find the header row
            parsedHeaders = row.data
              .map((header) => formatHeader(header)?.replace(/\s/g, ""))
              .filter((header) => header.toLowerCase() !== "fiscalyear")
            parsedHeaders.map((potentialHeader) => {
              for (let i = 0; i < ACC_Utils.ExpectedACCHeaders.length; i++) {
                if (ACC_Utils.ExpectedACCHeaders[i].toLowerCase() === potentialHeader.toLowerCase()) {
                  matchedHeaderCount.current += 1
                  if (matchedHeaderCount.current >= ACC_Utils.ExpectedACCHeaders.length) {
                    headerRowFound = true
                  }
                  break
                }
              }
            })
            matchedHeaderCount.current = 0
          } else {
            // Data Row
            let parsedRow = {}
            for (let i = 0; i < ACC_Utils.ExpectedACCHeaders.length; i++) {
              const fieldValue = row?.data[i] ? row.data[i].trim() : ""
              parsedRow[ACC_Utils.ExpectedACCHeaders[i]] = fieldValue === "#" ? null : fieldValue
            }
            tmpParsedRows.push(parsedRow)
          }
        },
        complete: () => {
          setTimeout(() => {
            const trimmedParsedRows = tmpParsedRows.filter((row) => Object.values(row).filter((value) => value !== "").length > 0)
            handleLoadCSVDataIntoTable(trimmedParsedRows)
            headerRowFound = false
          }, 1000)
        },
      })
    } else {
      setShowSnackbarError(true)
      setSnackbarMessage("Cannot upload data: The file type is not supported. Please upload a CSV file.")
    }
  }

  const origionalWarningsErrorsTableRows = useRef([])
  const updateTableForTab = (apiType, rfiIdFieldName, skip) => {
    if (renderingDBRows.current) {
      const queryParams = getQueryParamsOnTableUpdateFromDB(
        MUIFilter,
        MUISortModel,
        pageSize,
        skip,
        rfiIdFieldName,
        selectedRFI,
        ACC_Utils.NumericFields.ACC_DATA
      )
      if (selectedRFI.id) {
        apiCalls
          .getAllCount(apiType, { [QueryParamTypes.FILTER]: queryParams[QueryParamTypes.FILTER] })
          .then((count) => {
            apiCalls
              .getAll(apiType, queryParams)
              .then((pageRows) => {
                let pageTableRows = []
                numTotalTableRows.current = count
                pageRows.forEach((data) =>
                  pageTableRows.push({
                    Id: data.id ? data.id : uuidv4(),
                    [ACC_Utils.ACCDataFields.LOCAL_CONTRACT_NUMBER]: data.localContractNumber,
                    [ACC_Utils.ACCDataFields.ACTIONS_AGAINST_A_CAR_COUNT]: data.numberOfActions,
                    [ACC_Utils.ACCDataFields.CAR_ISSUED_COUNT]: data.carIssuedCount,
                  })
                )
                setShowBusyDialog(false)
                setACCDataTableRows(pageTableRows)
                if (MUIFilter?.value && MUIFilter.value.length > 0) {
                  setShowSnackbarSuccess(true)
                  setSnackbarMessage(`Filtered ACC data`)
                }
              })
              .finally(() => setShowBusyDialog(false))
          })
          .catch((e) => {
            console.error(e)
            setShowSnackbarError(true)
            if (MUIFilter?.value && MUIFilter.value.length > 0) {
              setSnackbarMessage(
                `Failed to filter field: the entered value ${MUIFilter?.value ? `'${MUIFilter.value}'` : ""} is not allowed or the ${
                  MUIFilter?.operator ? `'${MUIFilter.operator}'` : ""
                } operator is not allowed for the ${MUIFilter?.tableField ? `'${MUIFilter.tableField}'` : ""} field.`
              )
            } else {
              setSnackbarMessage(`Failed to retrieve ACC data`)
            }
          })
      } else {
        setACCDataTableRows([])
        pageNumber.current = 0
        numTotalTableRows.current = 0
        setShowBusyDialog(false)
      }
    } else {
      const warningsErrorsTableRowsForDisplay = toggleWarningsErrors ? warningsErrorsTableRowsWarningsErrorsOnly : warningsErrorsTableRows
      updateMUITableWithWarningsErrorsRows(
        MUIFilter,
        MUISortModel,
        warningsErrorsTableRowsForDisplay,
        origionalWarningsErrorsTableRows,
        numTotalTableRows,
        setACCDataTableRows,
        null,
        skip,
        pageSize,
        ACC_Utils.NumericFields.ACC_DATA,
        setShowSnackbarError,
        setSnackbarMessage
      )
      setShowBusyDialog(false)
    }
  }

  const updateTableRows = () => {
    if (!selectedOrg?.id || pageNumber?.current == null || pageNumber.current < 0 || pageSize?.current == null) {
      setShowBusyDialog(false)
      return
    }
    const skip = pageNumber.current * pageSize.current
    updateTableForTab(ACC_Utils.UrlApiTypes.record, ACC_Utils.UrlApiTypes.rfiId, skip)
  }

  const handleOnPageSizeChange = (pgSize) => {
    pageSize.current = pgSize
    if (pageNumber.current * pgSize + 1 > numTotalTableRows.current) {
      pageNumber.current = 0
    }
    handleOnPageChange(pageNumber.current)
  }

  const handleOnPageChange = (pgNumber) => {
    if (pgNumber < 0) pgNumber = 0
    pageNumber.current = pgNumber
    updateTableRows()
  }

  const adjustTableHeight = () => {
    setMUITableHeight(window.innerHeight - 350)
  }

  const requestResubmission = () => {
    setShowBusyDialog(true)
    apiCalls
      .put(`${ACC_Utils.UrlApiTypes.rfi}/${selectedRFI.id}`, { ...selectedRFI, status: SubmitStatus.REQUEST_RESUBMISSION })
      .then(() => {
        getSelectedRFI()
        setShowSnackbarSuccess(true)
        setSnackbarMessage("Resubmission requested for RFI!")
      })
      .catch(() => {
        setShowSnackbarError(true)
        setSnackbarMessage("Failed to request resubmission for RFI")
      })
      .finally(() => setShowBusyDialog(false))
  }

  const handleOnSortModelChange = (MUISortModel) => {
    if (!MUISortModel[0]) {
      setMUISortModel([{}])
    } else {
      if (renderingDBRows.current && MUISortModel[0]?.field === ACC_Utils.ACCDataFields.ACTIONS_AGAINST_A_CAR_COUNT) {
        setMUISortModel([{ ...MUISortModel[0], field: "NumberOfActions" }])
        return
      }
      setMUISortModel(MUISortModel)
    }
  }

  const handleFilterRFIData = (tableField, dataField, operator, value) => {
    if (dataField === ACC_Utils.ACCDataFields.ACTIONS_AGAINST_A_CAR_COUNT) {
      dataField = "NumberOfActions"
    }
    setMUIFilter({ tableField, dataField, operator, value })
  }

  const handleExportRFIData = () => {
    //setLoadingTitle(CSVUploadProgressMessageTypes.EXPORT)
  }

  useEffect(() => {
    if (MUIFilter?.value != null) {
      pageNumber.current = 0
      updateTableRows()
    }
  }, [MUIFilter])

  useEffect(() => {
    if (MUISortModel[0]) {
      pageNumber.current = 0
      setShowBusyDialog(true)
      updateTableRows()
    }
  }, [MUISortModel])

  useEffect(() => {
    setWarningsErrorsTableRows([])
    if (selectedOrg?.id) {
      if (uploadedTableRows?.length > 0) {
        persistDBData()
      } else {
        if (madeFirstSubmissionOfSession.current) {
          setShowSnackbarError(true)
          setSnackbarMessage("Some or all of the headers are incorrect or missing in the uploaded file. Please check the file and try again.")
        }
        setShowUploadProgressModal(false)
      }
    }
  }, [uploadedTableRows])

  useEffect(() => {
    const warningErrorsTableRowsForDisplay = toggleWarningsErrors ? warningsErrorsTableRowsWarningsErrorsOnly : warningsErrorsTableRows
    pageNumber.current = 0
    const skip = 0
    updateMUITableWithWarningsErrorsRows(
      MUIFilter,
      MUISortModel,
      warningErrorsTableRowsForDisplay,
      origionalWarningsErrorsTableRows,
      numTotalTableRows,
      setACCDataTableRows,
      null,
      skip,
      pageSize,
      ACC_Utils.NumericFields,
      setShowSnackbarError,
      setSnackbarMessage
    )
  }, [showingWarningErrorUpload, toggleWarningsErrors])

  useEffect(() => {
    if (warningsErrorsTableRows?.length > 0) {
      updateTableRows()
    }
  }, [warningsErrorsTableRows])

  useEffect(() => {
    getTableSubmitStatus()
  }, [ACCDataTableRows])

  useEffect(() => {
    renderingDBRows.current = true
    onlyWarningsInUpload.current = false
    setShowingWarningErrorUpload(false)
    if (selectedOrg?.id) {
      getSelectedRFI()
    }
    setMUISortModel([])
  }, [selectedOrg, activeFiscalCycle])

  useEffect(() => {
    setShowBusyDialog(true)
    if (MUIFilter?.value && MUIFilter.value.length > 0) {
      setMUIFilter({
        tableField: "",
        dataField: "",
        operator: "equals",
        value: "",
      })
    } else {
      updateTableRows()
    }
    getTableSubmitStatus()
  }, [selectedRFI])

  useEffect(() => {
    adjustTableHeight()
  }, [location])

  useEffect(() => {
    window.addEventListener("resize", adjustTableHeight)
    if (selectedOrg?.id) {
      getSelectedRFI()
    }
  }, [])

  return (
    <Box margin={2}>
      <Typography variant="h4">ACC RFI CSV Upload</Typography>
      <div className="--RFI-table-actions">
        {/************************************************ [RFI Instructions Modal] ************************************************/}
        {/* PUT RFI Instructions here */}
        <ACC_Instructions_Modal
          disclaimerRFIModalOpen={disclaimerRFIModalOpen}
          setDisclaimerRFIModalOpen={setDisclaimerRFIModalOpen}
        />
        <div className="--RFI-disclaimer-link">
          <Button
            variant="outlined"
            color="info"
            startIcon={<Help />}
            onClick={() => setDisclaimerRFIModalOpen(true)}
          >
            RFI Instructions
          </Button>
        </div>
        {/************************************** Import CSV file here ********************************************************/}
        <Stack
          direction="row"
          spacing={1}
        >
          <Button
            variant="outlined"
            onClick={() => setShowRequestResubmissionWarningModal(true)}
            hidden={ACCDataSubmitStatus !== SubmitStatus.SUBMITTED}
          >
            REQUEST RESUBMISSION
          </Button>
          <Button
            variant="outlined"
            disabled={!canUploadCSVACCData}
            onClick={() => fileSelector.current.click()}
          >
            UPLOAD CSV FILE
            <input
              type="file"
              ref={fileSelector}
              onChangeCapture={(e) => handleReadCSVFile(e.target.files[0])}
              hidden
            />
          </Button>
          <Button
            variant="contained"
            onClick={() => setShowSubmitRFIWarningModal(true)}
            disabled={!canSubmitRFIACCData}
          >
            SUBMIT RFI DATA
          </Button>
          <RfiStatusChip status={ACCDataSubmitStatus} />
        </Stack>
      </div>

      <div className="--RFI-table-container">
        {/* DataGrid */}
        <div style={{ minHeight: "500px", maxHeight: "75vh", width: "100%", display: "flex", flexDirection: "column" }}>
          {/* 
          <RFIGridToolbar
            selectedTab="ACC"
            selectedFiscalCycle={activeFiscalCycle}
            selectedOrgName={selectedOrg.name}
            headers={Object.values(ACC_Utils.ACCDataFields).filter((h) => !ACC_Utils.DateFields.ACC_DATA.includes(h))}
            dataHeaders={null}
            handleExportRFIData={handleExportRFIData}
            handleFilterRFIData={handleFilterRFIData}
            showingWarningErrorUpload={showingWarningErrorUpload}
            toggleWarningsErrors={toggleWarningsErrors}
            setToggleWarningsErrors={setToggleWarningsErrors}
            warningsOnly={onlyWarningsInUpload.current}
            canExportRFIData={canExportRFIACCData}
          />
 */}
          <DataGridPremium
            rows={ACCDataTableRows}
            columns={ACC_Utils.ACC_COLS}
            density="compact"
            sortingMode="server"
            onSortModelChange={handleOnSortModelChange}
            rowsPerPageOptions={[25, 40, 50, 60, 75, 100]}
            onPageSizeChange={(newPageSize) => handleOnPageSizeChange(newPageSize)}
            pagination
            loading={showBusyDialog}
            getRowId={() => uuidv4()}
            getRowClassName={(params) => {
              if (params.row.Warnings_Errors && params.row.Warnings_Errors.errors.length > 0) {
                return "--RFI-warnings-errors-row"
              } else if (params.row.Warnings_Errors && params.row.Warnings_Errors.warnings.length > 0) {
                return "--RFI-warnings-row"
              }
              if (params.indexRelativeToCurrentPage % 2 === 0) {
                return "even-row"
              } else return "odd-row"
            }}
            getCellClassName={(params) => {
              if (params.field === "Warnings_Errors") {
                return "--RFI-warning-errors-cell"
              }
            }}
            initialState={{
              columns: {
                columnVisibilityModel: { Id: false },
              },
            }}
          />
          <TableControls
            pageNumber={pageNumber.current}
            pageSize={pageSize.current}
            numTotalTableRows={numTotalTableRows.current}
            handleOnPageChange={handleOnPageChange}
          />
        </div>
      </div>
      <UploadProgressModal
        open={showUploadProgressModal}
        title={loadingTitle}
        setTitle={setLoadingTitle}
        fileSize={fileSize}
        uploadCompleted={uploadCompleted}
        trackProgress={true}
      />
      <SnackbarMessages
        showSnackbarSuccess={showSnackbarSuccess}
        setShowSnackbarSuccess={setShowSnackbarSuccess}
        showSnackbarError={showSnackbarError}
        setShowSnackbarError={setShowSnackbarError}
        snackbarMessage={snackbarMessage}
      />
      <RequestResubmissionWarningModal
        open={showRequestResubmissionWarningModal}
        setOpen={setShowRequestResubmissionWarningModal}
        requestResubmission={requestResubmission}
      />
      <SubmitRFIWarningModal
        showSubmitRFIWarningModal={showSubmitRFIWarningModal}
        setShowSubmitRFIWarningModal={setShowSubmitRFIWarningModal}
        msg={`If you submit RFI data, you won't be able to reupload data unless an administrator puts the ACC table back to a "Not Submitted" state in the ${selectedOrg.name} organization.`}
        submitRFIData={submitRFIData}
      />
      <ReviewWarningsModal
        showWarningUploadModal={showWarningUploadModal}
        setShowWarningUploadModal={setShowWarningUploadModal}
      />
    </Box>
  )
}

export default ACC
