import React, { useState, useEffect, useContext } from "react"
import dayjs from "dayjs"
import { Button, Checkbox, CardHeader, Divider, Box } from "@mui/material"
import { DataGrid, GridToolbar } from "@mui/x-data-grid"
import SnackbarMessages from "../../SnackbarMessages"
import { ActiveState, MinTableHeight } from "../../../Utils"
import { apiCalls, QueryParamTypes } from "../../DataService"
import { NewFiscalCycle } from "./NewFiscalCycle"
import { Close, Edit } from "@mui/icons-material"
import { DeleteFCConfirmation } from "./DeleteFCConfirmation"
import { EditFiscalCycle } from "./EditFiscalCycle"
import AppContext from "../../../AppContext"

const FiscalCycles = () => {
  // Initialize App-level Data
  const context = useContext(AppContext)
  const { filterOrganizations, setActiveFiscalCycle } = context

  // For now, going to make each page responsible for providing what it supports but there is a mapping in utils (RFIViewOrgMappings)
  useEffect(() => {
    filterOrganizations(["DASA DE&C"])
  }, [])

  const [showBusyDialog, setShowBusyDialog] = useState(false)
  const [showSnackbarSuccess, setShowSnackbarSuccess] = useState(false)
  const [showSnackbarError, setShowSnackbarError] = useState(false)
  const [snackbarMessage, setSnackbarMessage] = useState("")
  const [fiscalCycleTableRows, setFiscalCycleTableRows] = useState([])
  const [newFiscalCycleModalOpen, setNewFiscalCycleModalOpen] = useState(false)
  const fiscalCycleTableCols = [
    {
      field: "id",
      hide: true,
      headerClassName: "--grid-header",
    },
    {
      field: "fiscalYear",
      headerName: "Data Update FY",
      flex: 2,
      headerClassName: "--grid-header",
      minWidth: 125,
    },
    {
      field: "startDate",
      headerName: "Start Date",
      flex: 2,
      // type: "date",
      headerClassName: "--grid-header",
      minWidth: 125,
      renderCell: (params) => {
        if (!params.value) return null
        return <div className="MuiDataGrid-cellContent">{dayjs(params.value).startOf("day").format("MM/DD/YYYY, h:mm:ss a")}</div>
      },
    },
    {
      field: "endDate",
      headerName: "End Date",
      flex: 2,
      // type: "date",
      headerClassName: "--grid-header",
      minWidth: 125,
      renderCell: (params) => {
        if (!params.value) return null
        return <div className="MuiDataGrid-cellContent">{dayjs(params.value).endOf("day").format("MM/DD/YYYY, h:mm:ss a")}</div>
      },
    },
    {
      field: "dataUpdateStatus",
      headerName: "Data Update Status",
      flex: 1,
      minWidth: 100,
      headerClassName: "--RFI-grid-header",
      renderCell: (params) => {
        if (!params?.row) return null
        return (
          <Checkbox
            checked={params.row.dataUpdateStatus === ActiveState.ACTIVE}
            onChange={() => updateFCActiveStatusesInTable(params.row)}
          />
        )
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      sortable: false,
      flex: 1,
      headerClassName: "--grid-header",
      minWidth: 150,
      renderCell: (params) => {
        const unDeletableFYs = ["FY21", "FY22"]
        if (unDeletableFYs.includes(params.row.fiscalYear)) return null
        return (
          <div className="edit-delete-buttons">
            <button style={params.id % 2 === 0 ? { backgroundColor: "#ddd" } : { backgroundColor: "white" }}>
              <Edit
                onClick={() => {
                  setFCToEdit(params.row)
                  setOpenEditFCModal(true)
                }}
              />
            </button>
            <button style={params.id % 2 === 0 ? { backgroundColor: "#ddd" } : { backgroundColor: "white" }}>
              <Close
                onClick={() => {
                  setFCToDelete(params.row)
                  setOpenDeleteFCModal(true)
                }}
              />
            </button>
          </div>
        )
      },
    },
  ]
  const [openDeleteFCModal, setOpenDeleteFCModal] = useState(false)
  const [fcToDelete, setFCToDelete] = useState({})
  const [openEditFCModal, setOpenEditFCModal] = useState(false)
  const [fcToEdit, setFCToEdit] = useState({})

  const [selectedActiveFY, setSelectedActiveFY] = useState({})

  const toggleModal = () => {
    setNewFiscalCycleModalOpen((prevState) => !prevState)
  }

  const handleDeleteFiscalCycle = (id) => {
    apiCalls.delete(`FiscalCycles/${id}`).then((res) => {
      if (!res?.data?.Success && res.data.Success === false) {
        setSnackbarMessage("Unable to delete this Fiscal Cycle due to dependency constraints.")
        setShowSnackbarError(true)
        return
      }
      setSnackbarMessage("Fiscal Cycle deleted successfully.")
      setShowSnackbarSuccess(true)
      loadFiscalCyclesFromDB()
    })
  }

  const updateFCActiveStatusesInTable = (row) => {
    let updatedFiscalCycles = [...fiscalCycleTableRows]
    updatedFiscalCycles.forEach((fc) => {
      if (fc.id === row.id) {
        setSelectedActiveFY(fc)
      }
      fc.dataUpdateStatus = fc.id === row.id ? ActiveState.ACTIVE : ActiveState.INACTIVE
    })
    setFiscalCycleTableRows(updatedFiscalCycles)
  }

  const updateFCActiveStatusesInDB = async () => {
    let updatedFiscalCycles = [...fiscalCycleTableRows]
    updatedFiscalCycles.forEach((fc) => {
      const tmpFc = {
        ...fc,
        fiscalYear: Number(fc.fiscalYear.slice(2, 4)) + 2000,
      }
      apiCalls.put(`FiscalCycles/${fc.id}`, tmpFc).catch((e) => {
        console.error(e)
        setSnackbarMessage("Fiscal Cycle update failed.")
        setShowSnackbarError(true)
      })
    })
  }

  const handleMakeDBCalls = async () => {
    setShowBusyDialog(true)
    updateFCActiveStatusesInDB()
      .then(() => {
        setSnackbarMessage(`Fiscal Cycle update successful.`)
        setShowSnackbarSuccess(true)
        apiCalls.getById(`FiscalCycles/${selectedActiveFY.id}`).then((res) => {
          setActiveFiscalCycle(res)
        })
      })
      .finally(() => {
        setShowBusyDialog(false)
        loadFiscalCyclesFromDB()
      })
  }

  const loadFiscalCyclesFromDB = () => {
    apiCalls.getAll("FiscalCycles", { [QueryParamTypes.ORDER_BY]: "startDate desc" }).then((data) => {
      setFiscalCycleTableRows(data)
    })
  }

  useEffect(() => {
    loadFiscalCyclesFromDB()
  }, [])

  return (
    <Box
      container
      margin={2}
      spacing={2}
    >
      <CardHeader
        title="Fiscal Cycle Management"
        sx={{
          padding: "0px",
          "& .MuiTypography-root": {
            fontFamily: "Bitter",
            fontWeight: "bold",
          },
        }}
      />
      <Divider />
      <div className="--RFI-table-actions">
        <div />
        <Button
          onClick={toggleModal}
          variant="outlined"
        >
          <strong>New Fiscal Cycle</strong>
        </Button>
      </div>
      <DataGrid
        rows={fiscalCycleTableRows}
        columns={fiscalCycleTableCols}
        density="compact"
        components={{ Toolbar: GridToolbar }}
        loading={showBusyDialog}
        autoHeight={true}
        pageSize={25}
        sx={{
          border: 2,
          borderColor: "black",
        }}
        getRowClassName={(params) => {
          if (params.indexRelativeToCurrentPage % 2 === 0) {
            return "even-row"
          } else return "odd-row"
        }}
        initialState={{
          columns: {
            columnVisibilityModel: { id: false },
          },
        }}
      />
      <div className="--RFI-table-actions">
        <div />
        <Button
          onClick={handleMakeDBCalls}
          variant="outlined"
        >
          <strong>Update</strong>
        </Button>
      </div>
      <EditFiscalCycle
        fcToEdit={fcToEdit}
        setFCToEdit={setFCToEdit}
        isOpen={openEditFCModal}
        toggleModal={setOpenEditFCModal}
        loadFiscalCyclesFromDB={loadFiscalCyclesFromDB}
        setSnackbarMessage={setSnackbarMessage}
        setShowSnackbarError={setShowSnackbarError}
        setShowSnackbarSuccess={setShowSnackbarSuccess}
      />
      <NewFiscalCycle
        isOpen={newFiscalCycleModalOpen}
        toggleModal={toggleModal}
        loadFiscalCyclesFromDB={loadFiscalCyclesFromDB}
        fiscalCycleTableRows={fiscalCycleTableRows}
        setSnackbarMessage={setSnackbarMessage}
        setShowSnackbarError={setShowSnackbarError}
        setShowSnackbarSuccess={setShowSnackbarSuccess}
      />
      <DeleteFCConfirmation
        open={openDeleteFCModal}
        setOpen={setOpenDeleteFCModal}
        fcToDelete={fcToDelete}
        handleDeleteFiscalCycle={handleDeleteFiscalCycle}
      />
      <SnackbarMessages
        showSnackbarSuccess={showSnackbarSuccess}
        setShowSnackbarSuccess={setShowSnackbarSuccess}
        showSnackbarError={showSnackbarError}
        setShowSnackbarError={setShowSnackbarError}
        snackbarMessage={snackbarMessage}
      />
    </Box>
  )
}

export default FiscalCycles
