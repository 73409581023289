import SAPBR_Utils from "./components/Admin/SAPBR/SAPBR_Utils"
import { QueryParamTypes } from "./components/DataService"
import DSAMSTableUtils from "./components/RFIs/DSAMS-CISIL/DSAMS_Table_Utils"
import { validateGenericCode } from "./components/RFIs/DSAMS-CISIL/DSAMS_Table_Utils"
import { validateSDRNumber } from "./components/RFIs/DSAMS-CISIL/DSAMS_Table_Utils"
import { validateUserCaseId } from "./components/RFIs/DSAMS-CISIL/DSAMS_Table_Utils"
import GFEBS_CEFMS_Table_Utils, { validateRecordedHours } from "./components/RFIs/GFEBS-CEFMS/GFEBS_CEFMS_Table_Utils"
import TDAUtils from "./components/RFIs/TDA/TDAUtils"
import USACEUtils from "./components/RFIs/PEO-PM/USACEUtils"
import PEO_PM_Utils from "./components/RFIs/PEO-PM/PEO_PM_Utils"
import ACC_Utils from "./components/RFIs/ACC/ACC_Utils"
import SATFA_Utils from "./components/RFIs/SATFA/SATFA_Utils"
import Supplemental_Workforce_Utils from "./components/RFIs/Supplemental-Workforce/SupplementalWorkforceUtils"
import { apiCalls } from "./components/DataService"

export const SessionStorageKeys = {
  RFI_REVIEW_PAGE_REFRESHED: "rfiReviewPageRefreshed",
}

export const CWATColors = {
  PRIMARY: "#225782",
  ERROR: "#a04326",
  WARNING: "#bdaa02",
}

export const CSVBulkUploadReqHeaders = {
  headers: {
    Accept: "application/octet-stream, multipart/form-data",
    "Content-Type": "multipart/form-data, application/octet-stream",
  },
}

export const CSVUploadProgressMessageTypes = {
  PREPARE: "Preparing CSV file...",
  DELETE: "Deleting existing records...",
  POST: "Saving records to database - this may take several minutes...",
  RESTORE: "Upload Failed (restoring previous data) - this may take several minutes...",
  FINISH: "Finishing upload process...",
  EXPORT: "Exporting RFI Data...",
}

export const OrganizationRoles = {
  VIEW_ONLY: "View Only",
  FINAL_APPROVER: "Organization - Final Approver",
  USER_ACCESS_DATA_LOADER: "User Access - Data Loader",
  SUPER_USER: "Super User",
}

export const GlobalRoles = {
  SYSTEM_ADMINISTRATOR: "System Administrator",
  ENTERPRISE_USER: "Enterprise User",
  // ORGANIZATIONAL_USER: "Organizational User",
}

export const getNumRowsInFile = (file) => {
  const fileReader = new FileReader()
  let numRowsInFile = 0
  fileReader.readAsText(file)
  fileReader.onload = async (e) => {
    const csvData = e.target.result
    const csvDataRows = csvData.split(/\r?\n|\r/)
    numRowsInFile = csvDataRows.length
  }
  setTimeout(() => {
    return numRowsInFile
  }, 1000)
}

export const getFYOffset = (fiscalYear, offset) => {
  const fiscalYearNum = parseInt(fiscalYear.slice(-2)) + offset
  return fiscalYearNum < 10 ? `FY0${fiscalYearNum}` : `FY${fiscalYearNum}`
}

const getCurrentFiscalYear = () => {
  const currentMonth = new Date().getMonth()
  const currentYear = new Date().getFullYear()
  const currentFYNum = currentMonth > 9 ? currentYear + 1 : currentYear
  return "FY" + currentFYNum.toString().slice(-2)
}

export const get4DigitYearFromFY = (fy) => {
  const year = fy.slice(-2)
  return "20" + year
}

export const calculateFTEValue = (leave, total) => {
  // if (leave <= 0) return '0.00'
  return leave / total < 0.1 ? Math.abs(Number((total - leave) / 1740).toFixed(2)) : Math.abs(Number(total / 2080)).toFixed(2)
}

export const CurrentFiscalYear = getCurrentFiscalYear()

export const MinTableHeight = 400

export const AllRoles = [
  GlobalRoles.SYSTEM_ADMINISTRATOR,
  GlobalRoles.ENTERPRISE_USER,
  OrganizationRoles.VIEW_ONLY,
  OrganizationRoles.FINAL_APPROVER,
  OrganizationRoles.USER_ACCESS_DATA_LOADER,
  OrganizationRoles.SUPER_USER,
]

export const AllGlobalRoles = [GlobalRoles.SYSTEM_ADMINISTRATOR, GlobalRoles.ENTERPRISE_USER]

export const AllOrganizationRoles = [
  OrganizationRoles.VIEW_ONLY,
  OrganizationRoles.FINAL_APPROVER,
  OrganizationRoles.USER_ACCESS_DATA_LOADER,
  OrganizationRoles.SUPER_USER,
]

export const DASA_DEC_ORG_ID = "092C4034-7F00-4451-BF1E-2902EC5729EF"

export const RFIGroupTypes = {
  DSAMS_CISIL: "DSAMS/CISIL",
  GFEBS_CEFMS: "GFEBS/CEFMS",
  SUPPLEMENTAL_WORKFORCE: "Supplemental Workforce",
  ACC: "ACC",
  SATFA: "SATFA",
  USACE: "USACE",
  PEO_PM: "PEO PM",
  SAPBR: "SAPBR",
  TDA: "TDA",
}

export const SelectedTabToRFIMapping = {
  [DSAMSTableUtils.Tabs.MILESTONES]: "dsamsMilestone",
  [DSAMSTableUtils.Tabs.PREPARING_ACTIVITY_DATA]: "dsamsPreparingActivityData",
  [DSAMSTableUtils.Tabs.CISIL_CCOPES_LINE_DATA]: "cisilCcopesLineData",
  [DSAMSTableUtils.Tabs.CISIL_CASES_CERT_CLOSURE]: "cisilCasesCertClosure",
  [DSAMSTableUtils.Tabs.CISIL_CASES_CERT_CLOSURE_WO_PRI_ONE_DATE]: "cisilCasesCertClosureAll",
  [DSAMSTableUtils.Tabs.CISIL_REQUISITIONS]: "cisilRequisition",
  [DSAMSTableUtils.Tabs.CISIL_SDRs]: "cisilSdr",
  [DSAMSTableUtils.Tabs.CISIL_DATA_DESCRIPTIONS]: "cisilDataDescription",
  [GFEBS_CEFMS_Table_Utils.Tabs.GFEBS_DATA_REPORT1]: "gfebsReport1",
  [GFEBS_CEFMS_Table_Utils.Tabs.GFEBS_DATA_REPORT2]: "gfebsReport2",
  [GFEBS_CEFMS_Table_Utils.Tabs.CEFMS_DATA]: "cefms",
  ACC: "accWorkload",
  SATFA: "satfaSpecialCase",
  PEO_PM: "peoPm",
  SATMO: "satmo",
  USACE: "usace",
  [Supplemental_Workforce_Utils.Tabs.SUPPLEMENTAL_WORKFORCE_RFI]: "supplementalWorkforce",
  [Supplemental_Workforce_Utils.Tabs.BPC]: "bpcReduction",
  [Supplemental_Workforce_Utils.Tabs.CIVILIAN_PERCENTAGES]: "civilianPercentages",
  [SAPBR_Utils.Tabs.SAPBR]: "sapbr",
  [SAPBR_Utils.Tabs.RMD]: "rmd",
  TDA: "tda",
}

export const RFIOrgMappingAPITypes = {
  accWorkload: "AccWorkloadRfis",
  bpcReduction: "BpcReductionRfis",
  cefms: "CefmsRfis",
  cisilCasesCertClosure: "CisilCasesCertClosureRfis",
  cisilCasesCertClosureAll: "CisilCasesCertClosureAllRfis",
  cisilCcopesLineData: "CisilCcopesLineDataRfis",
  cisilDataDescription: "CisilDataDescriptionRfis",
  cisilRequisition: "CisilRequisitionRfis",
  cisilSdr: "CisilSdrRfis",
  civilianPercentages: "SupplementalWorkforceRfis",
  dsamsMilestone: "DsamsMilestoneRfis",
  dsamsPreparingActivityData: "DsamsPreparingActivityDataRfis",
  gfebsReport1: "GfebsReportRfis",
  gfebsReport2: "GfebsReportRfis",
  peoPm: "PeoPmRfis",
  rmd: "RmdRfis",
  sapbr: "SapbrRfis",
  satfaSpecialCase: "SatfaSpecialCaseRfis",
  satmo: "SatmoRfis",
  supplementalWorkforce: "SupplementalWorkforceRfis",
  tda: "TdaRfis",
  usace: "UsaceHCaseExceptionRfis",
}

export const Record_API_TableNames = {
  [RFIGroupTypes.DSAMS_CISIL]: {
    MILESTONES: "DsamsMilestoneRfis",
    PREPARING_ACTIVITY_DATA: "DsamsPreparingActivityDataRfis",
    CISIL_CCOPES_LINE_DATA: "CisilCcopesLineDataRfis",
    CISIL_CASES_CERT_CLOSURE: "CisilCasesCertClosureRfis",
    CISIL_CASES_CERT_CLOSURE_ALL: "CisilCasesCertClosureAllRfis",
    CISIL_REQUISITIONS: "CisilRequisitionRfis",
    CISIL_SDRs: "CisilSdrRfis",
    CISIL_DATA_DESCRIPTIONS: "CisilDataDescriptionRfis",
  },
  [RFIGroupTypes.GFEBS_CEFMS]: {
    GFEBS: "GfebsReportRfis",
    CEFMS: "CefmsRfis",
  },
  [RFIGroupTypes.SUPPLEMENTAL_WORKFORCE]: {
    SUPPLEMENTAL_WORKFORCE: "SupplementalWorkforceRfis",
    BPC_REDUCTION: "BpcReductionRfis",
  },
  [RFIGroupTypes.ACC]: {
    ACC: "AccWorkloadRfis",
  },
  [RFIGroupTypes.SATFA]: {
    SATFA: "SatfaSpecialCaseRfis",
  },
  [RFIGroupTypes.USACE]: {
    USACE_H_CASE_EXCEPTION: "UsaceHCaseExceptionRfis",
    USACE_SCIP: "UsaceScipRfis",
  },
  [RFIGroupTypes.PEO_PM]: {
    PEO_PM: "PeoPmRfis",
  },
  [RFIGroupTypes.SAPBR]: {
    SAPBR: "SapbrRfis",
    RMD: "RmdRfis",
  },
  [RFIGroupTypes.TDA]: {
    TDA: "TdaRfis",
  },
}

export const RFI_API_TableNames = {
  [RFIGroupTypes.DSAMS_CISIL]: {
    MILESTONES: "DsamsMilestoneRfis",
    PREPARING_ACTIVITY_DATA: "DsamsPreparingActivityDataRfis",
    CISIL_CCOPES_LINE_DATA: "CisilCcopesLineDataRfis",
    CISIL_CASES_CERT_CLOSURE: "CisilCasesCertClosureRfis",
    CISIL_CASES_CERT_CLOSURE_ALL: "CisilCasesCertClosureAllRfis",
    CISIL_REQUISITIONS: "CisilRequisitionRfis",
    CISIL_SDRs: "CisilSdrRfis",
    CISIL_DATA_DESCRIPTIONS: "CisilDataDescriptionRfis",
  },
  [RFIGroupTypes.GFEBS_CEFMS]: {
    GFEBS: "GfebsReportRfis",
    CEFMS: "CefmsRfis",
  },
  [RFIGroupTypes.SUPPLEMENTAL_WORKFORCE]: {
    SUPPLEMENTAL_WORKFORCE: "SupplementalWorkforceRfis",
    BPC_REDUCTION: "BpcReductionRfis",
  },
  [RFIGroupTypes.ACC]: {
    ACC: "AccWorkloadRfis",
  },
  [RFIGroupTypes.SATFA]: {
    SATFA: "SatfaSpecialCaseRfis",
  },
  [RFIGroupTypes.USACE]: {
    USACE_H_CASE_EXCEPTION: "UsaceHCaseExceptionRfis",
    USACE_SCIP: "UsaceScipRfis",
  },
  [RFIGroupTypes.PEO_PM]: {
    PEO_PM: "PeoPmRfis",
  },
  [RFIGroupTypes.SAPBR]: {
    SAPBR: "SapbrRfis",
    RMD: "RmdRfis",
  },
  [RFIGroupTypes.TDA]: {
    TDA: "TdaRfis",
  },
}

export const RFIGroupings = {
  DSAMS_CISIL: "DSAMS/CISIL",
  PEOPM_SATMO_USACE: "PEO PM/SATMO/USACE",
  SPECIAL_ORGS: "SPECIAL ORGS",
  GFEBS_CEFMS: "GFEBS/CEFMS",
  SUPPLEMENTAL_WORKFORCE: "SUPPLEMENTAL WORKFORCE",
  SAPBR_TDA: "SAPBR/TDA",
}

export const RFIGroupingAPITypes = {
  [RFIGroupings.DSAMS_CISIL]: [
    {
      rfiTableName: RFI_API_TableNames[RFIGroupTypes.DSAMS_CISIL].MILESTONES,
      apiType: Record_API_TableNames[RFIGroupTypes.DSAMS_CISIL].MILESTONES,
      fields: Object.values(DSAMSTableUtils.MilestonesFields),
      rfiMappingName: "dsamsMilestone",
      title: "DSAMS Milestones",
      rfiViewPathName: "/DSAMS-CISIL-RFIs",
      rfiViewTab: DSAMSTableUtils.Tabs.MILESTONES,
      rows: [],
    },
    {
      rfiTableName: RFI_API_TableNames[RFIGroupTypes.DSAMS_CISIL].PREPARING_ACTIVITY_DATA,
      apiType: Record_API_TableNames[RFIGroupTypes.DSAMS_CISIL].PREPARING_ACTIVITY_DATA,
      fields: Object.values(DSAMSTableUtils.ActivityDataFields),
      rfiMappingName: "dsamsPreparingActivityData",
      title: "DSAMS Preparing Activity Data",
      rfiViewPathName: "/DSAMS-CISIL-RFIs",
      rfiViewTab: DSAMSTableUtils.Tabs.PREPARING_ACTIVITY_DATA,
      rows: [],
    },
    {
      rfiTableName: RFI_API_TableNames[RFIGroupTypes.DSAMS_CISIL].CISIL_CCOPES_LINE_DATA,
      apiType: Record_API_TableNames[RFIGroupTypes.DSAMS_CISIL].CISIL_CCOPES_LINE_DATA,
      fields: Object.values(DSAMSTableUtils.CcopesLineDataFields),
      rfiMappingName: "cisilCcopesLineData",
      title: "CISIL CCOPES LINE Data",
      rfiViewPathName: "/DSAMS-CISIL-RFIs",
      rfiViewTab: DSAMSTableUtils.Tabs.CISIL_CCOPES_LINE_DATA,
      rows: [],
    },
    {
      rfiTableName: RFI_API_TableNames[RFIGroupTypes.DSAMS_CISIL].CISIL_CASES_CERT_CLOSURE,
      apiType: Record_API_TableNames[RFIGroupTypes.DSAMS_CISIL].CISIL_CASES_CERT_CLOSURE,
      fields: Object.values(DSAMSTableUtils.CisilCasesCertClosureFields),
      rfiMappingName: "cisilCasesCertClosure",
      title: "CISIL CASES CERT Closures",
      rfiViewPathName: "/DSAMS-CISIL-RFIs",
      rfiViewTab: DSAMSTableUtils.Tabs.CISIL_CASES_CERT_CLOSURE,
      rows: [],
    },
    {
      rfiTableName: RFI_API_TableNames[RFIGroupTypes.DSAMS_CISIL].CISIL_CASES_CERT_CLOSURE_ALL,
      apiType: Record_API_TableNames[RFIGroupTypes.DSAMS_CISIL].CISIL_CASES_CERT_CLOSURE_ALL,
      fields: Object.values(DSAMSTableUtils.CisilCasesCertClosureFields),
      rfiMappingName: "cisilCasesCertClosureAll",
      title: "CISIL CASES CERT Closures W/O Priority One Date",
      rfiViewPathName: "/DSAMS-CISIL-RFIs",
      rfiViewTab: DSAMSTableUtils.Tabs.CISIL_CASES_CERT_CLOSURE_ALL,
      rows: [],
    },
    {
      rfiTableName: RFI_API_TableNames[RFIGroupTypes.DSAMS_CISIL].CISIL_REQUISITIONS,
      apiType: Record_API_TableNames[RFIGroupTypes.DSAMS_CISIL].CISIL_REQUISITIONS,
      fields: Object.values(DSAMSTableUtils.CisilRequisitionsFields),
      rfiMappingName: "cisilRequisition",
      title: "CISIL Requisitions",
      rfiViewPathName: "/DSAMS-CISIL-RFIs",
      rfiViewTab: DSAMSTableUtils.Tabs.CISIL_REQUISITIONS,
      rows: [],
    },
    {
      rfiTableName: RFI_API_TableNames[RFIGroupTypes.DSAMS_CISIL].CISIL_SDRs,
      apiType: Record_API_TableNames[RFIGroupTypes.DSAMS_CISIL].CISIL_SDRs,
      fields: Object.values(DSAMSTableUtils.CisilSDRsFields),
      rfiMappingName: "cisilSdr",
      title: "CISIL SDRs",
      rfiViewPathName: "/DSAMS-CISIL-RFIs",
      rfiViewTab: DSAMSTableUtils.Tabs.CISIL_SDRs,
      rows: [],
    },
    {
      rfiTableName: RFI_API_TableNames[RFIGroupTypes.DSAMS_CISIL].CISIL_DATA_DESCRIPTIONS,
      apiType: Record_API_TableNames[RFIGroupTypes.DSAMS_CISIL].CISIL_DATA_DESCRIPTIONS,
      fields: Object.values(DSAMSTableUtils.CisilDataDescriptionFields),
      rfiMappingName: "cisilDataDescription",
      title: "CISIL Data Descriptions",
      rfiViewPathName: "/DSAMS-CISIL-RFIs",
      rfiViewTab: DSAMSTableUtils.Tabs.CISIL_DATA_DESCRIPTIONS,
      rows: [],
    },
  ],
  [RFIGroupings.PEOPM_SATMO_USACE]: [
    {
      rfiTableName: RFI_API_TableNames[RFIGroupTypes.PEO_PM].PEO_PM,
      apiType: Record_API_TableNames[RFIGroupTypes.PEO_PM].PEO_PM,
      fields: Object.values(PEO_PM_Utils.PEO_PM_TableFields),
      rfiMappingName: "peoPm",
      title: "PEO PM",
      rfiViewPathName: "/PEO-PM-RFIs",
      rfiViewTab: null,
      rows: [],
    },
    {
      rfiTableName: RFI_API_TableNames[RFIGroupTypes.PEO_PM].PEO_PM,
      apiType: Record_API_TableNames[RFIGroupTypes.PEO_PM].PEO_PM,
      fields: Object.values(PEO_PM_Utils.PEO_PM_TableFields),
      rfiMappingName: "satmo",
      title: "SATMO",
      rfiViewPathName: "/PEO-PM-RFIs",
      rfiViewTab: "SATMO",
      rows: [],
    },
    {
      rfiTableName: RFI_API_TableNames[RFIGroupTypes.PEO_PM].PEO_PM,
      apiType: Record_API_TableNames[RFIGroupTypes.PEO_PM].PEO_PM,
      fields: Object.values(PEO_PM_Utils.PEO_PM_TableFields).slice(0, 4),
      rfiMappingName: "usace",
      title: "USACE",
      rfiViewPathName: "/PEO-PM-RFIs",
      rfiViewTab: "USACE",
      rows: [],
    },
  ],
  [RFIGroupings.SPECIAL_ORGS]: [
    {
      rfiTableName: RFI_API_TableNames[RFIGroupTypes.ACC].ACC,
      apiType: Record_API_TableNames[RFIGroupTypes.ACC].ACC,
      fields: Object.values(ACC_Utils.ACCDataFields),
      rfiMappingName: "accWorkload",
      title: "ACC",
      rfiViewPathName: "/ACC-RFIs",
      rfiViewTab: null,
      rows: [],
    },
    {
      rfiTableName: RFI_API_TableNames[RFIGroupTypes.SATFA].SATFA,
      apiType: Record_API_TableNames[RFIGroupTypes.SATFA].SATFA,
      fields: Object.values(SATFA_Utils.SATFADataFields),
      rfiMappingName: "satfaSpecialCase",
      title: "SATFA",
      rfiViewPathName: "/SATFA-RFIs",
      rfiViewTab: null,
      rows: [],
    },
  ],
  [RFIGroupings.GFEBS_CEFMS]: [
    {
      rfiTableName: RFI_API_TableNames[RFIGroupTypes.GFEBS_CEFMS].GFEBS,
      apiType: Record_API_TableNames[RFIGroupTypes.GFEBS_CEFMS].GFEBS,
      fields: Object.values(GFEBS_CEFMS_Table_Utils.GFEBSDataFields),
      rfiMappingName: "gfebsReport1",
      title: "GFEBS Report 1",
      rfiViewPathName: "/GFEBS-CEFMS-RFIs",
      rfiViewTab: GFEBS_CEFMS_Table_Utils.Tabs.GFEBS_DATA_REPORT1,
      rows: [],
    },
    {
      rfiTableName: RFI_API_TableNames[RFIGroupTypes.GFEBS_CEFMS].GFEBS,
      apiType: Record_API_TableNames[RFIGroupTypes.GFEBS_CEFMS].GFEBS,
      fields: Object.values(GFEBS_CEFMS_Table_Utils.GFEBSDataFields),
      rfiMappingName: "gfebsReport2",
      title: "GFEBS Report 2",
      rfiViewPathName: "/GFEBS-CEFMS-RFIs",
      rfiViewTab: GFEBS_CEFMS_Table_Utils.Tabs.GFEBS_DATA_REPORT2,
      rows: [],
    },
    {
      rfiTableName: RFI_API_TableNames[RFIGroupTypes.GFEBS_CEFMS].CEFMS,
      apiType: Record_API_TableNames[RFIGroupTypes.GFEBS_CEFMS].CEFMS,
      fields: Object.values(GFEBS_CEFMS_Table_Utils.CEFMSDataFields),
      rfiMappingName: "cefms",
      title: "CEFMS",
      rfiViewPathName: "/GFEBS-CEFMS-RFIs",
      rfiViewTab: GFEBS_CEFMS_Table_Utils.Tabs.CEFMS_DATA,
      rows: [],
    },
  ],
  [RFIGroupings.SUPPLEMENTAL_WORKFORCE]: [
    {
      rfiTableName: RFI_API_TableNames[RFIGroupTypes.SUPPLEMENTAL_WORKFORCE].SUPPLEMENTAL_WORKFORCE,
      apiType: Record_API_TableNames[RFIGroupTypes.SUPPLEMENTAL_WORKFORCE].SUPPLEMENTAL_WORKFORCE,
      fields: Object.values(Supplemental_Workforce_Utils.SupplementalWorkforceDataFields),
      rfiMappingName: "supplementalWorkforce",
      title: "HOURS DISTRIBUTION",
      rfiViewPathName: "/SupplementalWorkforce-RFIs",
      rfiViewTab: Supplemental_Workforce_Utils.Tabs.SUPPLEMENTAL_WORKFORCE_RFI,
      rows: [],
    },
    {
      rfiTableName: RFI_API_TableNames[RFIGroupTypes.SUPPLEMENTAL_WORKFORCE].BPC_REDUCTION,
      apiType: Record_API_TableNames[RFIGroupTypes.SUPPLEMENTAL_WORKFORCE].BPC_REDUCTION,
      fields: Object.values(Supplemental_Workforce_Utils.BPCColumnHeaders).slice(1),
      rfiMappingName: "bpcReduction",
      title: "BPC CIV FTE REDUCTION",
      rfiViewPathName: "/SupplementalWorkforce-RFIs",
      rfiViewTab: Supplemental_Workforce_Utils.Tabs.BPC,
      rows: [],
    },
    {
      rfiTableName: RFI_API_TableNames[RFIGroupTypes.SUPPLEMENTAL_WORKFORCE].SUPPLEMENTAL_WORKFORCE,
      apiType: Record_API_TableNames[RFIGroupTypes.SUPPLEMENTAL_WORKFORCE].SUPPLEMENTAL_WORKFORCE,
      fields: Object.values(Supplemental_Workforce_Utils.SupplementalWorkforceDataFields),
      rfiMappingName: "civilianPercentages",
      title: "CIVILIAN PERCENTAGES",
      rfiViewPathName: "/SupplementalWorkforce-RFIs",
      rfiViewTab: Supplemental_Workforce_Utils.Tabs.CIVILIAN_PERCENTAGES,
      rows: [],
    },
  ],
  [RFIGroupings.SAPBR_TDA]: [
    {
      rfiTableName: RFI_API_TableNames[RFIGroupTypes.SAPBR].SAPBR,
      apiType: Record_API_TableNames[RFIGroupTypes.SAPBR].SAPBR,
      fields: Object.values(SAPBR_Utils.SAPBRTableFields),
      rfiMappingName: "sapbr",
      title: "SAPBR DECISION DATA",
      rfiViewPathName: "/SAPBR",
      rfiViewTab: SAPBR_Utils.Tabs.SAPBR,
      rows: [],
    },
    {
      rfiTableName: RFI_API_TableNames[RFIGroupTypes.SAPBR].RMD,
      apiType: Record_API_TableNames[RFIGroupTypes.SAPBR].RMD,
      fields: Object.values(SAPBR_Utils.RMDTableFields),
      rfiMappingName: "rmd",
      title: "RESOURCE MANAGEMENT DECISION",
      rfiViewPathName: "/SAPBR",
      rfiViewTab: SAPBR_Utils.Tabs.RMD,
      rows: [],
    },
    {
      rfiTableName: RFI_API_TableNames[RFIGroupTypes.TDA].TDA,
      apiType: Record_API_TableNames[RFIGroupTypes.TDA].TDA,
      fields: Object.values(TDAUtils.TDATableFields),
      rfiMappingName: "tda",
      title: "TDA",
      rfiViewPathName: "/TDA-RFIs",
      rfiViewTab: null,
      rows: [],
    },
  ],
}

export const UserFields = {
  FIRST_NAME: "First Name",
  LAST_NAME: "Last Name",
  EMAIL: "Email",
  PHONE: "Phone",
  DISABLED: "Disabled",
  ADD_GLOBAL_ROLE: "Add Global Role",
  REMOVE_GLOBAL_ROLE: "Remove Global Role",
  ASSIGN_ORGANIZATION: "Assign Organization",
  ASSIGN_ORGANIZATION_ROLE: "Assign Organization Role",
}

// null means all organizations can access that view
export const RFIViewOrgMappings = {
  "/DSAMS-CISIL-RFIs": ["USASAC"],
  "/ACC-RFIs": ["ACC"],
  "/SATFA-RFIs": ["SATFA"],
  "/PEO-PM-RFIs": [
    "JPEO A&A",
    "JPEO CBRND",
    "PEO AVN",
    "PEO CS&CSS",
    "PEO EIS",
    "PEO GCS",
    "PEO IEW&S",
    "PEO M&S",
    "PEO MASPO",
    "PEO Soldier",
    "PEO STRI",
    "PM LAV",
  ],
  "/USACE-RFIs": ["USACE"],
  "/SATMO-RFIs": ["SATMO"],
  "/GFEBS-CEFMS-RFIs": null,
  "/SupplementalWorkforce-RFIs": null,
  "/CivilianPercentages": ["DASA DE&C", "PEO EIS", "PEO IEW&S", "PEO STRI"],
  "/SAPBR": ["DASA DE&C"],
  "/TDA-RFIs": ["DASA DE&C"],
  "/FiscalCycles": ["DASA DE&C"],
  "/ReviewRFIs": ["DASA DE&C"],
}

export const ActionType = {
  ADD: "Add",
  EDIT: "Edit",
}

export const ActiveState = {
  ACTIVE: "ACTIVE",
  INACTIVE: "INACTIVE",
}

export const SubmitStatus = {
  NOT_SUBMITTED: "NOT_SUBMITTED",
  SUBMITTED: "SUBMITTED",
  REQUEST_RESUBMISSION: "REQUEST_RESUBMISSION",
  APPROVED: "APPROVED",
  REJECTED: "REJECTED",
  NOT_GENERATED: "NOT_GENERATED",
  GENERATED: "GENERATED",
}

export const SubmitStatusIdx = {
  NOT_SUBMITTED: 0,
  SUBMITTED: 1,
  REQUEST_RESUBMISSION: 2,
  APPROVED: 3,
  REJECTED: 4,
  NOT_GENERATED: 5,
  GENERATED: 6,
}

export const SubmitStatusArr = [
  SubmitStatus.NOT_SUBMITTED,
  SubmitStatus.SUBMITTED,
  SubmitStatus.REQUEST_RESUBMISSION,
  SubmitStatus.APPROVED,
  SubmitStatus.REJECTED,
  SubmitStatus.NOT_GENERATED,
  SubmitStatus.GENERATED,
]

export const RFIFieldCheckStatus = {
  NOT_STARTED: "NOT STARTED",
  PENDING_START: "PENDING START",
  PASSED: "PASSED",
  FAILED: "FAILED",
}

export const UploadSubmitEnabledStatuses = [SubmitStatus.NOT_SUBMITTED, SubmitStatus.REJECTED, SubmitStatus.NOT_GENERATED, SubmitStatus.GENERATED]

export function returnErrorMessageFromAPIError(error) {
  const errStatus = error.response.status
  switch (errStatus) {
    case 504:
      return "The server is taking too long to respond. The data may have persisted successfully, you can check this by waiting a minute and refreshing your page."
    case 503:
      return "The server is currently unavailable. Please try again later."
    case 500:
      return "An error occurred on the server. Please try again later."
    case 404:
      return "The requested resource was not found."
    case 415:
      return "The file type is not supported."
    case 401:
      return "You are not authorized to access this resource."
    case 400:
      if (error?.response?.data?.Message && error?.response?.data?.Message.includes("Index was outside the bounds of the array")) {
        return "Some rows do not have the correct number of values. Please check your file and try again."
      }
      return null
    default:
      return null
  }
}

export function formatHeader(header) {
  let tmpHeader = header

  tmpHeader = tmpHeader.replace("Dt", "Date")
  tmpHeader = tmpHeader.replace("Cd", "Code")
  tmpHeader = tmpHeader.replace("Tx", "Text")

  if (!tmpHeader.includes("Element")) {
    tmpHeader = tmpHeader.replace("El", "Element")
  }

  tmpHeader = tmpHeader.replace("Ctr.", "Center")
  tmpHeader = tmpHeader.replace("Ctr", "Center")

  if (!tmpHeader.includes("Classification")) {
    tmpHeader = tmpHeader.replace("Classificat", "Classification")
  }

  tmpHeader = tmpHeader.replace("#", "Number")

  if (tmpHeader === "Case") {
    tmpHeader = "CaseDesignator"
  }

  return tmpHeader
}

export function validateAlphaNumericCode(value, length) {
  if (value.length !== length) return false
  // [L|#] * length
  let patternStr = `[A-Z0-9]{${length}}`
  let pattern = RegExp(patternStr)
  return pattern.test(String(value))
}

export function validateOneOrMoreAlphaChars(value) {
  let patternStr = `[A-Z0-9]?`
  let pattern = RegExp(patternStr)
  return pattern.test(String(value))
}

export function validateNumeric(value) {
  const LENGTH = value.length
  let extractedNum = Number(value)
  if (value[0] === "(" && value[LENGTH - 1] === ")") {
    // Convert (#) to a negative # if necessary
    extractedNum = -Number(value.slice(1, LENGTH - 2))
  }
  return Number.isInteger(Math.floor(extractedNum))
}

export function validateDecimalPlaces(value, decimalPlaces) {
  if (!value.toString().includes(".")) {
    return true
  }
  return value.toString().split(".")[1].length <= decimalPlaces
}

export function setWarningErrorMessagesInTable(warningsErrorsTableRows, setWarningsErrorsTableRows, csvUploadWarningsErrors) {
  if (csvUploadWarningsErrors.length <= 0) {
    setWarningsErrorsTableRows([])
    return
  }
  if (warningsErrorsTableRows.length <= 0) return
  let tmpWarningErrorsTableRows = [...warningsErrorsTableRows]
  csvUploadWarningsErrors.forEach((csvWarningError) => {
    if (tmpWarningErrorsTableRows[csvWarningError.rowNum]) {
      tmpWarningErrorsTableRows[csvWarningError.rowNum] = {
        ...tmpWarningErrorsTableRows[csvWarningError.rowNum],
        Warnings_Errors: csvWarningError.warningsErrors,
      }
    }
  })
  setWarningsErrorsTableRows(tmpWarningErrorsTableRows)
}

export async function fetchPrevCsvFileData(restoreFolder, restoreFileName, fiscalYear) {
  return fetch(`${window.location.origin}/CSVs/${fiscalYear}/${restoreFolder}/${restoreFileName}`).then(async (res) => {
    let result = res.blob().then((blob) => blob)
    return result
  })
}

export function getQueryParamsOnTableUpdateFromDB(MUIFilter, MUISortModel, pageSize, skip, rfiIdFieldName, selectedRFI, numericFields) {
  const queryParams = {
    [QueryParamTypes.TOP]: pageSize.current,
    [QueryParamTypes.SKIP]: skip,
    [QueryParamTypes.FILTER]: `${rfiIdFieldName} eq ${selectedRFI.id}`,
  }
  if (MUISortModel[0]?.field) {
    queryParams[QueryParamTypes.ORDER_BY] = `${
      MUISortModel[0]?.dataField ? MUISortModel[0]?.dataField : MUISortModel[0]?.field
    } ${MUISortModel[0].sort.toUpperCase()}`
  }
  const filterValue = numericFields.includes(MUIFilter.tableField) ? MUIFilter.value : `'${MUIFilter.value}'`
  if (MUIFilter?.value) {
    switch (MUIFilter.operator) {
      case "contains":
        queryParams[QueryParamTypes.FILTER] += ` and contains(${MUIFilter.dataField}, ${filterValue})`
        break
      case "equals":
        queryParams[QueryParamTypes.FILTER] += ` and ${MUIFilter.dataField} eq ${filterValue}`
        break
      default:
        break
    }
  }
  return queryParams
}

export function getWarningsErrorsTableRowsFromUploadedTableRows(uploadedTableRows, errors, warnings, headerRowNumber) {
  let tmpWarningsErrorsTableRows = []
  let tmpWarningsErrorsTableRowsWarningsErrorsOnly = []
  const headerRowNumberToPass = headerRowNumber ? headerRowNumber : 2
  uploadedTableRows.forEach((row, idx) => {
    let warningsErrorsRowObj = {
      ...row,
      Warnings_Errors: {
        warnings: [],
        errors: [],
      },
    }
    if (errors && errors.length > 0) {
      const rowErrors = errors.filter((error) => error.rowNumber - headerRowNumberToPass === idx)
      if (rowErrors.length > 0) {
        warningsErrorsRowObj.Warnings_Errors = {
          warnings: warningsErrorsRowObj.Warnings_Errors.warnings,
          errors: rowErrors,
        }
      }
    }
    if (warnings && warnings.length > 0) {
      const rowWarnings = warnings.filter((warning) => warning.rowNumber - headerRowNumberToPass === idx)
      if (rowWarnings.length > 0) {
        warningsErrorsRowObj.Warnings_Errors = {
          warnings: rowWarnings,
          errors: warningsErrorsRowObj.Warnings_Errors.errors,
        }
      }
    }
    // If no warnings or errors, add the origional row to the warnings and errors table obj and return
    if (warningsErrorsRowObj.Warnings_Errors.errors.length <= 0 && warningsErrorsRowObj.Warnings_Errors.warnings.length <= 0) {
      tmpWarningsErrorsTableRows.push(row)
    } else {
      // If there are errors, add the row to the errors only table obj
      if (warningsErrorsRowObj.Warnings_Errors.errors.length > 0 || warningsErrorsRowObj.Warnings_Errors.warnings.length > 0) {
        tmpWarningsErrorsTableRowsWarningsErrorsOnly.push(warningsErrorsRowObj)
      }
      // Add the row to the warnings and errors table obj if there are any errors or warnings
      tmpWarningsErrorsTableRows.push(warningsErrorsRowObj)
    }
  })
  return { tmpWarningsErrorsTableRows, tmpWarningsErrorsTableRowsWarningsErrorsOnly }
}

export function updateMUITableWithWarningsErrorsRows(
  MUIFilter,
  MUISortModel,
  warningsErrorsTableRowsForDisplay,
  origionalWarningsErrorsTableRows,
  numTotalTableRows,
  setSelectedTableRows,
  selectedTab,
  skip,
  pageSize,
  numericFields,
  setShowSnackbarError,
  setSnackbarMessage
) {
  // SORT
  let sortedTableRows = [...warningsErrorsTableRowsForDisplay]
  if (!sortedTableRows[0]) return
  // if MUISortModel[0]?.field is not null we are rendering sorted rows, if null we render the orignial rows
  if (MUISortModel[0]?.field) {
    if (sortedTableRows[0][MUISortModel[0].field]) {
      if (numericFields.includes(MUISortModel[0].field)) {
        sortedTableRows = sortedTableRows.sort((a, b) =>
          MUISortModel[0].sort === "desc"
            ? Number(a[MUISortModel[0].field]) > Number(b[MUISortModel[0].field])
              ? -1
              : 1
            : Number(a[MUISortModel[0].field]) > Number(b[MUISortModel[0].field])
            ? 1
            : -1
        )
      } else {
        sortedTableRows = sortedTableRows.sort((a, b) =>
          MUISortModel[0].sort === "desc"
            ? b[MUISortModel[0].field].localeCompare(a[MUISortModel[0].field])
            : a[MUISortModel[0].field].localeCompare(b[MUISortModel[0].field])
        )
      }
    }
  } else {
    if (!MUISortModel[0]) {
      origionalWarningsErrorsTableRows.current = [...warningsErrorsTableRowsForDisplay]
    }
  }
  // FILTER
  if (warningsErrorsTableRowsForDisplay.length > 0) {
    let refTableRows = MUISortModel[0]?.field ? sortedTableRows : origionalWarningsErrorsTableRows.current
    switch (MUIFilter.operator) {
      case "contains":
        if (!numericFields.includes(MUIFilter.tableField)) {
          refTableRows = refTableRows.filter((row) => row[MUIFilter.tableField].toLowerCase().includes(MUIFilter.value.toLowerCase()))
        } else {
          setShowSnackbarError(true)
          setSnackbarMessage(
            `Failed to filter field ${MUIFilter?.field ? MUIFilter.tableField : ""}, this field cannot be filtered with operator: ${
              MUIFilter?.operator ? MUIFilter.operator : ""
            }.`
          )
        }
        break
      case "equals":
        if (MUIFilter?.value && MUIFilter.value.length > 0) {
          refTableRows = refTableRows.filter((row) => row[MUIFilter.tableField] === MUIFilter.value)
        }
        break
      default:
        break
    }
    if (selectedTab != null) {
      numTotalTableRows.current = { ...numTotalTableRows.current, [selectedTab]: refTableRows.length }
    } else {
      numTotalTableRows.current = refTableRows.length
    }
    const pageTableRows = refTableRows.slice(skip, Math.min(skip + pageSize.current, warningsErrorsTableRowsForDisplay.length))
    setSelectedTableRows(pageTableRows)
  } else {
    setSelectedTableRows([])
  }
}

export function validateDataTypes(data, nonNullableFields, dateFields, numericFields, setLoadingProgress, selectedFiscalCycle) {
  let tmpCsvUploadWarningsErrors = []
  // data could have millions of rows
  setLoadingProgress(0)
  const numRowPerProgressUpdate = data.length / 20
  data.map((row, idx) => {
    if (idx % numRowPerProgressUpdate === 0) {
      setTimeout(() => {
        setLoadingProgress(Math.min(Math.round((idx / data.length) * 100), 100))
      }, [500])
    }
    let errors = []
    let warnings = []
    Object.keys(row)
      .filter((key) => key !== "__parsed_extra")
      .forEach((key) => {
        if (nonNullableFields.includes(key)) {
          if (row[key] == null || row[key] === "" || row[key] === "#") {
            if (
              key === SAPBR_Utils.SAPBRTableFields.APPROVED_WORK_YEAR_ONE ||
              key === SAPBR_Utils.SAPBRTableFields.APPROVED_WORK_YEAR_TWO ||
              key === SAPBR_Utils.SAPBRTableFields.APPROVED_WORK_YEAR_THREE ||
              key === SAPBR_Utils.SAPBRTableFields.APPROVED_WORK_YEAR_FOUR
            ) {
              let tmpKey = SAPBR_Utils.correctFYInHeader(key, selectedFiscalCycle)
              errors.push(`${tmpKey} cannot be null`)
            } else {
              errors.push(`${key} cannot be null`)
            }
          }
        }
        if (dateFields.includes(key)) {
          if (isNaN(Date.parse(row[key])) || typeof row[key] === "number") {
            errors.push(`${key} must be a valid date`)
          }
        }
        if (numericFields.includes(key)) {
          if (!Number.isInteger(Math.floor(Number(row[key])))) {
            errors.push(`${key} must be a number`)
          }
        }
        // Check regular expressions
        if (!(row[key] == null || row[key] === "" || row[key] === "#")) {
          switch (key) {
            // DSAMS/CISIL Checks
            case DSAMSTableUtils.MilestonesFields.USER_CASE_ID:
              if (!validateUserCaseId(row[key])) {
                errors.push(`${key} must be in the following format #L-L-L###`)
              }
              break
            case DSAMSTableUtils.ActivityDataFields.GENERIC_CODE:
              if (!validateGenericCode(row[key])) {
                errors.push(`${key} must be in the following format L#L`)
              }
              break
            case DSAMSTableUtils.CisilSDRsFields.SDR_NUMBER:
              if (!validateSDRNumber(row[key])) {
                errors.push(`${key} must be in the following format L####`)
              }
              break
            case DSAMSTableUtils.CcopesLineDataFields.IMPLEMENTING_AGENCY:
            case DSAMSTableUtils.CcopesLineDataFields.ULO_INDICATOR:
            case DSAMSTableUtils.CisilDataDescriptionFields.MDE_CODE:
              if (!validateAlphaNumericCode(row[key], 1)) {
                errors.push(`${key} must be one alphanumeric character`)
              }
              break
            case DSAMSTableUtils.CcopesLineDataFields.COUNTRY_CODE:
            case DSAMSTableUtils.CcopesLineDataFields.INHIBITOR_CODE:
              if (!validateAlphaNumericCode(row[key], 2)) {
                errors.push(`${key} must be two alphanumeric characters`)
              }
              break
            case DSAMSTableUtils.CcopesLineDataFields.CASE_DESIGNATOR:
            case DSAMSTableUtils.CcopesLineDataFields.RIC:
              if (!validateAlphaNumericCode(row[key], 3)) {
                errors.push(`${key} must be three alphanumeric characters`)
              }
              break
            // GFEBS Checks
            case GFEBS_CEFMS_Table_Utils.GFEBSDataFields.PARTNER_FUND:
              if (!validateAlphaNumericCode(row[key], 10)) {
                errors.push(`${key} must be 10 alphanumeric characters long`)
              }
              break
            case GFEBS_CEFMS_Table_Utils.GFEBSDataFields.PARTNER_FUNCTIONAL_AREA:
              if (!validateAlphaNumericCode(row[key], 7)) {
                errors.push(`${key} must be 7 alphanumeric characters long`)
              }
              break
            case GFEBS_CEFMS_Table_Utils.GFEBSDataFields.PARTNER_FUNDS_CENTER:
              if (!validateAlphaNumericCode(row[key], 5)) {
                errors.push(`${key} must be 5 alphanumeric characters long`)
              }
              break
            case GFEBS_CEFMS_Table_Utils.GFEBSDataFields.GRC_CODE:
              if (!validateAlphaNumericCode(row[key], 2)) {
                errors.push(`${key} must be 2 alphanumeric characters long`)
              }
              break
            // CEFMS Checks
            case GFEBS_CEFMS_Table_Utils.CEFMSDataFields.AMSCO_CODE:
              if (!validateAlphaNumericCode(row[key], 11)) {
                errors.push(`${key} must be 11 alphanumeric characters long`)
              }
              break
            case GFEBS_CEFMS_Table_Utils.CEFMSDataFields.EMPLOYEE_IDENTIFIER:
              if (row[key][0] !== "E") {
                errors.push(`${key} must start with an uppercase 'E'`)
              }
              if (!validateAlphaNumericCode(row[key], 9)) {
                errors.push(`${key} must be 9 alphanumeric characters long`)
              }
              break
            case GFEBS_CEFMS_Table_Utils.CEFMSDataFields.HOURS_TYPE_CODE:
              if (!validateAlphaNumericCode(row[key], 2)) {
                errors.push(`${key} must be 2 alphanumeric characters long`)
              }
              break
            case GFEBS_CEFMS_Table_Utils.CEFMSDataFields.LABOR_HOURS:
              if (!validateRecordedHours(Number(row[key]))) {
                errors.push(`${key} must be between 0 and 80`)
              }
              break
            // TDA Checks
            case TDAUtils.TDATableFields.FY:
              if (!validateAlphaNumericCode(row[key], 2) && window.location.pathname.split("/")[1] === "TDA-RFIs") {
                errors.push(`${key} must be 2 alphanumeric characters long`)
              }
              break
            case TDAUtils.TDATableFields.CMD:
              if (!validateAlphaNumericCode(row[key], 2)) {
                errors.push(`${key} must be 2 alphanumeric characters long`)
              }
              break
            case TDAUtils.TDATableFields.DOCNO:
              if (!validateAlphaNumericCode(row[key], 8)) {
                errors.push(`${key} must be 8 alphanumeric characters long`)
              }
              break
            case TDAUtils.TDATableFields.UIC:
              if (!validateAlphaNumericCode(row[key], 6)) {
                errors.push(`${key} must be 6 alphanumeric characters long`)
              }
              break
            case TDAUtils.TDATableFields.CCNUM:
              if (!validateNumeric(row[key]) || row[key].length !== 4) {
                errors.push(`${key} must be 4 digits long`)
              }
              break
            case TDAUtils.TDATableFields.LN:
              if (!validateNumeric(row[key]) || row[key].length !== 2) {
                errors.push(`${key} must be 2 digits long`)
              }
              break
            case TDAUtils.TDATableFields.UICDR:
              if (!validateAlphaNumericCode(row[key], 6) && row[key].length > 0) {
                errors.push(`${key} must be 6 alphanumeric characters long or empty`)
              }
              break
            case TDAUtils.TDATableFields.GRADE:
              if (!validateNumeric(row[key]) || row[key].length !== 2) {
                errors.push(`${key} must be 2 digits long`)
              }
              break
            case TDAUtils.TDATableFields.POSCO:
              if (validateNumeric(row[key]) && row[key].length !== 5) {
                errors.push(`${key} must be 5 digits long`)
              }
              break
            case TDAUtils.TDATableFields.PRMK1:
              if (!validateAlphaNumericCode(row[key], 2) && row[key].length > 0) {
                errors.push(`${key} must be 2 alphanumeric characters long or empty`)
              }
              break
            case TDAUtils.TDATableFields.PRMK2:
              if (!validateAlphaNumericCode(row[key], 2) && row[key].length > 0) {
                errors.push(`${key} must be 2 alphanumeric characters long or empty`)
              }
              break
            case TDAUtils.TDATableFields.PRMK3:
              if (!validateAlphaNumericCode(row[key], 2) && row[key].length > 0) {
                errors.push(`${key} must be 2 alphanumeric characters long or empty`)
              }
              break
            case TDAUtils.TDATableFields.AMSCO:
              if (!validateNumeric(row[key]) || row[key].length !== 8) {
                errors.push(`${key} must be 8 digits long`)
              }
              break
            case TDAUtils.TDATableFields.PPSST:
              if (!validateAlphaNumericCode(row[key], 1)) {
                errors.push(`${key} must be 1 alphanumeric character long`)
              }
              break
            case TDAUtils.TDATableFields.PSIRQ:
              if (!validateAlphaNumericCode(row[key], 1)) {
                errors.push(`${key} must be 1 alphanumeric character long`)
              }
              break
            case TDAUtils.TDATableFields.PPSRQ:
              if (!validateAlphaNumericCode(row[key], 1)) {
                errors.push(`${key} must be 1 alphanumeric character long`)
              }
              break
            case TDAUtils.TDATableFields.REQSTR:
              if (!validateAlphaNumericCode(row[key], 1)) {
                errors.push(`${key} must be 1 alphanumeric character long`)
              }
              break
            case TDAUtils.TDATableFields.AUTHSTR:
              if (!validateAlphaNumericCode(row[key], 1)) {
                errors.push(`${key} must be 1 alphanumeric character long`)
              }
              break
            // SAPBR Checks
            case SAPBR_Utils.SAPBRTableFields.APPROVED_WORK_YEAR_ONE:
            case SAPBR_Utils.SAPBRTableFields.APPROVED_WORK_YEAR_TWO:
            case SAPBR_Utils.SAPBRTableFields.APPROVED_WORK_YEAR_THREE:
            case SAPBR_Utils.SAPBRTableFields.APPROVED_WORK_YEAR_FOUR:
              if (!validateDecimalPlaces(row[key].trim(), 2)) {
                errors.push(`${key} can have up to 2 decimal places`)
              }
              break
            // USACE Checks
            case USACEUtils.HCaseExceptionFields.CASE_ID:
              if (!validateAlphaNumericCode(row[key], 6)) {
                errors.push(`${key} must be 6 alphanumeric characters`)
              }
              break
            default:
              break
          }
        }
      })
    if (errors.length > 0 || warnings.length > 0) {
      tmpCsvUploadWarningsErrors.push({
        rowNum: idx,
        warningsErrors: {
          warnings,
          errors,
        },
      })
    }
  })
  return tmpCsvUploadWarningsErrors
}

export const orgHierarchy = [
  {
    tier2: "AMC",
    organizations: [
      {
        organizationName: "ACC",
        id: "297a095b-b46a-4f21-b83e-74aa87d04de5",
      },
      {
        organizationName: "AMCOM",
        id: "abf6621a-8be6-456b-8c36-22eccccebce0",
      },
      {
        organizationName: "CECOM",
        id: "87603d41-8072-4d30-905e-238b0a2dfe98",
      },
      {
        organizationName: "JMC",
        id: "bf61b2c3-2979-46eb-8259-98160092dc94",
      },
      {
        organizationName: "SATMO",
        id: "b719f592-14a9-44a3-a5df-4633b4a85d25",
      },
      {
        organizationName: "TACOM",
        id: "bb2cb128-e8b0-4faa-8429-92a7fc0e942d",
      },
      {
        organizationName: "USAMMA",
        id: "94bc9af4-b2fd-4ecd-9722-0107c8d2251f",
      },
      {
        organizationName: "USASAC",
        id: "e3b06615-5fcc-41d1-ab53-f0107a8a5213",
      },
    ],
  },
  {
    tier2: "ASA-ALT",
    organizations: [
      {
        organizationName: "DASA DE&C",
        id: "092c4034-7f00-4451-bf1e-2902ec5729ef",
      },
    ],
  },
  {
    tier2: "PEOPM",
    organizations: [
      {
        organizationName: "JPEO A&A",
        id: "9a817529-534c-4766-a7c7-146d36e750f7",
      },
      {
        organizationName: "JPEO CBRND",
        id: "974156ab-b77a-446b-ad48-8b792f822a47",
      },
      {
        organizationName: "PEO AVN",
        id: "6ef8833c-804c-4260-b124-f9ebe36cc7bf",
      },
      {
        organizationName: "PEO MASPO",
        id: "ab066502-a2e7-4e1a-8b03-b51a680e5292",
      },
      {
        organizationName: "PEO CS&CSS",
        id: "f69e86ce-da31-450e-9f5d-1853c4e28691",
      },
      {
        organizationName: "PEO GCS",
        id: "e1db825a-a512-45da-acdb-306cfe7431e4",
      },
      {
        organizationName: "PEO IEW&S",
        id: "a6483974-a8b9-4dba-8f53-dfd67e5b8db4",
      },
      {
        organizationName: "PEO M&S",
        id: "3995c765-6848-46c6-a9ef-09360df78bc5",
      },
      {
        organizationName: "PEO Soldier",
        id: "1c8ae677-140c-4131-ada4-174721fba8e7",
      },
      {
        organizationName: "PEO STRI",
        id: "7d999610-2784-40d5-8498-ad192e4d9e20",
      },
    ],
  },
  {
    tier2: "TRADOC SATFA",
    organizations: [
      {
        organizationName: "SATFA",
        id: "96f261d5-c3b1-4241-a430-59ba466b959f",
      },
    ],
  },
  {
    tier2: "USACE",
    organizations: [
      {
        organizationName: "USACE",
        id: "ea9987c6-0300-4e37-9fed-8b74550de0f0",
      },
    ],
  },
]
