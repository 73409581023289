import { useState, useContext, useEffect } from "react"

// Data
import { QueryParamTypes, apiCalls } from "../components/DataService"

// Libraries
import { useHistory } from "react-router-dom/cjs/react-router-dom.min"

// Custom
import { logosArr } from "../components/Organizations/OrganizationPOCs"
import { ApplicationPaths } from "../components/api-authorization/ApiAuthorizationConstants"
import MessageBoard from "../components/MessageBoard/MessageBoard"

import AMC from "../CWATImages/FMS_Organization_Icons/AMC.png"
import PEOPM from "../CWATImages/FMS_Organization_Icons/PEOPM.png"
import TRADOC from "../CWATImages/FMS_Organization_Icons/TRADOC.png"
import USACE1 from "../CWATImages/FMS_Organization_Icons/USACE1.png"

import ACC from "../CWATImages/FMS_Organization_Icons/ACC.png"
import AMCOM from "../CWATImages/FMS_Organization_Icons/AMCOM.png"
import CECOM from "../CWATImages/FMS_Organization_Icons/CECOM.png"
import DASA_DEC from "../CWATImages/FMS_Organization_Icons/DASA DE&C.png"
import JMC from "../CWATImages/FMS_Organization_Icons/JMC.png"
import JPEO_A_A from "../CWATImages/FMS_Organization_Icons/JPEO A&A.png"
import JPEO_CBRND from "../CWATImages/FMS_Organization_Icons/JPEO CBRND.png"
import MASPO from "../CWATImages/FMS_Organization_Icons/MASPO.png"
import PEO_AVN from "../CWATImages/FMS_Organization_Icons/PEO AVN.png"
import PEO_CS_CSS from "../CWATImages/FMS_Organization_Icons/PEO CS&CSS.png"
import PEO_EIS from "../CWATImages/FMS_Organization_Icons/PEO EIS.png"
import PEO_GCS from "../CWATImages/FMS_Organization_Icons/PEO GCS.png"
import PEO_IEW_S from "../CWATImages/FMS_Organization_Icons/PEO IEW&S.png"
import PEO_M_S from "../CWATImages/FMS_Organization_Icons/PEO M&S.png"
import PEO_SOLDIER from "../CWATImages/FMS_Organization_Icons/PEO Soldier.png"
import PEO_STRI from "../CWATImages/FMS_Organization_Icons/PEO STRI.png"
import PM_LAV from "../CWATImages/FMS_Organization_Icons/PM LAV.png"
import SATFA from "../CWATImages/FMS_Organization_Icons/SATFA.png"
import SATMO from "../CWATImages/FMS_Organization_Icons/SATMO.png"
import TACOM from "../CWATImages/FMS_Organization_Icons/TACOM.png"
import USACE from "../CWATImages/FMS_Organization_Icons/USACE.png"
import USAMMA from "../CWATImages/FMS_Organization_Icons/USAMMA.png"
import USASAC from "../CWATImages/FMS_Organization_Icons/USASAC.png"
import armyPhoto from "../CWATImages/FMS_Organization_Icons/armyphoto.jpg"

import PEOPMlogo from "../CWATImages/FMS_Organization_Icons/PEOPMlogo.png"
import USACElogo from "../CWATImages/FMS_Organization_Icons/USACElogo.png"
import AMClogo from "../CWATImages/FMS_Organization_Icons/AMClogo.png"
import TRADOClogo from "../CWATImages/FMS_Organization_Icons/TRADOClogo.png"

import DEClogo from "../CWATImages/DEClogo.png"
import DASADEClogo from "../CWATImages/DASADEClogo.png"
import ARMYLogo from "../CWATImages/Armylogo(2).png"

// Styles
import { makeStyles } from "@mui/styles"
import "./NewLandingPage.css"

const useStyles = makeStyles({
  root: {
    // backgroundColor: "#333",
    backgroundImage: `url(${armyPhoto})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundAttachment: "fixed",
    height: "100vh",
    width: "100%",
    color: "white",
    height: "calc(100vh - 20px)",
    maxHeight: "100vh",
    padding: "1rem",
    overflow: "auto",
  },
  header: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: "2rem",
    backgroundColor: "rgba(255, 255, 255, 0.5)",
    backdropFilter: "blur(4px)",
    WebkitBackdropFilter: "blur(4px)",
    padding: "20px",
    borderRadius: "8px",
    // width: '65%',
    // margin: '0 auto',
  },
  title: {
    color: "white",
    textAlign: "center",
  },
  selectFunction: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "2rem",
  },
  selectFunctionTitle: {
    marginBottom: "1rem",
    color: "#1189bd",
    textAlign: "center",
  },
  userRoleTitle: {
    marginBottom: "0.75rem",
    color: "orange",
    textAlign: "center",
  },
  orgButtons: {
    color: "black",
    backgroundColor: "#aaa",
    padding: "0.5rem 1rem",
    border: "none",
    borderRadius: "5px",
    margin: "0.25rem 0",
    "&:hover": {
      backgroundColor: "white",
    },
  },
  timeline: {
    marginTop: "4rem",
  },
  blurCircle: {
    backgroundColor: "#333",
    borderRadius: "50%",
    position: "relative",
    left: "17px",
  },
  orgLogos: {
    marginTop: "5rem",
  },
  cuiMarking: {
    width: "100%",
    position: "fixed",
    bottom: 25,
  },
})

const NewLandingPage = () => {
  const classes = useStyles()
  const history = useHistory()
  const [DASA_DECLogo, setDASA_DECLogo] = useState("")
  // temporary state since we're moving away from globalContext...not sure if this needs to be reworked to work with appContext yet.
  const [selectedOrg, setSelectedOrg] = useState({})

  // temporary logic to split up allOrgs arr into the 4 groups (AMC, PEOPM, TRADOC, USACE) WILL BE REPLACED BY A CALL TO GET ALL ORGS AND PARENT UIC -->

  const PEOPMLogosArr = [
    { name: "JPEO A&A", link: JPEO_A_A }, // on list
    { name: "JPEO CBRND", link: JPEO_CBRND }, // on list
    { name: "PEO MASPO", link: MASPO },
    { name: "PEO AVN", link: PEO_AVN }, // on list
    { name: "PEO CS&CSS", link: PEO_CS_CSS }, // on list
    { name: "PEO EIS", link: PEO_EIS },
    { name: "PEO GCS", link: PEO_GCS }, // on list
    { name: "PEO IEW&S", link: PEO_IEW_S }, // on list
    { name: "PEO M&S", link: PEO_M_S }, // on list
    { name: "PEO Soldier", link: PEO_SOLDIER }, // on list
    { name: "PEO STRI", link: PEO_STRI }, // on list
    { name: "PM LAV", link: PM_LAV }, // on list
  ]

  const USACELogosArr = [
    // empty as far as I know
  ]

  const AMCLogosArr = [
    { name: "ACC", link: ACC }, // on list
    { name: "AMCOM", link: AMCOM }, // on list
    { name: "CECOM", link: CECOM }, // on list
    { name: "JMC", link: JMC }, // on list
    { name: "SATMO", link: SATMO }, // on list
    { name: "TACOM", link: TACOM }, // on list
    { name: "USAMMA", link: USAMMA }, // on list
    { name: "USASAC", link: USASAC }, // on list
  ]

  const TRADOCLogosArr = [
    { name: "SATFA", link: SATFA }, // on list
  ]

  const ARMYLogosArr = [{ name: "DASADEC", link: DEClogo }]

  const topOrgs = [
    { name: "ARMY", imageUrl: ARMYLogo, ARMYLogosArr: ARMYLogosArr },
    { name: "AMC", imageUrl: AMClogo, AMCLogosArr: AMCLogosArr },
    { name: "TRADOC", imageUrl: TRADOClogo, TRADOCLogosArr: TRADOCLogosArr },
    { name: "USACE", imageUrl: USACElogo, USACELogosArr: USACELogosArr },
    { name: "PEOPM", imageUrl: PEOPMlogo, PEOPMLogosArr: PEOPMLogosArr },
  ]

  const messages = [
    {
      id: 1,
      timestamp: "14:32",
      content: "CWAT is now open to receiving RFI responses.",
    },
    {
      id: 2,
      timestamp: "15:00",
      content: "Pellentesque habitant morbi tristique senectus et netus.",
    },
    {
      id: 3,
      timestamp: "15:45",
      content: "Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    },
    {
      id: 4,
      timestamp: "16:20",
      content: "Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris.",
    },
    {
      id: 5,
      timestamp: "17:10",
      content: "Duis aute irure dolor in reprehenderit in voluptate velit esse cillum.",
    },
    {
      id: 6,
      timestamp: "18:05",
      content: "Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia.",
    },
  ]

  // temporary logic to split up allOrgs arr into the 4 groups (AMC, PEOPM, TRADOC, USACE) WILL BE REPLACED BY A CALL TO GET ALL ORGS AND PARENT UIC <--

  // ** --------------***     [ ~ EFFECTS ~ ]     ***-------------- **
  useEffect(() => {
    // console.log("TRIGGERED: 6")
  })

  useEffect(() => {
    // loadLandingPageData()
  }, [])

  // ** --------------***     [ ~ FUNCTIONS ~ ]     ***-------------- **
  const loadLandingPageData = async () => {
    // let accountURL = getURL("Accounts", "me")
    // let accountFetch = fetchData(url)

    // TODO: convert to Promise.all and async/awaits

    apiCalls.getAll(`Accounts/me`, {}).then((loggedInUser) => {
      // setIsLoggedInContext(!!loggedInUser?.sub)
      if (!loggedInUser?.sub) {
        window.location.replace(ApplicationPaths.IdentityLoginPath)
      }
    })

    // apiCalls.getAll(`Organizations`, { [QueryParamTypes.ORDER_BY]: "name" }).then((tempOrgList) => {
    //   // Set DASA DE&C logo
    //   setDASA_DECLogo(logosArr.find((logo) => logo.name === "DASA DE&C"))
    //   // Add org logo relative path to data object
    //   if (!selectedOrg?.id) {
    //     tempOrgList = tempOrgList.map((org) => ({ ...org, logo: logosArr.find((logo) => logo.name === org.name) }))
    //     // setAllOrgsContext(tempOrgList)
    //     const DASA_DEC_ORG = tempOrgList.find((org) => org.name === "DASA DE&C")
    //     apiCalls.getById(`Organizations`, DASA_DEC_ORG.id).then((selectedOrg) => {
    //       setSelectedOrg({ ...selectedOrg, logo: logosArr.find((logo) => logo.name === selectedOrg.name) })
    //     })
    //   }
    // })
  }

  const setTargetOrganization = (targetOrg) => {
    apiCalls.getAll(`Organizations`, {}).then((orgs) => {
      const targetOrgAPI = orgs.find((org) => org.name === targetOrg)
      apiCalls.getById(`Organizations`, targetOrgAPI.id).then((org) => {
        sessionStorage.setItem("ORG", JSON.stringify(org))
        setSelectedOrg({ ...org, logo: logosArr.find((logo) => logo.name === targetOrgAPI.name) })
      })
    })
  }

  const handleTopOrgClick = (orgName) => {
    setTargetOrganization(orgName)
    history.push("/dashboard")
  }

  const handleSubOrgClick = (subOrgName) => {
    setTargetOrganization(subOrgName)
    history.push("/dashboard")
  }

  // ** --------------***     [ ~ RENDER ~ ]     ***-------------- **
  return (
    <div className={`${classes.root} 'landing-main'}`}>
      <div className={classes.header}>
        <div style={{ paddingRight: "126px" }}>
          <img
            src={DEClogo}
            alt="DEClogo"
            width="100px"
            height="100px"
            className="org-icon"
          />
        </div>
        <h1 className={classes.title}>Welcome to the Common Workforce Analysis Tool (CWAT)</h1>
        <img
          src={DASADEClogo}
          alt="DEClogo"
          width="226px"
          height="100px"
          className="org-icon"
        />
      </div>

      {/* my new code */}
      <div
        className="logo-nav-container"
        style={{ textAlign: "center" }}
      >
        <div
          className="logo-container"
          style={{ display: "inline-block" }}
        >
          {topOrgs.map((org, index) => {
            const subOrgs = org[`${org.name}LogosArr`]
            const hasTwoColumns = subOrgs.length > 6
            return (
              <div
                className="logo-item-new"
                key={index}
                onClick={() => handleTopOrgClick(org.name)}
              >
                <img
                  src={org.imageUrl}
                  alt={org.name}
                  className="logo-image"
                />
                <h1 className="org-text">{org.name}</h1>
                {subOrgs.length > 0 && (
                  <div className={`hover-box ${hasTwoColumns ? "two-columns" : ""}`}>
                    {subOrgs.map((subLogo, subIndex) => (
                      <img
                        key={subIndex}
                        src={subLogo.link}
                        alt={subLogo.name}
                        onClick={(e) => {
                          e.stopPropagation()
                          handleSubOrgClick(subLogo.name)
                        }}
                      />
                    ))}
                  </div>
                )}
              </div>
            )
          })}
        </div>
      </div>
      {/* new code for message board */}
      <div className="message-board-container">
        <MessageBoard messages={messages} />
      </div>
      {/* my new code */}
    </div>
  )
}

export default NewLandingPage
