import instrument from './instrument';
import React from 'react';
import ReactDOM from 'react-dom'
import App from './App';
import { AuthProvider } from "react-oidc-context"
import { WebStorageStateStore } from "oidc-client-ts"

const SERVER_URL = process.env.REACT_APP_SERVER_URL ? process.env.REACT_APP_SERVER_URL : window.location.origin

const oidcConfig = {
    authority: `${SERVER_URL}`,
    client_id: "CWAT.Api",
    redirect_uri: `${SERVER_URL}/authentication/login-callback`,
    loadUserInfo: true,
    userStore: new WebStorageStateStore({ store: window.localStorage }),
    onSigninCallback() {
      window.history.replaceState({}, document.title, window.location.pathname)
    },
  }
  
  ReactDOM.render(
    <AuthProvider {...oidcConfig}>
      <App />
    </AuthProvider>,
    document.getElementById("root")
  )