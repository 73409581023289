/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useContext } from "react"

//Libraries
import { useLocation } from "react-router-dom"
import { DataGrid } from "@mui/x-data-grid"
import { Button } from "@mui/material"
import { ArrowCircleRight } from "@mui/icons-material"

// Data
import { QueryParamTypes, apiCalls } from "../DataService"

// Custom
import SnackbarMessages from "../SnackbarMessages"
import FTEValidationChangeConfirmationModal from "./FTEValidationChangeConfirmationModal"
import UploadProgressModal from "../RFIs/UploadProgressModal"
import RunWorkforceWorkloadConfirmationModal from "../RunWorkforceWorkloadConfirmationModal"

// Vars
import {
  MinTableHeight,
  RFIViewOrgMappings,
  SubmitStatus,
  SubmitStatusArr,
  SubmitStatusIdx,
  calculateFTEValue,
  returnErrorMessageFromAPIError,
} from "../../Utils"
import FTEValidationUtils from "./FTEValidationUtils"

// Styles
import "../RFIs/RFI.css"
import { makeStyles } from "@mui/styles"
import AppContext from "../../AppContext"

const useStyles = makeStyles({
  fteContainer: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    alignItems: "center",
    width: "99%",
    "& .MuiDataGrid-columnHeaderTitleContainerContent": {
      width: "100%",
    },
    "& .MuiDataGrid-columnHeaderTitle": {
      overflow: "visible",
      lineHeight: "1.25rem",
      whiteSpace: "normal",
      textAlign: "center",
      width: "100%",
    },
    "& .MuiDataGrid-cell": {
      justifyContent: "center",
      border: "0.5px solid black",
    },
  },
  fteHeader: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-end",
    width: "100%",
    margin: "0 1rem",
  },
  actionButtons: {
    display: "flex",
    flexDirection: "row",
    alignSelf: "flex-end",
    alignItems: "center",
    justifyContent: "flex-end",
    width: "250px",
  },
  table: {
    width: "100%",
    height: 800,
    display: "flex",
    flexDirection: "column",
    alignSelf: "center",
    marginTop: "2rem",
    minHeight: MinTableHeight,
  },
})

const FTEValidationDashboard = (props) => {
  const { fteDashboardOrgRfiMapping, rfiOrganizationsMapping, fetchFTEDashboardObjectsFromDB } = props
  const classes = useStyles()
  const location = useLocation()
  const context = useContext(AppContext)
  const { activeFiscalCycle, allOrganizations } = context

  const [modifiedCells, setModifiedCells] = useState([])
  const [FTEValidationTableRows, setFTEValidationTableRows] = useState([])
  const [MUITableHeight, setMUITableHeight] = useState(800)
  const [showSaveChangesConfirmationModal, setShowSaveChangesConfirmationModal] = useState(false)
  const [showLoadingSpinner, setShowLoadingSpinner] = useState(false)
  const [loadingTitle, setLoadingTitle] = useState("")
  const [supplementalWorkforceRfiDBData, setSupplementalWorkforceRfiDBData] = useState([])
  const [openRunWorkforceConfirmationDialog, setOpenRunWorkforceConfirmationDialog] = useState(false)
  const [showSnackbarSuccess, setShowSnackbarSuccess] = useState(false)
  const [showSnackbarError, setShowSnackbarError] = useState(false)
  const [snackbarMessage, setSnackbarMessage] = useState("")

  const [workforceRecords, setWorkforceRecords] = useState("")

  const createFTEDashboardInitObj = (rfiOrganizationsMapping) => {
    if (Object.keys(rfiOrganizationsMapping).length > 0) {
      // Initialize the global fteDashboardOrgRfiMapping object
      const tmpOrgRfiMappingRFIObjects = {}
      for (const key of Object.keys(rfiOrganizationsMapping)) {
        tmpOrgRfiMappingRFIObjects[key] = {}
        const rfiOrgs = rfiOrganizationsMapping[key]
        for (const orgName of rfiOrgs) {
          if (!tmpOrgRfiMappingRFIObjects[key][orgName]) {
            tmpOrgRfiMappingRFIObjects[key][orgName] = {}
          }
        }
      }
      return tmpOrgRfiMappingRFIObjects
    }
  }

  // QUERY TEST
  // const useFetch = (url) => {
  //   const [data, setData] = useState("")

  //   useEffect(() => {
  //     getData()
  //   }, [url])

  //   const getData = async () => {
  //     let response = await apiCalls.fetchData(url)
  //     setData(response)
  //   }
  //   return data
  // }

  // useFetch("WorkforceRecords")

  // ** --------------***     [ ~ VARS ~ ]     ***-------------- **

  const FTEValidationTableColumns = [
    {
      field: "id",
      headerName: "Id",
      hide: true,
      sortable: false,
      disableColumnMenu: true,
      headerClassName: "--grid-header",
    },
    {
      field: "organization",
      headerName: "Organization",
      flex: 1,
      minWidth: 200,
      sortable: false,
      disableColumnMenu: true,
      headerClassName: "--grid-header",
    },
    {
      field: "civilianSumHours",
      headerName: "Civilian Sum Hours with WBS Mapping",
      flex: 1,
      minWidth: 200,
      sortable: false,
      disableColumnMenu: true,
      headerClassName: "--grid-header",
    },
    {
      field: "civFTEs",
      headerName: "CIV FTEs",
      flex: 1,
      minWidth: 200,
      sortable: false,
      disableColumnMenu: true,
      headerClassName: "--grid-header",
    },
    {
      field: "matrix",
      headerName: "Unaccounted for CIV (Matrix) FTEs",
      flex: 1,
      minWidth: 200,
      sortable: false,
      disableColumnMenu: true,
      headerClassName: "--grid-header",
    },
    {
      field: "ctrFTEs",
      headerName: "CTR FTEs",
      flex: 1,
      minWidth: 200,
      sortable: false,
      disableColumnMenu: true,
      headerClassName: "--grid-header",
    },
    {
      field: "milFTEs",
      headerName: "MIL FTEs",
      flex: 1,
      minWidth: 200,
      sortable: false,
      disableColumnMenu: true,
      headerClassName: "--grid-header",
    },
    {
      field: "totalCivFTEs",
      headerName: "Total CIV FTEs",
      flex: 1,
      minWidth: 200,
      sortable: false,
      disableColumnMenu: true,
      headerClassName: "--grid-header",
    },
    {
      field: "totalFTEs",
      headerName: "Total FTEs",
      flex: 1,
      minWidth: 200,
      sortable: false,
      disableColumnMenu: true,
      headerClassName: "--grid-header",
    },
    {
      field: FTEValidationUtils.FTEValidationDashboardHeaders.FUNDING_MEMO_CIV_WYS,
      headerName: "Funding Memo FMS-A approved CIV WYs",
      flex: 1,
      minWidth: 200,
      editable: true,
      type: "number",
      sortable: false,
      disableColumnMenu: true,
      headerClassName: "--grid-header",
      cellClassName: "editable-cell",
    },
    {
      field: FTEValidationUtils.FTEValidationDashboardHeaders.OVER_UNDER_ACTUAL_CIV_FTES,
      headerName: "Over/Under Actuals CIV FTEs",
      flex: 1,
      minWidth: 200,
      sortable: false,
      disableColumnMenu: true,
      headerClassName: "--grid-header",
    },
    {
      field: FTEValidationUtils.FTEValidationDashboardHeaders.NOTES,
      headerName: "Notes",
      hide: true,
      flex: 1,
      minWidth: 200,
      sortable: false,
      disableColumnMenu: true,
      headerClassName: "--grid-header",
    },
    {
      field: FTEValidationUtils.FTEValidationDashboardHeaders.CEFMS_OR_GFEBS_R1,
      headerName: "CEFMS or GFEBS Report 1",
      flex: 1,
      minWidth: 200,
      sortable: false,
      disableColumnMenu: true,
      headerClassName: "--grid-header",
      renderCell: (params) => {
        if (params.row[FTEValidationUtils.FTEValidationDashboardHeaders.CEFMS_OR_GFEBS_R1] == null) return null
        const submitStatus = Object.values(SubmitStatus)[params.row[FTEValidationUtils.FTEValidationDashboardHeaders.CEFMS_OR_GFEBS_R1]]
        const split = submitStatus.split("_")
        return (
          <div className="MuiDataGrid-cellContent">
            <div style={{ textAlign: "center" }}>
              {split[0] && <>{split[0]}</>}
              <br />
              {split[1] && <>{split[1]}</>}
            </div>
            <Button onClick={() => changeRFIStatus(params)}>
              <ArrowCircleRight />
            </Button>
          </div>
        )
      },
    },
    {
      field: FTEValidationUtils.FTEValidationDashboardHeaders.GFEBS_R2,
      headerName: "GFEBS Report 2",
      flex: 1,
      minWidth: 200,
      sortable: false,
      disableColumnMenu: true,
      headerClassName: "--grid-header",
      renderCell: (params) => {
        if (params.row[FTEValidationUtils.FTEValidationDashboardHeaders.GFEBS_R2] == null) return null
        const submitStatus = Object.values(SubmitStatus)[params.row[FTEValidationUtils.FTEValidationDashboardHeaders.GFEBS_R2]]
        const split = submitStatus.split("_")
        return (
          <div className="MuiDataGrid-cellContent">
            <div style={{ textAlign: "center" }}>
              {split[0] && <>{split[0]}</>}
              <br />
              {split[1] && <>{split[1]}</>}
            </div>
            <Button onClick={() => changeRFIStatus(params)}>
              <ArrowCircleRight />
            </Button>
          </div>
        )
      },
    },
    {
      field: FTEValidationUtils.FTEValidationDashboardHeaders.SUPPLEMENTAL_WORKFORCE,
      headerName: "Supplemental Workforce",
      flex: 1,
      minWidth: 195,
      headerClassName: "--grid-header",
      renderCell: (params) => {
        if (params.row[FTEValidationUtils.FTEValidationDashboardHeaders.SUPPLEMENTAL_WORKFORCE] == null) return null
        const submitStatus = Object.values(SubmitStatus)[params.row[FTEValidationUtils.FTEValidationDashboardHeaders.SUPPLEMENTAL_WORKFORCE]]
        const split = submitStatus.split("_")
        return (
          <div className="MuiDataGrid-cellContent">
            <div style={{ textAlign: "center" }}>
              {split[0] && <>{split[0]}</>}
              <br />
              {split[1] && <>{split[1]}</>}
            </div>
            <Button onClick={() => changeRFIStatus(params)}>
              <ArrowCircleRight />
            </Button>
          </div>
        )
      },
    },
    {
      field: FTEValidationUtils.FTEValidationDashboardHeaders.CIVILIAN_PERCENTAGES,
      headerName: "Civilian Percentages",
      flex: 1,
      minWidth: 200,
      sortable: false,
      disableColumnMenu: true,
      headerClassName: "--grid-header",
      cellClassName: (params) => {
        if (!RFIViewOrgMappings["/CivilianPercentages"].includes(params.row.organization)) {
          return "--FTE-validation-disabled-cell"
        }
      },
      renderCell: (params) => {
        if (params.row[FTEValidationUtils.FTEValidationDashboardHeaders.CIVILIAN_PERCENTAGES] == null) return null
        const submitStatus = Object.values(SubmitStatus)[params.row[FTEValidationUtils.FTEValidationDashboardHeaders.CIVILIAN_PERCENTAGES]]
        const split = submitStatus.split("_")
        return (
          <div className="MuiDataGrid-cellContent">
            <div style={{ textAlign: "center" }}>
              {split[0] && <>{split[0]}</>}
              <br />
              {split[1] && <>{split[1]}</>}
            </div>
            <Button onClick={() => changeRFIStatus(params)}>
              <ArrowCircleRight />
            </Button>
          </div>
        )
      },
    },
    {
      field: FTEValidationUtils.FTEValidationDashboardHeaders.BPC_REDUCTION,
      headerName: "BPC Reduction",
      flex: 1,
      minWidth: 200,
      sortable: false,
      disableColumnMenu: true,
      headerClassName: "--grid-header",
      renderCell: (params) => {
        if (params.row[FTEValidationUtils.FTEValidationDashboardHeaders.BPC_REDUCTION] == null) return null
        const submitStatus = Object.values(SubmitStatus)[params.row[FTEValidationUtils.FTEValidationDashboardHeaders.BPC_REDUCTION]]
        const split = submitStatus.split("_")
        return (
          <div className="MuiDataGrid-cellContent">
            <div style={{ textAlign: "center" }}>
              {split[0] && <>{split[0]}</>}
              <br />
              {split[1] && <>{split[1]}</>}
            </div>
            <Button onClick={() => changeRFIStatus(params)}>
              <ArrowCircleRight />
            </Button>
          </div>
        )
      },
    },
  ]
  // ** --------------***     [ ~ EFFECTS ~ ]     ***-------------- **
  useEffect(() => {
    adjustTableHeight()
  }, [location])

  useEffect(() => {
    window.addEventListener("resize", adjustTableHeight)
  }, [activeFiscalCycle])

  useEffect(() => {
    fetchSWRFIData()
  }, [activeFiscalCycle, allOrganizations, fteDashboardOrgRfiMapping])

  useEffect(() => {
    if (activeFiscalCycle) {
      fetchWorkforceRecords()
    }
  }, [activeFiscalCycle])

  useEffect(() => {
    if (workforceRecords) {
      loadFTEValidationData()
    }
  }, [workforceRecords, supplementalWorkforceRfiDBData, fteDashboardOrgRfiMapping])

  // ** --------------***     [ ~ FUNCTIONS ~ ]     ***-------------- **
  const fetchWorkforceRecords = async () => {
    let response = await apiCalls.getAll("WorkforceRecords")
    let filtered = response.filter((item) => item.fiscalCycle.id === activeFiscalCycle.id)

    // TODO: Add alert if current FC cannot be used/has no data
    setWorkforceRecords(filtered)
  }

  const getWFRows = (data) => {
    let allRows = data.map((item) => {
      let {
        id,
        organization,
        leaveHours,
        preLORHours,
        caseDevelopmentHours,
        caseExecutionHours,
        caseClosureHours,
        civilianFTEs,
        businessSustainmentHours,
        militaryFTEs,
        contractorFTEs,
        matrix,
      } = item

      let totalHours = leaveHours + preLORHours + caseDevelopmentHours + caseExecutionHours + caseClosureHours + businessSustainmentHours
      let civFTE = calculateFTEValue(leaveHours, totalHours)

      return {
        id,
        organization: organization.name,
        civilianFTEs,
        militaryFTEs,
        contractorFTEs,
        totalFTEs: "",
        totalCivFTEs: civFTE,
        matrix,
        totalHours,
      }
    })

    return allRows
  }

  const loadFTEValidationData = async () => {
    let wfRows = getWFRows(workforceRecords)
    let mappingKeys = Object.keys(fteDashboardOrgRfiMapping)

    if (mappingKeys.length > 0 && allOrganizations.length > 0) {
      // ORG MAPPING
      let tableRows = allOrganizations.map((org) => {
        let { cefms, gfebsReport1, gfebsReport2, supplementalWorkforce, civilianPercentages, bpcReduction } = fteDashboardOrgRfiMapping

        let cefmsOrGfebsR1 = org.name === "USACE" ? cefms[org.name]?.status : gfebsReport1[org.name]?.status
        let gfebsR2 = gfebsReport2[org.name]?.status
        let suppWFData = supplementalWorkforce[org.name]?.status
        let civPercentageData = civilianPercentages[org.name]?.status
        let bpcReductionData = bpcReduction[org.name]?.status

        // SUPPLEMENTAL WORKFORCE MAPPING
        let civilianFtes = 0
        supplementalWorkforceRfiDBData.forEach((rfi) => {
          let { group, organization, leave, pre_Lor, caseDevelopment, caseExecution, caseClosure, businessSustaining } = rfi

          if (organization.name === rfi.name) {
            let total = leave + pre_Lor + caseDevelopment + caseExecution + caseClosure + businessSustaining

            if (group === 0) {
              civilianFtes = calculateFTEValue(leave, total)
            }
          }
        })

        let wf = wfRows.find((row) => row.organization === org.name)

        let totalFTENum = wf?.totalCivFTEs + civilianFtes + wf?.contractorFTEs
        let totalCivFTENum = wf?.totalCivFTEs + civilianFtes

        let row = {
          id: org.id,
          organization: org.name,
          civilianSumHours: wf?.totalHours ? round(wf.totalHours) : null,
          matrix: wf?.matrix,
          civFTEs: wf?.totalCivFTEs,
          milFTEs: wf?.militaryFTEs,
          ctrFTEs: wf?.contractorFTEs,
          totalCivFTEs: totalCivFTENum ? round(totalCivFTENum) : null,
          totalFTEs: totalFTENum ? round(totalFTENum) : null,
          notes: "",
          unaccountedCivFTEs: civilianFtes ? civilianFtes : null,
          cefmsOrGFEBSR1: cefmsOrGfebsR1 || cefmsOrGfebsR1 === 0 ? cefmsOrGfebsR1 : null,
          gfebsR2: gfebsR2 || gfebsR2 === 0 ? gfebsR2 : null,
          supplementalWorkforce: suppWFData || suppWFData === 0 ? suppWFData : null,
          civilianPercentages: civPercentageData || civPercentageData === 0 ? civPercentageData : null,
          bpcReduction: bpcReductionData || bpcReductionData === 0 ? bpcReductionData : null,
        }

        return row
      })
      setFTEValidationTableRows(tableRows)
    }
  }

  const fetchSWRFIData = async () => {
    if (activeFiscalCycle?.id) {
      apiCalls
        .getAll(`SupplementalWorkforceRfis`, {
          [QueryParamTypes.FILTER]: `fiscalCycleId eq ${activeFiscalCycle.id}`,
          [QueryParamTypes.EXPAND]: "organization",
        })
        .then((data) => {
          setSupplementalWorkforceRfiDBData(data)
        })
    }
  }

  const saveRFIChanges = async () => {
    setShowLoadingSpinner(true)
    setLoadingTitle("Saving RFI Changes...")
    for (const cell of modifiedCells) {
      let rfiObj = null
      let apiType = null
      switch (cell.field) {
        case FTEValidationUtils.FTEValidationDashboardHeaders.CEFMS_OR_GFEBS_R1:
          if (cell.organization === "USACE") {
            apiType = FTEValidationUtils.UrlApiTypes.CEFMS
            rfiObj = fteDashboardOrgRfiMapping.cefms[cell.organization]
          } else {
            apiType = FTEValidationUtils.UrlApiTypes.GFEBS
            rfiObj = fteDashboardOrgRfiMapping.gfebsReport1[cell.organization]
          }
          break
        case FTEValidationUtils.FTEValidationDashboardHeaders.GFEBS_R2:
          apiType = FTEValidationUtils.UrlApiTypes.GFEBS
          rfiObj = fteDashboardOrgRfiMapping.gfebsReport2[cell.organization]
          break
        case FTEValidationUtils.FTEValidationDashboardHeaders.SUPPLEMENTAL_WORKFORCE:
          apiType = FTEValidationUtils.UrlApiTypes.SUPPLEMENTAL_WORKFORCE
          rfiObj = fteDashboardOrgRfiMapping.supplementalWorkforce[cell.organization]
          break
        case FTEValidationUtils.FTEValidationDashboardHeaders.CIVILIAN_PERCENTAGES:
          apiType = FTEValidationUtils.UrlApiTypes.SUPPLEMENTAL_WORKFORCE
          rfiObj = fteDashboardOrgRfiMapping.civilianPercentages[cell.organization]
          break
        case FTEValidationUtils.FTEValidationDashboardHeaders.BPC_REDUCTION:
          apiType = FTEValidationUtils.UrlApiTypes.BPC_REDUCTION
          rfiObj = fteDashboardOrgRfiMapping.bpcReduction[cell.organization]
          break
        default:
          break
      }
      await apiCalls.put(`${apiType}/${rfiObj.id}`, { ...rfiObj, status: cell.value })
    }
    const initObj = createFTEDashboardInitObj(rfiOrganizationsMapping)
    fetchFTEDashboardObjectsFromDB(initObj, allOrganizations, activeFiscalCycle).then(() => setShowLoadingSpinner(false))
    setModifiedCells([])
  }

  const changeRFIStatus = (params) => {
    setFTEValidationTableRows((prevRows) => {
      return prevRows.map((tableRow) => {
        if (
          tableRow[FTEValidationUtils.FTEValidationDashboardHeaders.ORGANIZATION] ===
          params.row[FTEValidationUtils.FTEValidationDashboardHeaders.ORGANIZATION]
        ) {
          const prevValue = tableRow[params.field]
          let value = null
          switch (SubmitStatusArr[params.row[params.field]]) {
            case SubmitStatus.NOT_SUBMITTED:
              value = SubmitStatusIdx.SUBMITTED
              break
            case SubmitStatus.SUBMITTED:
              value = SubmitStatusIdx.APPROVED
              break
            case SubmitStatus.APPROVED:
              value = SubmitStatusIdx.REJECTED
              break
            case SubmitStatus.REJECTED:
              value = SubmitStatusIdx.NOT_SUBMITTED
              break
            case SubmitStatus.REQUEST_RESUBMISSION:
              value = SubmitStatusIdx.NOT_SUBMITTED
              break
            default:
              break
          }
          let updatedCells = []
          if (modifiedCells.map((cell) => cell.orgId).includes(params.row.id)) {
            updatedCells = modifiedCells.map((cell) => {
              if (cell.orgId === params.row.id) {
                return { ...cell, value }
              } else return cell
            })
          } else {
            updatedCells = [
              ...modifiedCells,
              {
                orgId: params.row.id,
                organization: tableRow[FTEValidationUtils.FTEValidationDashboardHeaders.ORGANIZATION],
                field: params.field,
                prevValue,
                value,
              },
            ]
          }
          setModifiedCells(updatedCells.filter((cell) => cell.value !== cell.prevValue))
          return { ...tableRow, [params.field]: value }
        } else return tableRow
      })
    })
  }

  const adjustTableHeight = () => {
    setMUITableHeight(window.innerHeight - 300)
  }

  const executeRunWorkforceStoredProcedures = () => {
    setShowLoadingSpinner(true)
    setLoadingTitle("Executing Run Workforce stored procedures...")
    apiCalls
      .post(`WorkloadWorkforceProcedures/RunWorkforce?fiscalYear=${activeFiscalCycle?.fiscalYear}`)
      .then(() => {
        setShowSnackbarSuccess(true)
        setSnackbarMessage("Successfully executed Run Workforce stored procedures")
      })
      .catch((e) => {
        setShowSnackbarError(true)
        if (e?.response?.status === 504) {
          setSnackbarMessage(
            "There was server timeout. The Man Hours Models may have executed successfully. You can check this by waiting a minute then refreshing your page."
          )
          return
        }
        const errMsg = returnErrorMessageFromAPIError(e)
        if (errMsg) {
          setSnackbarMessage(errMsg)
        } else {
          setSnackbarMessage(
            "There was a server timeout when attempting to execute the Run Workforce stored procedures. The stored procedures may have executed successfully, you can check this by contacting your system administrator."
          )
        }
      })
      .finally(() => {
        fetchWorkforceRecords()
        setShowLoadingSpinner(false)
      })
  }

  const round = (num) => {
    return Math.round(num * 100) / 100
  }

  return (
    <div className={classes.fteContainer}>
      <div className={classes.fteHeader}>
        <div className={classes.actionButtons}>
          <button
            className="--RFI-table-action-button"
            disabled={modifiedCells.length <= 0}
            onClick={() => setShowSaveChangesConfirmationModal(true)}
          >
            SAVE CHANGES
          </button>
          <button
            onClick={() => setOpenRunWorkforceConfirmationDialog(true)}
            className="--RFI-table-action-button"
            disabled={false}
          >
            RUN WORKFORCE
          </button>
        </div>
      </div>
      <div
        className={classes.table}
        style={{ height: MUITableHeight }}
      >
        {FTEValidationTableRows[0]?.id && (
          <DataGrid
            loading={!FTEValidationTableRows[0]}
            variant="styledDataGrid"
            rows={FTEValidationTableRows}
            columns={FTEValidationTableColumns}
            initialState={{
              columns: {
                columnVisibilityModel: { id: false },
              },
            }}
          />
        )}
      </div>
      <SnackbarMessages
        showSnackbarSuccess={showSnackbarSuccess}
        setShowSnackbarSuccess={setShowSnackbarSuccess}
        showSnackbarError={showSnackbarError}
        setShowSnackbarError={setShowSnackbarError}
        snackbarMessage={snackbarMessage}
      />
      <UploadProgressModal
        open={showLoadingSpinner}
        title={loadingTitle}
        loadingProgress={null}
      />
      <RunWorkforceWorkloadConfirmationModal
        open={openRunWorkforceConfirmationDialog}
        setOpen={setOpenRunWorkforceConfirmationDialog}
        title="Execute Run Workforce"
        message="You are about to execute the run workforce stored procedures"
        runWorkloadOrWorkforce={executeRunWorkforceStoredProcedures}
      />
      <FTEValidationChangeConfirmationModal
        open={showSaveChangesConfirmationModal}
        setOpen={setShowSaveChangesConfirmationModal}
        modifiedCells={modifiedCells}
        saveRFIChanges={saveRFIChanges}
      />
    </div>
  )
}

export default FTEValidationDashboard
