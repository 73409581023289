import { useContext, useEffect, useState } from "react"

//MUI
import { Box, Button, FormControl, IconButton, InputLabel, MenuItem, Select, Toolbar, Typography } from "@mui/material"
import MenuIcon from "@mui/icons-material/Menu"

// CUSTOM
import logo from "../../../CWATImages/dasadec-logo.png"
import AppContext from "../../../AppContext"
import { useAuth } from "react-oidc-context"

const CustomToolbar = ({ open, handleDrawerOpen }) => {
  const context = useContext(AppContext)
  const auth = useAuth()
  const { selectedOrg, handleOrgChange, filteredOrganizations, activeFiscalCycle } = context

  const handleChange = (org) => {
    handleOrgChange(org.target.value)
  }

  const getOptions = (arr, value) => {
    return arr.map((item, index) => {
      return (
        <MenuItem
          sx={{ minWidth: "150px" }}
          key={item.id}
          value={item}
        >
          {item[value]}
        </MenuItem>
      )
    })
  }

  return (
    <Toolbar sx={{ maxHeight: "90px" }}>
      <IconButton
        color="inherit"
        aria-label="open drawer"
        onClick={handleDrawerOpen}
        edge="start"
        sx={[
          {
            marginRight: 5,
          },
          open && { display: "none" },
        ]}
      >
        <MenuIcon />
      </IconButton>
      {/* CWAT LOGO */}

      <img
        src={logo}
        style={{ height: "50px", marginRight: "10px" }}
      />
      {/* HEADER/SUBHEADER */}
      <Box sx={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
        <Box
          className="flexColumn"
          sx={{ justifyContent: "center" }}
        >
          <Typography
            variant="pageHeader"
            className="titleTest"
          >
            Common Workforce Analysis Tool (CWAT)
          </Typography>
          <Typography variant="pageSubheader">{selectedOrg.name}</Typography>
          {/* - - - */}
        </Box>

        <Box sx={{ display: "flex", alignItems: "center", maxHeight: "50px" }}>
          <FormControl
            sx={{
              display: "flex",
              minWidth: "150px",
              marginRight: "15px",
              maxHeight: "50px",
              color: "white",
            }}
          >
            <InputLabel sx={{ color: "white" }}>Organization</InputLabel>
            <Select
              label="Organization"
              variant="outlined"
              value={selectedOrg}
              renderValue={(params) => params.name}
              onChange={handleChange}
              autoWidth
              sx={{
                color: "white",
                ".MuiSelect-select": {
                  maxHeight: "100%",
                  padding: "7px 15px",
                },
                ".MuiInputBase-root": {
                  borderColor: "white",
                },
              }}
            >
              {getOptions(filteredOrganizations, "name")}
            </Select>
          </FormControl>
          <FormControl
            sx={{
              display: "flex",
              minWidth: "150px",
              marginRight: "15px",
              maxHeight: "50px",
              color: "white",
            }}
          >
            <InputLabel sx={{ color: "white" }}>Fiscal Cycle</InputLabel>
            <Select
              label="Fiscal Cycle"
              variant="outlined"
              value={activeFiscalCycle}
              renderValue={(params) => params.fiscalYear}
              autoWidth
              sx={{
                color: "white",
                ".MuiSelect-select": {
                  maxHeight: "100%",
                  padding: "7px 15px",
                },
                ".MuiInputBase-root": {
                  borderColor: "white",
                },
              }}
              inputProps={{ readOnly: true }}
            />
          </FormControl>
          <Typography variant="pageSubheader" sx={{ paddingRight: 1 }}>{ auth.user.profile.email }</Typography>
          <Button
            variant="outlined"
            className="buttonTest"
            onClick={() => { auth.removeUser(); window.location.replace('/Identity/Account/Logout') }}
            sx={{ color: "white", borderColor: "white" }}
          >
            Logout
          </Button>
        </Box>
      </Box>
    </Toolbar>
  )
}

export default CustomToolbar
