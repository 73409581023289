import { useContext, useEffect, useState } from "react"

// MUI
import { Box, Tab, Tabs } from "@mui/material"

// Custom
import RFIStatusManagement from "./RFIApproveReject/RFIStatusManagement"
import FTEValidationDashboard from "./FTEValidationDashboard/FTEValidationDashboard"
import CustomTabPanel from "./custom/CustomTabPanel"
import AppContext from "../AppContext"
import { apiCalls, QueryParamTypes } from "./DataService"
import {
  RFIFieldCheckStatus,
  RFIGroupingAPITypes,
  RFIGroupings,
  RFIOrgMappingAPITypes,
  SubmitStatus,
  SubmitStatusArr,
  SubmitStatusIdx,
} from "../Utils"
import GFEBS_CEFMS_Table_Utils from "./RFIs/GFEBS-CEFMS/GFEBS_CEFMS_Table_Utils"
import { v4 as uuidv4 } from "uuid"

const RFIValidationDashboardMenu = () => {
  // Initialize App-level Data
  const context = useContext(AppContext)
  const { rfiOrgMapping, activeFiscalCycle, allOrganizations, filterOrganizations } = context

  const [rfiRecordCountMapping, setRfiRecordCountMapping] = useState({})
  const [rfiDashboardOrgRfiMapping, setRfiDashboardOrgRfiMapping] = useState({})
  const [fteDashboardOrgRfiMapping, setFteDashboardOrgRfiMapping] = useState({})

  // For now, going to make each page responsible for providing what it supports but there is a mapping in utils (RFIViewOrgMappings)
  useEffect(() => {
    filterOrganizations(["DASA DE&C"])
  }, [])

  const tabs = ["RFI Dashboard", "FTE Validation Dashboard"]

  const [currentTab, setCurrentTab] = useState({
    num: 0,
    open: false,
    name: tabs[0],
  })
  const tabProps = (index) => {
    return {
      id: `vertical-tab-${index}`,
      "aria-controls": `vertical-tabpanel-${index}`,
      disableRipple: true,
      variant: "tabbedView",
    }
  }

  const handleTabChange = (e, newValue) => {
    setCurrentTab({
      num: newValue,
      name: tabs[newValue],
    })
  }

  async function getRfiRecordCountMapping(selectedFiscalCycle) {
    if (selectedFiscalCycle?.id) {
      const rfiRecordCountMapping = await apiCalls.getById(`RfiDashboard`, selectedFiscalCycle.id).then((dashboard) => {
        return dashboard
      })
      return rfiRecordCountMapping
    }
    return null
  }

  const createFTEDashboardInitObj = (rfiOrganizationsMapping) => {
    if (Object.keys(rfiOrganizationsMapping).length > 0) {
      // Initialize the global fteDashboardOrgRfiMapping object
      const tmpOrgRfiMappingRFIObjects = {}
      for (const key of Object.keys(rfiOrganizationsMapping)) {
        tmpOrgRfiMappingRFIObjects[key] = {}
        const rfiOrgs = rfiOrganizationsMapping[key]
        for (const orgName of rfiOrgs) {
          if (!tmpOrgRfiMappingRFIObjects[key][orgName]) {
            tmpOrgRfiMappingRFIObjects[key][orgName] = {}
          }
        }
      }
      return tmpOrgRfiMappingRFIObjects
    }
  }

  const fetchRFIDashboardObjectsFromDB = async (rfiOrganizationsMapping, allOrgs, selectedFiscalCycle, rfiRecordCountMapping) => {
    let tmpRfiDashboardOrgRfiMapping = {}
    for (const rfiGrouping of Object.values(RFIGroupings)) {
      let tmpRfiGrouping = []
      for (const rfiMappingObj of RFIGroupingAPITypes[rfiGrouping]) {
        const rfiApiType = rfiMappingObj.apiType
        const rfiMappingName = rfiMappingObj.rfiMappingName
        let queryParams = {
          [QueryParamTypes.EXPAND]: "organization",
          [QueryParamTypes.FILTER]: `fiscalCycleId eq ${selectedFiscalCycle.id}`,
        }
        let rfiMappingOrgs = []
        Object.keys(rfiOrganizationsMapping).forEach((table) => {
          if (table === rfiMappingName) {
            rfiMappingOrgs = rfiOrganizationsMapping[table]
          }
        })
        if (rfiMappingName === "gfebsReport1")
          queryParams = {
            ...queryParams,
            [QueryParamTypes.FILTER]: queryParams[QueryParamTypes.FILTER].concat(
              ` and reportType eq '${GFEBS_CEFMS_Table_Utils.ReportType.GFEBS_R1}'`
            ),
          }
        else if (rfiMappingName === "gfebsReport2")
          queryParams = {
            ...queryParams,
            [QueryParamTypes.FILTER]: queryParams[QueryParamTypes.FILTER].concat(
              ` and reportType eq '${GFEBS_CEFMS_Table_Utils.ReportType.GFEBS_R2}'`
            ),
          }
        else if (rfiMappingName === "civilianPercentages")
          queryParams = {
            ...queryParams,
            [QueryParamTypes.FILTER]: queryParams[QueryParamTypes.FILTER].concat(` and group eq 'CIVILIAN_PERCENTAGE'`),
          }
        else if (rfiMappingName === "supplementalWorkforce")
          queryParams = {
            ...queryParams,
            [QueryParamTypes.FILTER]: queryParams[QueryParamTypes.FILTER].concat(` and group ne 'CIVILIAN_PERCENTAGE'`),
          }
        await apiCalls
          .getAll(`${rfiApiType}`, queryParams)
          .then(async (data) => {
            if (data) {
              let rows = []
              for (const org of rfiMappingOrgs) {
                let associationsList = []
                if (rfiGrouping === RFIGroupings.PEOPM_SATMO_USACE) {
                  const orgId = allOrgs.find((o) => o.name === org)?.id
                  const organization = await apiCalls.getById(`Organizations`, orgId)
                  associationsList =
                    rfiMappingName === "usace"
                      ? organization?.dsamsPrepActDataAssociationsList?.concat(organization?.cisilDataDescriptionWorkloadList)
                      : organization?.dsamsPrepActDataAssociationsList
                }
                const orgRfi = data.find((rfi) => rfi.organization.name === org)
                let row = {
                  organization: org,
                  Id: orgRfi?.id ? orgRfi.id : uuidv4(),
                  rfiMappingName: rfiMappingName,
                  poc: null,
                  status: null,
                  dateLastModified: null,
                  rowCount: null,
                  check1: null,
                  check2: null,
                  notes: null,
                }
                if (orgRfi) {
                  let rowCount = 0
                  let prevStatus = null
                  let status = null
                  if (rfiGrouping === RFIGroupings.PEOPM_SATMO_USACE) {
                    rowCount = associationsList ? associationsList?.length : 0
                    if (orgRfi?.status !== SubmitStatusIdx.NOT_GENERATED && orgRfi?.status !== SubmitStatusIdx.GENERATED) {
                      prevStatus = SubmitStatusArr[orgRfi.status]
                      status = SubmitStatusArr[orgRfi.status]
                    } else {
                      prevStatus = rowCount > 0 ? SubmitStatus.GENERATED : SubmitStatus.NOT_GENERATED
                      status = rowCount > 0 ? SubmitStatus.GENERATED : SubmitStatus.NOT_GENERATED
                    }
                  } else {
                    rowCount = rfiRecordCountMapping[rfiMappingName].find((rfi) => rfi.organization === org)?.records
                      ? rfiRecordCountMapping[rfiMappingName].find((rfi) => rfi.organization === org).records
                      : 0
                    prevStatus = SubmitStatusArr[orgRfi.status]
                    status = SubmitStatusArr[orgRfi.status]
                  }
                  row = {
                    ...row,
                    Id: orgRfi.id,
                    poc: orgRfi?.poc ? orgRfi.poc : null,
                    prevStatus,
                    status,
                    dateLastModified: rowCount > 0 ? orgRfi.modifiedDate : null,
                    rowCount,
                    check1: RFIFieldCheckStatus.NOT_STARTED,
                    check2: RFIFieldCheckStatus.NOT_STARTED,
                    prevNotes: orgRfi.comments,
                    notes: orgRfi.comments,
                  }
                }
                rows?.push(row)
              }
              tmpRfiGrouping.push({ ...rfiMappingObj, rows })
            } else tmpRfiGrouping.push(rfiMappingObj)
          })
          .catch((e) => {
            console.error(e)
            tmpRfiGrouping.push(rfiMappingObj)
          })
          .finally(() => {
            tmpRfiDashboardOrgRfiMapping[rfiGrouping] = tmpRfiGrouping
          })
      }
    }
    return tmpRfiDashboardOrgRfiMapping
  }

  const fetchFTEDashboardObjectsFromDB = async (initObj, allOrganizations, selectedFiscalCycle) => {
    const tmpOrgRfiMappingRFIObjects = { ...initObj }

    for (const rfi of Object.keys(tmpOrgRfiMappingRFIObjects)) {
      const orgs = Object.keys(tmpOrgRfiMappingRFIObjects[rfi])

      for (const org of orgs) {
        if (tmpOrgRfiMappingRFIObjects[rfi][org]) {
          let apiTableName = RFIOrgMappingAPITypes[rfi]

          // ** NEW
          if (rfi === "usaceHCaseException") {
            apiTableName = "UsaceHCaseExceptionRfis"
          }
          if (rfi === "usaceScip") {
            apiTableName = "UsaceScipRfis"
          }
          // ** END NEW ** //

          const orgId = allOrganizations.find((orgObj) => orgObj.name === org).id
          let queryParams = {
            [QueryParamTypes.EXPAND]: "organization",
            [QueryParamTypes.FILTER]: `organizationId eq ${orgId} and fiscalCycleId eq ${selectedFiscalCycle.id}`,
          }

          if (rfi === "gfebsReport1")
            queryParams = {
              ...queryParams,
              [QueryParamTypes.FILTER]: queryParams[QueryParamTypes.FILTER] + ` and reportType eq '${GFEBS_CEFMS_Table_Utils.ReportType.GFEBS_R1}'`,
            }
          else if (rfi === "gfebsReport2")
            queryParams = {
              ...queryParams,
              [QueryParamTypes.FILTER]: queryParams[QueryParamTypes.FILTER] + ` and reportType eq '${GFEBS_CEFMS_Table_Utils.ReportType.GFEBS_R2}'`,
            }
          else if (rfi === "civilianPercentages")
            queryParams = { ...queryParams, [QueryParamTypes.FILTER]: queryParams[QueryParamTypes.FILTER] + ` and group eq 'CIVILIAN_PERCENTAGE'` }
          else if (rfi === "supplementalWorkforce")
            queryParams = { ...queryParams, [QueryParamTypes.FILTER]: queryParams[QueryParamTypes.FILTER] + ` and group ne 'CIVILIAN_PERCENTAGE'` }
          await apiCalls.getAll(`${apiTableName}`, queryParams).then((data) => {
            tmpOrgRfiMappingRFIObjects[rfi][org] = data[0] ? data[0] : null
          })
        }
      }
    }
    return tmpOrgRfiMappingRFIObjects
  }

  const populateOrgRfiMappingRFIObjects = (initObj) => {
    fetchFTEDashboardObjectsFromDB(initObj, allOrganizations, activeFiscalCycle).then((data) => {
      setFteDashboardOrgRfiMapping(data)
    })
  }

  useEffect(() => {
    getRfiRecordCountMapping(activeFiscalCycle).then((out) => setRfiRecordCountMapping(out))
  }, [])

  useEffect(() => {
    const tmpOrgRfiMappingRFIObjects = createFTEDashboardInitObj(rfiOrgMapping)

    if (
      allOrganizations.length > 0 &&
      activeFiscalCycle?.id &&
      Object.keys(rfiOrgMapping).length > 0 &&
      Object.keys(rfiRecordCountMapping).length > 0
    ) {
      populateOrgRfiMappingRFIObjects(tmpOrgRfiMappingRFIObjects)
      fetchRFIDashboardObjectsFromDB(rfiOrgMapping, allOrganizations, activeFiscalCycle, rfiRecordCountMapping).then((data) => {
        setRfiDashboardOrgRfiMapping(data)
      })
    }
  }, [rfiOrgMapping, allOrganizations, activeFiscalCycle, rfiRecordCountMapping])

  return (
    <Box>
      {/* TABS */}
      <Tabs
        variant="scrollable"
        value={currentTab.num}
        onChange={handleTabChange}
        sx={{
          width: "100%",
          marginBottom: "5px",
          borderColor: "divider",
          ".MuiTabs-flexContainer": {
            justifyContent: "center",
          },
        }}
      >
        <Tab
          label="RFI Dashboard"
          {...tabProps(0)}
        />
        <Tab
          label="FTE Validation Dashboard"
          {...tabProps(1)}
        />
      </Tabs>

      {/* 1 - RFI DASHBOARD*/}
      <CustomTabPanel
        value={currentTab.num}
        index={0}
      >
        <RFIStatusManagement
          rfiRecordCountMapping={rfiRecordCountMapping}
          rfiDashboardOrgRfiMapping={rfiDashboardOrgRfiMapping}
          setRfiDashboardOrgRfiMapping={setRfiDashboardOrgRfiMapping}
        />
      </CustomTabPanel>

      {/* 2 - FTE VALIDATION DASHBOARD*/}
      <CustomTabPanel
        value={currentTab.num}
        index={1}
      >
        <FTEValidationDashboard
          fteDashboardOrgRfiMapping={fteDashboardOrgRfiMapping}
          rfiOrganizationsMapping={rfiOrgMapping}
          fetchFTEDashboardObjectsFromDB={fetchFTEDashboardObjectsFromDB}
        />
      </CustomTabPanel>
    </Box>
  )
}

export default RFIValidationDashboardMenu
