import React, { Component } from "react"
import { Route, Redirect } from "react-router-dom"
import { ApplicationPaths, QueryParameterNames } from "./ApiAuthorizationConstants"
import authService from "./AuthorizeService"
import CustomDrawer from "../elements/layout/CustomDrawer"

export default class AuthorizeRoute extends Component {
  constructor(props) {
    super(props)

    this.state = {
      ready: false,
      authenticated: false,
    }

    this.authenticationChanged = this.authenticationChanged.bind(this)
    this.populateAuthenticationState = this.populateAuthenticationState.bind(this)
  }

  componentDidMount() {
    this._subscription = authService.subscribe(() => this.authenticationChanged())
    this.populateAuthenticationState()
  }

  componentWillUnmount() {
    authService.unsubscribe(this._subscription)
  }

  async populateAuthenticationState() {
    const authenticated = await authService.isAuthenticated()
    this.setState({ ready: true, authenticated })
  }

  async authenticationChanged() {
    this.setState({ ready: false, authenticated: false })
    console.log("Authentication Changed")
    await this.populateAuthenticationState()
  }

  render() {
    const { ready, authenticated } = this.state
    const link = document.createElement("a")
    link.href = this.props.path
    const returnUrl = `${link.protocol}//${link.host}${process.env.PUBLIC_URL}${link.pathname}${link.search}${link.hash}`
    const redirectUrl = `${ApplicationPaths.Login}?${QueryParameterNames.ReturnUrl}=${encodeURIComponent(returnUrl)}`
    if (!ready) {
      return <div></div>
    } else {
      if (this.props.component) {
        const { component: Component, ...rest } = this.props
        return (
          <Route
            {...rest}
            render={(props) => {
              if (authenticated) {
                return <Component {...props} />
              } else {
                return <Redirect to={redirectUrl} />
              }
            }}
          />
        )
      } else {
        return <CustomDrawer>{this.props.element}</CustomDrawer>
      }
    }
  }
}
