import { useState, useEffect } from "react";
import { apiCalls } from "../components/DataService";

const useReferenceUtils = () => {
  
  const [names, setNames] = useState([]); 
  const [updatedColumnDefs, setUpdatedColumnDefs] = useState(columnDefs)
  const [updatedInputDefs, setUpdatedInputDefs] = useState(inputDefs)
  const [updatedReqVar, setUpdatedReqVar] = useState(reqVar)

  useEffect(() => {
    const fetchNames = async () => {
      try {
        const response = await apiCalls.fetchData("GfebsFundsCenters");
        const cleanData = response?.data || response;
        const namesArr = cleanData.map(element => element.organizationName);
        let uniqueNamesArr = Array.from(new Set(namesArr))
        uniqueNamesArr.sort((a, b) => {
          return a.localeCompare(b);
        });
        setNames(uniqueNamesArr);

        const updatedColumnDefsTemp = {
          GfebsHourCodes: [
            {
              field: "id",
              filterable: false,
            },
            {
              field: "hourCode",
              headerName: "Hour Code",
              flex: 1,
            },
            {
              field: "hourCodeDescription",
              headerName: "Description",
              flex: 1,
            },
            {
              field: "hourCategory",
              headerName: "Category",
              type: "singleSelect",
              valueOptions: ["Productive", "Unproductive", "Exclude"],
              flex: 1,
            },
            {
              field: "type",
              headerName: "Type",
              type: "singleSelect",
              valueOptions: ["Leave", "Worked"],
              flex: 1,
            },
          ],
          GfebsActivityIdPeCoreFunctions: [
            {
              field: "id",
              headerName: "ID",
              flex: 1,
            },
            {
              field: "workCategoryElement",
              headerName: "Work Category Element",
              flex: 1,
            },
            {
              field: "funding",
              headerName: "Funding",
              flex: 1,
            },
            {
              field: "pe",
              headerName: "PE",
              flex: 1,
            },
            {
              field: "coreFunction",
              headerName: "Core Function",
              flex: 1,
            },
            {
              field: "coreFunctionDescription",
              headerName: "Description",
              flex: 1,
            },
          ],
          GfebsFundsCenters: [
            {
              field: "id",
              headerName: "ID",
              flex: 1,
            },
            {
              field: "fundsCenter",
              headerName: "Funds Center",
              flex: 1,
            },
            {
              field: "fundsCenterDescription",
              headerName: "Description",
              flex: 1,
            },
            {
              field: "organizationName",
              headerName: "Organization Name",
              type: "singleSelect",
              valueOptions: uniqueNamesArr,
              flex: 1,
            },
          ],
          GfebsPartnerWbsElements: [
            {
              field: "id",
              headerName: "ID",
              flex: 1,
            },
            {
              field: "workCategoryElement",
              headerName: "Work Category Element",
              flex: 1,
            },
            {
              field: "partnerWbsElement",
              headerName: "Partner Wbs Element",
              flex: 1,
            },
            {
              field: "partnerWbsElementDescription",
              headerName: "Description",
              flex: 1,
            },
          ],
          Organizations: [
            {
              field: "id",
              headerName: "ID",
              flex: 1,
            },
            {
              field: "name",
              headerName: "Name",
              flex: 1,
            },
            {
              field: "longName",
              headerName: "Long Name",
              flex: 1,
            },
            {
              field: "oldName",
              headerName: "Old Name",
              flex: 1,
            },
            {
              field: "uic",
              headerName: "UIC",
              flex: 1,
            },
            {
              field: "referenceNumber",
              headerName: "Reference Number",
              flex: 1,
            },
            {
              field: "historicLookbackNumber",
              headerName: "Look Back Number",
              flex: 1,
            },
            {
              field: "modelsApplication",
              headerName: "Models Application",
              flex: 1,
            },
            {
              field: "rfiPackage",
              headerName: "RFI Package",
              flex: 1,
            },
          ],
          CountryCocoms: [
            {
              field: "id",
              headerName: "ID",
              flex: 1,
            },
            {
              field: "countryCode",
              headerName: "Country Code",
              flex: 1,
            },
            {
              field: "cocom",
              headerName: "COCOM",
              flex: 1,
            },
            {
              field: "cocomName",
              headerName: "COCOM Name",
              flex: 1,
            },
            {
              field: "countryName",
              headerName: "Country Name",
              flex: 1,
            },
          ],
          RicRecords: [
            {
              field: "id",
              headerName: "ID",
              flex: 1,
            },
            {
              field: "ric",
              headerName: "RIC",
              flex: 1,
            },
            {
              field: "lcmc",
              headerName: "LCMC",
              flex: 1,
            },
          ],
          DsamsPreparingActivityCodes: [
            {
              field: "id",
              headerName: "ID",
              flex: 1,
            },
            {
              field: "preparingActivityId",
              headerName: "Preparing Activity Id",
              flex: 1,
            },
            {
              field: "activityName",
              headerName: "Activity Name",
              flex: 1,
            },
            {
              field: "activityAbbreviationName",
              headerName: "Activity Abbreviation Name",
              flex: 1,
            },
            {
              field: "activityOfficeSymbolName",
              headerName: "Activity Office Symbol Name",
              flex: 1,
            },
            {
              field: "activityRoutingCode",
              headerName: "Activity Routing Code",
              flex: 1,
            },
            {
              field: "organization",
              headerName: "Organization",
              flex: 1,
            },
          ],
          GfebsCostCenters: [
            {
              field: "id",
              headerName: "ID",
              flex: 1,
            },
            {
              field: "costCenter",
              headerName: "Cost Center",
              flex: 1,
            },
            {
              field: "costCenterDescription",
              headerName: "Cost Center Description",
              flex: 1,
            },
          ],
          GfebsFunctionalAreas: [
            {
              field: "id",
              headerName: "ID",
              flex: 1,
            },
            {
              field: "functionalArea",
              headerName: "Functional Area",
              flex: 1,
            },
            {
              field: "functionalAreaDescription",
              headerName: "Functional Area Description",
              flex: 1,
            },
          ],
          ProgramElementCoreFunctions: [
            {
              field: "id",
              headerName: "ID",
              flex: 1,
            },
            {
              field: "programElement",
              headerName: "Program Element",
              flex: 1,
            },
            {
              field: "coreFunctionNumber",
              headerName: "Core Function Number",
              flex: 1,
            },
          ],
          BpcProgramCodes: [
            {
              field: "id",
              headerName: "ID",
            },
            {
              field: "programCodes",
              headerName: "Program Codes",
              flex: 1,
            },
          ],
        };

        const updatedInputDefsTemp = {
          "Hour Codes": [
            {
              label: "Hour Code",
              name: "hourCode",
              type: "textfield",
              required: true,
            },
            {
              label: "Description",
              name: "hourCodeDescription",
              type: "textarea",
              required: true,
            },
            {
              label: "Category",
              name: "hourCategory",
              type: "singleSelect",
              optionValues: ["Unproductive", "Productive", "Exclude"],
              required: true,
            },
            {
              label: "Type",
              name: "type",
              type: "singleSelect",
              optionValues: ["Leave", "Worked"],
              required: true,
            },
          ],
          "Gfebs Funds Centers": [
            {
              name: "fundsCenter",
              label: "Funds Center",
              type: "textfield",
              required: true,
            },
            {
              name: "fundsCenterDescription",
              label: "Description",
              type: "textfield",
              required: true,
            },
            {
              name: "organizationName",
              label: "Organization Name",
              type: "singleSelect",
              optionValues: uniqueNamesArr,
              required: true,
            },
          ],
          "Gfebs Activity Id Pe Core Functions": [
            {
              label: "Work Category Element",
              name: "workCategoryElement",
              type: "textfield",
              required: true,
            },
            {
              label: "Funding",
              name: "funding",
              type: "textfield",
              required: true,
            },
            {
              label: "PE",
              name: "pe",
              type: "textfield",
              required: true,
            },
            {
              label: "Core Function",
              name: "coreFunction",
              type: "textfield",
              required: true,
            },
            {
              label: "Description",
              name: "coreFunctionDescription",
              type: "textfield",
              required: true,
            },
          ],
          "Gfebs Partner Wbs Elements": [
            {
              label: "Work Category Element",
              name: "workCategoryElement",
              type: "textfield",
              required: true,
            },
            {
              label: "Partner Wbs Element",
              name: "partnerWbsElement",
              type: "textfield",
              required: true,
            },
            {
              label: "Description",
              name: "partnerWbsElementDescription",
              type: "textfield",
              required: true,
            },
          ],
          "Organizations": [
            {
              label: "Name",
              name: "name",
              type: "textfield",
              required: true,
            },
            {
              label: "Long Name",
              name: "longName",
              type: "textfield",
              required: true,
            },
            {
              label: "Old Name",
              name: "oldName",
              type: "textfield",
              required: true,
            },
            {
              label: "UIC",
              name: "uic",
              type: "textfield",
              required: true,
            },
            {
              label: "Reference Number",
              name: "referenceNumber",
              type: "textfield",
              required: true,
            },
            {
              label: "Look Back Number",
              name: "historicLookbackNumber",
              type: "textfield",
            },
            {
              label: "Models Application",
              name: "modelsApplication",
              type: "textfield",
            },
            {
              label: "RFI Package",
              name: "rfiPackage",
              type: "textfield",
            },
          ],
          "Country Cocoms": [
            {
              label: "Country Code",
              name: "countryCode",
              type: "textfield",
              required: true,
            },
            {
              label: "COCOM",
              name: "cocom",
              type: "textfield",
              required: true,
            },
            {
              label: "COCOM Name",
              name: "cocomName",
              type: "textfield",
              required: true,
            },
            {
              label: "Country Name",
              name: "countryName",
              type: "textfield",
              required: true,
            },
          ],
          "Ric Records": [
            {
              label: "RIC",
              name: "ric",
              type: "textfield",
              required: true,
            },
            {
              label: "LCMC",
              name: "lcmc",
              type: "textfield",
              required: true,
            },
          ],
          "Preparing Activity Codes": [
            {
              label: "Preparing Activity Id",
              name: "preparingActivityId",
              type: "textfield",
              required: true,            
            },
            {
              label: "Activity Name",
              name: "activityName",
              type: "textfield",
              required: true,            
            },
            {
              label: "Activity Abbreviation Name",
              name: "activityAbbreviationName",
              type: "textfield",
              required: true,            
            },
            {
              label: "Activity Office Symbol Name",
              name: "activityOfficeSymbolName",
              type: "textfield",
              required: true,            
            },
            {
              label: "Activity Routing Code",
              name: "activityRoutingCode",
              type: "textfield",
              required: true,            
            },
            {
              label: "Organization",
              name: "organization",
              type: "textfield",
              required: true,
            },
          ],
          "Gfebs Cost Centers": [
            {
              label: "Cost Center",
              name: "costCenter",
              type: "textfield",
              required: true,            
            },
            {
              label: "Cost Center Description",
              name: "costCenterDescription",
              type: "textfield",
              required: true,            
            },
          ],
          "Gfebs Functional Areas": [
            {
              label: "Functional Area",
              name: "functionalArea",
              type: "textfield",
              required: true,            
            },
            {
              label: "Functional Area Description",
              name: "functionalAreaDescription",
              type: "textfield",
              required: true,            
            },
          ],
          "Program Element Core Functions": [
            {
              label: "Program Element",
              name: "programElement",
              type: "textfield",
              required: true,            
            },
            {
              label: "Core Function Number",
              name: "coreFunctionNumber",
              type: "textfield",
              required: true,            
            },
          ],
          "BPC Program Codes": [
            {
              label: "Program Codes",
              name: "programCodes",
              type: "textfield",
              required: true,            
            },
          ],
        };

        const updatedReqVarTemp = {
          "Hour Codes":
          [
            {
              label: "Hour Code",
              name: "hourCode",
              type: "textfield",
              required: true,
            },
            {
              label: "Description",
              name: "hourCodeDescription",
              type: "textarea",
              required: true,
            },
            {
              label: "Category",
              name: "hourCategory",
              type: "singleSelect",
              optionValues: ["Unproductive", "Productive", "Exclude"],
              required: true,
            },
            {
              label: "Type",
              name: "type",
              type: "singleSelect",
              optionValues: ["Leave", "Worked"],
              required: true,
            },
          ],
          "Gfebs Activity Id Pe Core Functions":
          [
            {
              label: "Work Category Element",
              name: "workCategoryElement",
              type: "textfield",
              required: true,
            },
            {
              label: "Funding",
              name: "funding",
              type: "textfield",
              required: true,
            },
            {
              label: "PE",
              name: "pe",
              type: "textfield",
              required: true,
            },
            {
              label: "Core Function",
              name: "coreFunction",
              type: "textfield",
              required: true,
            },
            {
              label: "Description",
              name: "coreFunctionDescription",
              type: "textfield",
              required: true,
            },
          ],
          "Gfebs Partner Wbs Elements": [
            {
              label: "Work Category Element",
              name: "workCategoryElement",
              type: "textfield",
              required: true,
            },
            {
              label: "Partner Wbs Element",
              name: "partnerWbsElement",
              type: "textfield",
              required: true,
            },
            {
              label: "Description",
              name: "partnerWbsElementDescription",
              type: "textfield",
              required: true,
            },
          ],
          "Gfebs Funds Centers": [
            {
              name: "fundsCenter",
              label: "Funds Center",
              type: "textfield",
              required: true,
            },
            {
              name: "fundsCenterDescription",
              label: "Description",
              type: "textfield",
              required: true,
            },
            {
              name: "organizationName",
              label: "Organization Name",
              type: "singleSelect",
              optionValues: uniqueNamesArr,
              required: true,
            },
          ],
          "Organizations": [
            {
              label: "Name",
              name: "name",
              type: "textfield",
              required: true,
            },
            {
              label: "Long Name",
              name: "longName",
              type: "textfield",
              required: true,
            },
            {
              label: "Old Name",
              name: "oldName",
              type: "textfield",
              required: true,
            },
            {
              label: "UIC",
              name: "uic",
              type: "textfield",
              required: true,
            },
            {
              label: "Reference Number",
              name: "referenceNumber",
              type: "textfield",
              required: true,
            },
            {
              label: "Look Back Number",
              name: "historicLookbackNumber",
              type: "textfield",
            },
            {
              label: "Models Application",
              name: "modelsApplication",
              type: "textfield",
            },
            {
              label: "RFI Package",
              name: "rfiPackage",
              type: "textfield",
            },
          ],
          "Country Cocoms": [
            {
              name: "countryCode",
              label: "Country Code",
              type: "textfield",
              required: true,
            },
            {
              name: "cocom",
              label: "COCOM",
              type: "textfield",
              required: true,
            },
            {
              name: "cocomName",
              label: "COCOM Name",
              type: "textfield",
              required: true,
            },
            {
              name: "countryName",
              label: "Country Name",
              type: "textfield",
              required: true,
            },

          ],
          "Ric Records": [
            {
              label: "RIC",
              name: "ric",
              type: "textfield",
              required: true,
            },
            {
              label: "LCMC",
              name: "lcmc",
              type: "textfield",
              required: true,
            },
          ],
          "Preparing Activity Codes": [
            {
              label: "Preparing Activity Id",
              name: "preparingActivityId",
              type: "textfield",
              required: true,            
            },
            {
              label: "Activity Name",
              name: "activityName",
              type: "textfield",
              required: true,            
            },
            {
              label: "Activity Abbreviation Name",
              name: "activityAbbreviationName",
              type: "textfield",
              required: true,            
            },
            {
              label: "Activity Office Symbol Name",
              name: "activityOfficeSymbolName",
              type: "textfield",
              required: true,            
            },
            {
              label: "Activity Routing Code",
              name: "activityRoutingCode",
              type: "textfield",
              required: true,            
            },
            {
              label: "Organization",
              name: "organization",
              type: "textfield",
              required: true,
            },
          ],
          "Gfebs Cost Centers": [
            {
              label: "Cost Center",
              name: "costCenter",
              type: "textfield",
              required: true,            
            },
            {
              label: "Cost Center Description",
              name: "costCenterDescription",
              type: "textfield",
              required: true,            
            },
          ],
          "Gfebs Functional Areas": [
            {
              label: "Functional Area",
              name: "functionalArea",
              type: "textfield",
              required: true,            
            },
            {
              label: "Functional Area Description",
              name: "functionalAreaDescription",
              type: "textfield",
              required: true,            
            },
          ],
          "Program Element Core Functions": [
            {
              label: "Program Element",
              name: "programElement",
              type: "textfield",
              required: true,            
            },
            {
              label: "Core Function Number",
              name: "coreFunctionNumber",
              type: "textfield",
              required: true,            
            },
          ],
          "BPC Program Codes": [
            {
              label: "Program Codes",
              name: "programCodes",
              type: "textfield",
              required: true,            
            },
          ],
        }

        setUpdatedColumnDefs(updatedColumnDefsTemp);
        setUpdatedInputDefs(updatedInputDefsTemp);
        setUpdatedReqVar(updatedReqVarTemp);

      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchNames();
  }, []);

  return {updatedColumnDefs, updatedInputDefs, updatedReqVar};
};

export default useReferenceUtils;


export const columnDefs = {
  GfebsHourCodes: [
    {
      field: "id",
      filterable: false,
    },
    {
      field: "hourCode",
      headerName: "Hour Code",
      flex: 1,
    },
    {
      field: "hourCodeDescription",
      headerName: "Description",
      flex: 1,
    },
    {
      field: "hourCategory",
      headerName: "Category",
      type: "singleSelect",
      valueOptions: ["Productive", "Unproductive", "Exclude"],
      flex: 1,
    },
    {
      field: "type",
      headerName: "Type",
      type: "singleSelect",
      valueOptions: ["Leave", "Worked"],
      flex: 1,
    },
  ],
  // WorkforceRecords: [
  //   {
  //     field: "id",
  //     headerName: "Id",
  //   },
  //   {
  //     field: "organization",
  //     headerName: "Organization",
  //     // valueGetter: (params) => {
  //     //   return params.value.name
  //     // },
  //     valueFormatter: function (params) {return params.value.name ;},
  //     flex: 1,
  //   },
  //   {
  //     field: "civilianFTEs",
  //     headerName: "Civ FTEs",
  //     flex: 1,
  //   },
  //   {
  //     field: "matrix",
  //     headerName: "Unaccounted for CIV (Matrix) FTEs",
  //     flex: 1,
  //   },
  // ],
  //
  // FiscalCycles: [
  //   {
  //     field: "id",
  //     headerName: "ID",
  //     flex: 1,
  //   },
  //   {
  //     field: "startDate",
  //     headerName: "Start Date",
  //     flex: 1,
  //   },
  //   {
  //     field: "endDate",
  //     headerName: "End Date",
  //     flex: 1,
  //   },
  //   {
  //     field: "fiscalYear",
  //     headerName: "Fiscal Year",
  //     flex: 1,
  //   },
  // ],
  //
  //
  GfebsActivityIdPeCoreFunctions: [
    {
      field: "id",
      headerName: "ID",
      flex: 1,
    },
    {
      field: "workCategoryElement",
      headerName: "Work Category Element",
      flex: 1,
    },
    {
      field: "funding",
      headerName: "Funding",
      flex: 1,
    },
    {
      field: "pe",
      headerName: "PE",
      flex: 1,
    },
    {
      field: "coreFunction",
      headerName: "Core Function",
      flex: 1,
    },
    {
      field: "coreFunctionDescription",
      headerName: "Description",
      flex: 1,
    },
  ],
  GfebsFundsCenters: [
    {
      field: "id",
      headerName: "ID",
      flex: 1,
    },
    {
      field: "fundsCenter",
      headerName: "Funds Center",
      flex: 1,
    },
    {
      field: "fundsCenterDescription",
      headerName: "Description",
      flex: 1,
    },
    {
      field: "organizationName",
      headerName: "Organization Name",
      type: "singleSelect",
      valueOptions: [],
      flex: 1,
    },
  ],
  //
  GfebsPartnerWbsElements: [
    {
      field: "id",
      headerName: "ID",
      flex: 1,
    },
    {
      field: "workCategoryElement",
      headerName: "Work Category Element",
      flex: 1,
    },
    {
      field: "partnerWbsElement",
      headerName: "Partner Wbs Element",
      flex: 1,
    },
    {
      field: "partnerWbsElementDescription",
      headerName: "Description",
      flex: 1,
    },
  ],
  Organizations: [
    {
      field: "id",
      headerName: "ID",
      flex: 1,
    },
    {
      field: "name",
      headerName: "Name",
      flex: 1,
    },
    {
      field: "longName",
      headerName: "Long Name",
      flex: 1,
    },
    {
      field: "oldName",
      headerName: "Old Name",
      flex: 1,
    },
    {
      field: "uic",
      headerName: "UIC",
      flex: 1,
    },
    {
      field: "referenceNumber",
      headerName: "Reference Number",
      flex: 1,
    },
    {
      field: "historicLookbackNumber",
      headerName: "Look Back Number",
      flex: 1,
    },
    {
      field: "modelsApplication",
      headerName: "Models Application",
      flex: 1,
    },
    {
      field: "rfiPackage",
      headerName: "RFI Package",
      flex: 1,
    },
  ],
  CountryCocoms: [
    {
      field: "id",
      headerName: "ID",
      flex: 1,
    },
    {
      field: "countryCode",
      headerName: "Country Code",
      flex: 1,
    },
    {
      field: "cocom",
      headerName: "COCOM",
      flex: 1,
    },
    {
      field: "cocomName",
      headerName: "COCOM Name",
      flex: 1,
    },
    {
      field: "countryName",
      headerName: "Country Name",
      flex: 1,
    },
  ],
  RicRecords: [
    {
      field: "id",
      headerName: "ID",
      flex: 1,
    },
    {
      field: "ric",
      headerName: "RIC",
      flex: 1,
    },
    {
      field: "lcmc",
      headerName: "LCMC",
      flex: 1,
    },
  ],
  DsamsPreparingActivityCodes: [
    {
      field: "id",
      headerName: "ID",
      flex: 1,
    },
    {
      field: "preparingActivityId",
      headerName: "Preparing Activity Id",
      flex: 1,
    },
    {
      field: "activityName",
      headerName: "Activity Name",
      flex: 1,
    },
    {
      field: "activityAbbreviationName",
      headerName: "Activity Abbreviation Name",
      flex: 1,
    },
    {
      field: "activityOfficeSymbolName",
      headerName: "Activity Office Symbol Name",
      flex: 1,
    },
    {
      field: "activityRoutingCode",
      headerName: "Activity Routing Code",
      flex: 1,
    },
    {
      field: "organization",
      headerName: "Organization",
      flex: 1,
    },
  ],
  GfebsCostCenters: [
    {
      field: "id",
      headerName: "ID",
      flex: 1,
    },
    {
      field: "costCenter",
      headerName: "Cost Center",
      flex: 1,
    },
    {
      field: "costCenterDescription",
      headerName: "Cost Center Description",
      flex: 1,
    },
  ],
  GfebsFunctionalAreas: [
    {
      field: "id",
      headerName: "ID",
      flex: 1,
    },
    {
      field: "functionalArea",
      headerName: "Functional Area",
      flex: 1,
    },
    {
      field: "functionalAreaDescription",
      headerName: "Functional Area Description",
      flex: 1,
    },
  ],
  ProgramElementCoreFunctions: [
    {
      field: "id",
      headerName: "ID",
      flex: 1,
    },
    {
      field: "programElement",
      headerName: "Program Element",
      flex: 1,
    },
    {
      field: "coreFunctionNumber",
      headerName: "Core Function Number",
      flex: 1,
    },
  ],
  BpcProgramCodes: [
    {
      field: "id",
      headerName: "ID",
    },
    {
      field: "programCodes",
      headerName: "Program Codes",
      flex: 1,
    },
  ],
}

export const inputDefs = {
  "Hour Codes": [
    {
      label: "Hour Code",
      name: "hourCode",
      type: "textfield",
      required: true,
    },
    {
      label: "Description",
      name: "hourCodeDescription",
      type: "textarea",
      required: true,
    },
    {
      label: "Category",
      name: "hourCategory",
      type: "singleSelect",
      optionValues: ["Unproductive", "Productive", "Exclude"],
      required: true,
    },
    {
      label: "Type",
      name: "type",
      type: "singleSelect",
      optionValues: ["Leave", "Worked"],
      required: true,
    },
  ],
  // "Workforce Records": [
  //   {
  //     label: "Organization",
  //     name: "organization",
  //     type: "textfield",
  //     required: true,
  //   },
  //   {
  //     label: "Civ FTEs",
  //     name: "civilianFTEs",
  //     type: "number",
  //     required: true,
  //   },
  //   {
  //     label: "Unaccounted for CIV (Matrix) FTEs",
  //     name: "matrix",
  //     type: "number",
  //     required: true,
  //   },
  // ],
  // "Fiscal Cycles": [
  //   {
  //     label: "Start Date",
  //     name: "startDate",
  //     type: "textfield",
  //     required: true,
  //   },
  //   {
  //     label: "End Date",
  //     name: "endDate",
  //     type: "textfield",
  //     required: true,
  //   },
  //   {
  //     label: "Fiscal Year",
  //     name: "fiscalYear",
  //     type: "textfield",
  //     required: true,
  //   },
  // ],
  "Gfebs Funds Centers": [
    {
      name: "fundsCenter",
      label: "Funds Center",
      type: "textfield",
      required: true,
    },
    {
      name: "fundsCenterDescription",
      label: "Description",
      type: "textfield",
      required: true,
    },
    {
      name: "organizationName",
      label: "Organization Name",
      // type: "textfield",
      type: "singleSelect",
      optionValues: [],
      required: true,
    },
  ],
  "Gfebs Activity Id Pe Core Functions": [
    {
      label: "Work Category Element",
      name: "workCategoryElement",
      type: "textfield",
      required: true,
    },
    {
      label: "Funding",
      name: "funding",
      type: "textfield",
      required: true,
    },
    {
      label: "PE",
      name: "pe",
      type: "textfield",
      required: true,
    },
    {
      label: "Core Function",
      name: "coreFunction",
      type: "textfield",
      required: true,
    },
    {
      label: "Description",
      name: "coreFunctionDescription",
      type: "textfield",
      required: true,
    },
  ],
  "Gfebs Partner Wbs Elements": [
    {
      label: "Work Category Element",
      name: "workCategoryElement",
      type: "textfield",
      required: true,
    },
    {
      label: "Partner Wbs Element",
      name: "partnerWbsElement",
      type: "textfield",
      required: true,
    },
    {
      label: "Description",
      name: "partnerWbsElementDescription",
      type: "textfield",
      required: true,
    },
  ],
  "Organizations": [
    {
      label: "Name",
      name: "name",
      type: "textfield",
      required: true,
    },
    {
      label: "Long Name",
      name: "longName",
      type: "textfield",
      required: true,
    },
    {
      label: "Old Name",
      name: "oldName",
      type: "textfield",
      required: true,
    },
    {
      label: "UIC",
      name: "uic",
      type: "textfield",
      required: true,
    },
    {
      label: "Reference Number",
      name: "referenceNumber",
      type: "textfield",
      required: true,
    },
    {
      label: "Look Back Number",
      name: "historicLookbackNumber",
      type: "textfield",
    },
    {
      label: "Models Application",
      name: "modelsApplication",
      type: "textfield",
    },
    {
      label: "RFI Package",
      name: "rfiPackage",
      type: "textfield",
    },
  ],
  "Country Cocoms": [
    {
      label: "Country Code",
      name: "countryCode",
      type: "textfield",
      required: true,
    },
    {
      label: "COCOM",
      name: "cocom",
      type: "textfield",
      required: true,
    },
    {
      label: "COCOM Name",
      name: "cocomName",
      type: "textfield",
      required: true,
    },
    {
      label: "Country Name",
      name: "countryName",
      type: "textfield",
      required: true,
    },
  ],
  "Ric Records": [
    {
      label: "RIC",
      name: "ric",
      type: "textfield",
      required: true,
    },
    {
      label: "LCMC",
      name: "lcmc",
      type: "textfield",
      required: true,
    },
  ],
  "Preparing Activity Codes": [
    {
      label: "Preparing Activity Id",
      name: "preparingActivityId",
      type: "textfield",
      required: true,            
    },
    {
      label: "Activity Name",
      name: "activityName",
      type: "textfield",
      required: true,            
    },
    {
      label: "Activity Abbreviation Name",
      name: "activityAbbreviationName",
      type: "textfield",
      required: true,            
    },
    {
      label: "Activity Office Symbol Name",
      name: "activityOfficeSymbolName",
      type: "textfield",
      required: true,            
    },
    {
      label: "Activity Routing Code",
      name: "activityRoutingCode",
      type: "textfield",
      required: true,            
    },
    {
      label: "Organization",
      name: "organization",
      type: "textfield",
      required: true,
    },
  ],
  "Gfebs Cost Centers": [
    {
      label: "Cost Center",
      name: "costCenter",
      type: "textfield",
      required: true,            
    },
    {
      label: "Cost Center Description",
      name: "costCenterDescription",
      type: "textfield",
      required: true,            
    },
  ],
  "Gfebs Functional Areas": [
    {
      label: "Functional Area",
      name: "functionalArea",
      type: "textfield",
      required: true,            
    },
    {
      label: "Functional Area Description",
      name: "functionalAreaDescription",
      type: "textfield",
      required: true,            
    },
  ],
  "Program Element Core Functions": [
    {
      label: "Program Element",
      name: "programElement",
      type: "textfield",
      required: true,            
    },
    {
      label: "Core Function Number",
      name: "coreFunctionNumber",
      type: "textfield",
      required: true,            
    },
  ],
  "BPC Program Codes": [
    {
      label: "Program Codes",
      name: "programCodes",
      type: "textfield",
      required: true,            
    },
  ],
}

export const reqVar = {
  "Hour Codes":
  [
    {
      label: "Hour Code",
      name: "hourCode",
      type: "textfield",
      required: true,
    },
    {
      label: "Description",
      name: "hourCodeDescription",
      type: "textarea",
      required: true,
    },
    {
      label: "Category",
      name: "hourCategory",
      type: "singleSelect",
      optionValues: ["Unproductive", "Productive", "Exclude"],
      required: true,
    },
    {
      label: "Type",
      name: "type",
      type: "singleSelect",
      optionValues: ["Leave", "Worked"],
      required: true,
    },
  ],
  // "Workforce Records": 
  // [
  //   {
  //     label: "Organization",
  //     name: "organization",
  //     type: "textarea",
  //     required: true,
  //   },
  //   {
  //     label: "Civ FTEs",
  //     name: "civilianFTEs",
  //     type: "number",
  //     required: true,
  //   },
  //   {
  //     label: "Unaccounted for CIV (Matrix) FTEs",
  //     name: "matrix",
  //     type: "number",
  //     required: true,
  //   },
  // ],
  // "Fiscal Cycles": 
  // [
  //   {
  //     label: "Start Date",
  //     name: "startDate",
  //     type: "textArea",
  //     required: true,
  //   },
  //   {
  //     label: "End Date",
  //     name: "endDate",
  //     type: "textArea",
  //     required: true,
  //   },
  //   {
  //     label: "Fiscal Year",
  //     name: "fiscalYear",
  //     type: "textArea",
  //     required: true,
  //   },
  // ],
  "Gfebs Activity Id Pe Core Functions":
  [
    {
      label: "Work Category Element",
      name: "workCategoryElement",
      type: "textfield",
      required: true,
    },
    {
      label: "Funding",
      name: "funding",
      type: "textfield",
      required: true,
    },
    {
      label: "PE",
      name: "pe",
      type: "textfield",
      required: true,
    },
    {
      label: "Core Function",
      name: "coreFunction",
      type: "textfield",
      required: true,
    },
    {
      label: "Description",
      name: "coreFunctionDescription",
      type: "textfield",
      required: true,
    },
  ],
  "Gfebs Partner Wbs Elements": [
    {
      label: "Work Category Element",
      name: "workCategoryElement",
      type: "textfield",
      required: true,
    },
    {
      label: "Partner Wbs Element",
      name: "partnerWbsElement",
      type: "textfield",
      required: true,
    },
    {
      label: "Description",
      name: "partnerWbsElementDescription",
      type: "textfield",
      required: true,
    },
  ],
  "Gfebs Funds Centers": [
    {
      name: "fundsCenter",
      label: "Funds Center",
      type: "textfield",
      required: true,
    },
    {
      name: "fundsCenterDescription",
      label: "Description",
      type: "textfield",
      required: true,
    },
    {
      name: "organizationName",
      label: "Organization Name",
      // type: "textfield",
      type: "singleSelect",
      optionValues: [],
      required: true,
    },
  ],
  "Organizations": [
    {
      label: "Name",
      name: "name",
      type: "textfield",
      required: true,
    },
    {
      label: "Long Name",
      name: "longName",
      type: "textfield",
      required: true,
    },
    {
      label: "Old Name",
      name: "oldName",
      type: "textfield",
      required: true,
    },
    {
      label: "UIC",
      name: "uic",
      type: "textfield",
      required: true,
    },
    {
      label: "Reference Number",
      name: "referenceNumber",
      type: "textfield",
      required: true,
    },
    {
      label: "Look Back Number",
      name: "historicLookbackNumber",
      type: "textfield",
    },
    {
      label: "Models Application",
      name: "modelsApplication",
      type: "textfield",
    },
    {
      label: "RFI Package",
      name: "rfiPackage",
      type: "textfield",
    },
  ],
  "Country Cocoms": [
    {
      name: "countryCode",
      label: "Country Code",
      type: "textfield",
      required: true,
    },
    {
      name: "cocom",
      label: "COCOM",
      type: "textfield",
      required: true,
    },
    {
      name: "cocomName",
      label: "COCOM Name",
      type: "textfield",
      required: true,
    },
    {
      name: "countryName",
      label: "Country Name",
      type: "textfield",
      required: true,
    },
  ],
  "Ric Records": [
    {
      label: "RIC",
      name: "ric",
      type: "textfield",
      required: true,
    },
    {
      label: "LCMC",
      name: "lcmc",
      type: "textfield",
      required: true,
    },
  ],
  "Preparing Activity Codes": [
    {
      label: "Preparing Activity Id",
      name: "preparingActivityId",
      type: "textfield",
      required: true,            
    },
    {
      label: "Activity Name",
      name: "activityName",
      type: "textfield",
      required: true,            
    },
    {
      label: "Activity Abbreviation Name",
      name: "activityAbbreviationName",
      type: "textfield",
      required: true,            
    },
    {
      label: "Activity Office Symbol Name",
      name: "activityOfficeSymbolName",
      type: "textfield",
      required: true,            
    },
    {
      label: "Activity Routing Code",
      name: "activityRoutingCode",
      type: "textfield",
      required: true,            
    },
    {
      label: "Organization",
      name: "organization",
      type: "textfield",
      required: true,
    },
  ],
  "Gfebs Cost Centers": [
    {
      label: "Cost Center",
      name: "costCenter",
      type: "textfield",
      required: true,            
    },
    {
      label: "Cost Center Description",
      name: "costCenterDescription",
      type: "textfield",
      required: true,            
    },
  ],
  "Gfebs Functional Areas": [
    {
      label: "Functional Area",
      name: "functionalArea",
      type: "textfield",
      required: true,            
    },
    {
      label: "Functional Area Description",
      name: "functionalAreaDescription",
      type: "textfield",
      required: true,            
    },
  ],
  "Program Element Core Functions": [
    {
      label: "Program Element",
      name: "programElement",
      type: "textfield",
      required: true,            
    },
    {
      label: "Core Function Number",
      name: "coreFunctionNumber",
      type: "textfield",
      required: true,            
    },
  ],
  "BPC Program Codes": [
    {
      label: "Program Codes",
      name: "programCodes",
      type: "textfield",
      required: true,            
    },
  ],
}
