import { useContext } from "react"

// Context
import AppContext from "./AppContext"

// Libraries
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom"

// Custom
import ACC from "./components/RFIs/ACC/ACC"
import ApiAuthorizationRoutes from "./components/api-authorization/ApiAuthorizationRoutes"
import { ApplicationPaths } from "./components/api-authorization/ApiAuthorizationConstants"
import AuthorizeRoute from "./components/api-authorization/AuthorizeRoute"
import DSAMS_RFI from "./components/RFIs/DSAMS-CISIL/DSAMS_RFI"
import FiscalCycles from "./components/Admin/FiscalCycles/FiscalCycles"
import GFEBS_CEFMS from "./components/RFIs/GFEBS-CEFMS/GFEBS_CEFMS"
import ManHourWeightedLinesModelView from "./components/Admin/MHWLModels/ManHourWeightedLinesModelView"
import OrganizationPOCs from "./components/Organizations/OrganizationPOCs"
import PageNotFound from "./components/PageNotFound"
import PEO_PM from "./components/RFIs/PEO-PM/PEO_PM"
import RFIValidationDashboardMenu from "./components/RFIValidationDashboardMenu"
import SATFA from "./components/RFIs/SATFA/SATFA"
import SAPBR from "./components/Admin/SAPBR/SAPBR"
import SupplementalWorkforce from "./components/RFIs/Supplemental-Workforce/SupplementalWorkforce"
import TDA from "./components/RFIs/TDA/TDA"
import Users from "./components/Admin/Users/Users"

//Styles
import LoadingView from "./components/loading/LoadingView"
import ReferenceTableView from "./reference-tables/ReferenceTableView"
import NewLandingPage from "./NewLandingPage/NewLandingPage"
import ClaimantDashboard from "./views/ClaimantDashboard"
import CustomDrawer from "./components/elements/layout/CustomDrawer"

const AppRoutes = ({ loading }) => {
  const context = useContext(AppContext)
  const { classificationData, clickTarget, selectedFY } = context
  let user = sessionStorage.getItem("currentUser")

  return (
    <BrowserRouter basename={process.env.PUBLIC_URL}>
      {/* <Route render={({ location }) => location.pathname !== "/cwat-web" && <DashboardMenu clickTarget={clickTarget} />} /> */}
      {/* <Route
        path="/landing-test"
        component={LandingPageNEW}
      /> */}
      {loading && <LoadingView />}
      {!loading && (
        <Switch>
          {/* Dashboard Redirect Route */}
          {/* Dashboard Redirect Route */}
          <Route
            exact
            path="/authentication/login-callback"
            render={() => <Redirect to="/dashboard" />}
          />
          <Route
            exact
            path="/"
            render={() => <Redirect to="/landingPage" />}
          />

          <AuthorizeRoute
            exact
            path="/drawer"
            element={<CustomDrawer />}
          />

          <AuthorizeRoute
            exact
            path="/dashboard"
            element={<ClaimantDashboard />}
          />
          {/* Reference Table View */}
          {/* TODO: fix CSS */}
          <AuthorizeRoute
            exact
            path="/reference"
            element={<ReferenceTableView />}
          />
          {/* Organization Routes */}
          <AuthorizeRoute
            exact
            path="/organization-POCs"
            element={<OrganizationPOCs />}
          />

          {/* New Landing Page */}
          <AuthorizeRoute
            exact
            path="/landingPage"
            element={<NewLandingPage />}
          />
          {/* RFI Views */}
          <AuthorizeRoute
            exact
            path="/DSAMS-CISIL-RFIs"
            element={<DSAMS_RFI />}
          />
          <AuthorizeRoute
            exact
            path="/GFEBS-CEFMS-RFIs"
            element={<GFEBS_CEFMS />}
          />
          <AuthorizeRoute
            exact
            path="/ACC-RFIs"
            element={<ACC />}
          />
          <AuthorizeRoute
            exact
            path="/SupplementalWorkforce-RFIs"
            element={<SupplementalWorkforce />}
          />
          <AuthorizeRoute
            exact
            path="/SATFA-RFIs"
            element={<SATFA />}
          />
          <AuthorizeRoute
            exact
            path="/USACE-RFIs"
            element={<PEO_PM usace />}
          />
          <AuthorizeRoute
            exact
            path="/PEO-PM-RFIs"
            element={<PEO_PM />}
          />
          <AuthorizeRoute
            exact
            path="/SATMO-RFIs"
            element={<PEO_PM satmo />}
          />
          <AuthorizeRoute
            exact
            path="/TDA-RFIs"
            element={<TDA />}
          />
          {/* Admin Routes */}
          <AuthorizeRoute
            exact
            path="/users"
            element={<Users />}
          />
          <AuthorizeRoute
            exact
            path="/SAPBR"
            element={<SAPBR />}
          />
          <AuthorizeRoute
            exact
            path="/FiscalCycles"
            element={<FiscalCycles />}
          />
          <AuthorizeRoute
            exact
            path="/Dashboards"
            element={<RFIValidationDashboardMenu />}
          />
          <AuthorizeRoute
            exact
            path="/Models"
            element={<ManHourWeightedLinesModelView />}
          />
          {/* Page Not Found */}
          <Route
            path="/*"
            element={<PageNotFound />}
          />
        </Switch>
      )}
      {/* <Banner
        data={classificationData}
        position={{ bottom: "0px" }}
      /> */}
      {/* <div
        className="cui-bottom"
        style={{ backgroundColor: classificationData.classificationColor }}
      >
        <strong>{classificationData.classificationMarking}</strong>
      </div> */}
      {/* <Banner
        data={classificationData}
        position={{ position: "fixed", bottom: "0" }}
      /> */}
    </BrowserRouter>
  )
}

export default AppRoutes
