import { useContext, useEffect, useState } from "react"

// Libraries
import { Box, Button, MenuItem, TextField } from "@mui/material"
import { CSVLink } from "react-csv"

// Custom
import { CWATColors } from "../../Utils"

// Styles
import "./RFI.css"
import AppContext from "../../AppContext"

const RFIGridToolbar = ({
  csvLinkRef,
  csvData,
  selectedTab,
  selectedOrgName,
  headers,
  dataHeaders,
  handleExportRFIData,
  handleFilterRFIData,
  showingWarningErrorUpload,
  toggleWarningsErrors,
  setToggleWarningsErrors,
  warningsOnly,
  canExportRFIData,
}) => {
  const context = useContext(AppContext)
  const { activeFiscalCycle } = context

  const operators = ["equals", "contains"]
  const [selectedTableField, setSelectedTableField] = useState("")
  const [selectedDataField, setSelectedDataField] = useState("")
  const [selectedOperator, setSelectedOperator] = useState(operators[0])
  const [value, setValue] = useState("")

  useEffect(() => {
    handleClearFilter()
  }, [selectedTab, activeFiscalCycle])

  const handleChange = (e, type) => {
    switch (type) {
      case "field":
        setSelectedTableField(e.target.value)
        if (!dataHeaders) {
          setSelectedDataField(e.target.value)
        } else {
          const idx = headers.findIndex((header) => header === e.target.value)
          setSelectedDataField(dataHeaders[idx])
        }
        break
      case "operator":
        setSelectedOperator(e.target.value)
        break
      case "value":
        setValue(e.target.value.replace("&", "_AND_"))
        break
      case "toggleWarningsErrors":
        setToggleWarningsErrors(!toggleWarningsErrors)
        break
      default:
        break
    }
  }

  const canFilter = () => {
    return selectedTableField.length > 0 && value.toString().length > 0
  }

  const handleClearFilter = () => {
    setSelectedTableField("")
    setSelectedDataField("")
    setSelectedOperator(operators[0])
    setValue("")
  }

  return (
    <Box style={{ display: "flex", alignItems: "center", width: "50%", maxWidth: "50%", flexWrap: "nowrap" }}>
      <div>
        <Button
          variant="contained"
          onClick={handleExportRFIData}
          disabled={!canExportRFIData}
          sx={{ height: "38px", marginRight: "5px", minWidth: "140px" }}
        >
          EXPORT
        </Button>
        {/* Add back CSV data link for PEO PM Export */}
        {csvData && (
          <CSVLink
            ref={csvLinkRef}
            data={csvData}
            headers={headers}
            filename={`PEOPMData_${selectedOrgName}.csv`}
            className="--RFI-csv-export-link"
            hidden={true}
          />
        )}
      </div>
      <TextField
        label="Field"
        value={selectedTableField}
        onChange={(e) => handleChange(e, "field")}
        InputLabelProps={{ shrink: true }}
        variant="outlined"
        style={{ width: "200px", marginRight: "5px", fontSize: ".8rem" }}
        size="small"
        select
      >
        {headers
          .filter((header) => header !== "Remove")
          .map((field, idx) => (
            <MenuItem
              key={idx}
              value={field}
            >
              {field}
            </MenuItem>
          ))}
      </TextField>
      <TextField
        label="Operator"
        style={{ width: "125px", marginRight: "5px", fontSize: ".8rem" }}
        select
        variant="outlined"
        value={selectedOperator}
        InputLabelProps={{ shrink: true }}
        onChange={(e) => handleChange(e, "operator")}
        size="small"
      >
        {operators.map((op, idx) => (
          <MenuItem
            key={idx}
            value={op}
          >
            {op}
          </MenuItem>
        ))}
      </TextField>
      <TextField
        label="Value"
        style={{ width: "150px", marginRight: "5px", fontSize: ".8rem" }}
        variant="outlined"
        InputLabelProps={{ shrink: true }}
        onChange={(e) => handleChange(e, "value")}
        value={value}
        size="small"
      />
      <Button
        variant="peopm"
        disabled={!canFilter()}
        sx={{ marginRight: "5px", height: "38px" }}
        onClick={() => handleFilterRFIData(selectedTableField, selectedDataField, selectedOperator, value)}
      >
        FILTER
      </Button>
      <Button
        variant="outlined"
        color="primary"
        disabled={!canFilter()}
        onClick={() => {
          handleClearFilter()
          handleFilterRFIData("", "", operators[0], "")
        }}
        sx={{ height: "38px" }}
      >
        CLEAR
      </Button>
      <div>
        <Button
          hidden={!showingWarningErrorUpload}
          className="--RFI-csv-clear-filter"
          style={{
            width: warningsOnly ? "225px" : "300px",
            backgroundColor: toggleWarningsErrors ? CWATColors.PRIMARY : warningsOnly ? CWATColors.WARNING : CWATColors.ERROR,
          }}
          onClick={(e) => handleChange(e, "toggleWarningsErrors")}
        >
          {toggleWarningsErrors
            ? warningsOnly
              ? "UNFILTER WARNINGS"
              : "UNFILTER ERRORS/WARNINGS"
            : warningsOnly
            ? "FILTER FOR WARNINGS"
            : "FILTER FOR ERRORS/WARNINGS"}
        </Button>
      </div>
    </Box>
  )
}

export default RFIGridToolbar
