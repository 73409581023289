/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useContext, useRef } from "react"

// Libraries
import { v4 as uuidv4 } from "uuid"

// Data
import { apiCalls } from "../../DataService"

// Custom
import SnackbarMessages from "../../SnackbarMessages"
import UploadProgressModal from "../../RFIs/UploadProgressModal"
import MHWLBtns from "./MHWLBtns"
import MHWLGrid from "./MHWLGrid"
import CustomCard from "../../custom/CustomCard"
import { TableControls } from "../../RFIs/TableControls"

// Vars
import { getFYOffset } from "../../../Utils"
import MHWLUtils from "./MHWLUtils"

import AppContext from "../../../AppContext"

const ManHourWeightedLinesModelView = () => {
  // Initialize App-level Data
  const context = useContext(AppContext)
  const { filterOrganizations, allOrganizations, activeFiscalCycle } = context

  // For now, going to make each page responsible for providing what it supports but there is a mapping in utils (RFIViewOrgMappings)
  useEffect(() => {
    filterOrganizations(["DASA DE&C"])
  }, [])

  const pageNumber = useRef(0)

  // ** --------------***     [ ~ HOOKS ~ ]     ***-------------- **
  const [loading, setLoading] = useState(false)
  const [loadingMsg, setLoadingMsg] = useState("")
  const [gridCols, setGridCols] = useState("")
  const [gridRows, setGridRows] = useState([])
  const [selectedGridRows, setSelectedGridRows] = useState([])
  const [snackbarMessage, setSnackbarMessage] = useState("")
  const [showSnackbarSuccess, setShowSnackbarSuccess] = useState(false)
  const [showSnackbarError, setShowSnackbarError] = useState(false)

  // ** --------------***     [ ~ VARS ~ ]     ***-------------- **

  const fyGridCols = {
    ORGANIZATION: "Organization",
    CORE_FUNCTION: "Front Term",
    MH_FY: "",
    MH_FY_1: "",
    MH_FY_2: "",
    WL_FY: "",
    WL_FY_1: "",
    WL_FY_2: "",
    ABM: "ABM",
  }

  // ** --------------***     [ ~ EFFECTS ~ ]     ***-------------- **
  useEffect(() => {
    initPage()
  }, [activeFiscalCycle])

  // ** --------------***     [ ~ FUNCTIONS ~ ]     ***-------------- **
  const initPage = () => {
    if (allOrganizations.length > 0 && activeFiscalCycle?.id) {
      getGridCols()
      getGridRows()
    }
    pageNumber.current = 0
  }

  const getGridCols = () => {
    setFYCols()
    let cols = [
      {
        field: "Organization",
        headerName: "Organization",
        flex: 1,
        disableColumnMenu: true,
        filterable: false,
        headerClassName: "mhwl-header",
      },
      {
        field: "Front Term",
        headerName: "Front Term",
        flex: 2,
        disableColumnMenu: true,
        filterable: false,
        headerClassName: "mhwl-header",
      },
      {
        field: MHWLUtils.gridFields.MH_FY,
        headerName: fyGridCols.MH_FY,
        flex: 1,
        disableColumnMenu: true,
        filterable: false,
        headerClassName: "mhwl-header",
      },
      {
        field: MHWLUtils.gridFields.MH_FY_1,
        headerName: fyGridCols.MH_FY_1,
        flex: 1,
        disableColumnMenu: true,
        filterable: false,
        headerClassName: "mhwl-header",
      },
      {
        field: MHWLUtils.gridFields.MH_FY_2,
        headerName: fyGridCols.MH_FY_2,
        flex: 1,
        disableColumnMenu: true,
        filterable: false,
        headerClassName: "mhwl-header",
      },
      {
        field: MHWLUtils.gridFields.WL_FY,
        headerName: fyGridCols.WL_FY,
        flex: 1,
        disableColumnMenu: true,
        filterable: false,
        headerClassName: "mhwl-header",
      },
      {
        field: MHWLUtils.gridFields.WL_FY_1,
        headerName: fyGridCols.WL_FY_1,
        flex: 1,
        disableColumnMenu: true,
        filterable: false,
        headerClassName: "mhwl-header",
      },
      {
        field: MHWLUtils.gridFields.WL_FY_2,
        headerName: fyGridCols.WL_FY_2,
        flex: 1,
        disableColumnMenu: true,
        filterable: false,
        headerClassName: "mhwl-header",
      },
      {
        field: "ABM",
        headerName: "ABM",
        flex: 1,
        disableColumnMenu: true,
        filterable: false,
        headerClassName: "mhwl-header",
      },
    ]
    setGridCols(cols)
  }

  const setFYCols = () => {
    fyGridCols.MH_FY = MHWLUtils.gridFields.MH_FY.replace("FY(XX)", getFYOffset(activeFiscalCycle.fiscalYear, 2))
    fyGridCols.MH_FY_1 = MHWLUtils.gridFields.MH_FY_1.replace("FY(XX + 1)", getFYOffset(activeFiscalCycle.fiscalYear, 3))
    fyGridCols.MH_FY_2 = MHWLUtils.gridFields.MH_FY_2.replace("FY(XX + 2)", getFYOffset(activeFiscalCycle.fiscalYear, 4))
    fyGridCols.WL_FY = MHWLUtils.gridFields.WL_FY.replace("FY(XX)", getFYOffset(activeFiscalCycle.fiscalYear, 2))
    fyGridCols.WL_FY_1 = MHWLUtils.gridFields.WL_FY_1.replace("FY(XX + 1)", getFYOffset(activeFiscalCycle.fiscalYear, 3))
    fyGridCols.WL_FY_2 = MHWLUtils.gridFields.WL_FY_2.replace("FY(XX + 2)", getFYOffset(activeFiscalCycle.fiscalYear, 4))
  }

  const getGridRows = () => {
    let rows = []
    const orgsSorted = allOrganizations.sort((a, b) => a.name.localeCompare(b.name))

    orgsSorted.forEach((org) => {
      MHWLUtils.allCoreFunctions.forEach((item) => {
        rows.push({
          Id: uuidv4(),
          [MHWLUtils.gridFields.ORGANIZATION]: org.name,
          [MHWLUtils.gridFields.CORE_FUNCTION]: item,
          [MHWLUtils.gridFields.MH_FY]: null,
          [MHWLUtils.gridFields.MH_FY_1]: null,
          [MHWLUtils.gridFields.MH_FY_2]: null,
          [MHWLUtils.gridFields.WL_FY]: null,
          [MHWLUtils.gridFields.WL_FY_1]: null,
          [MHWLUtils.gridFields.WL_FY_2]: null,
          [MHWLUtils.gridFields.ABM]: null,
        })
      })
    })
    setGridRows(rows)
    setSelectedGridRows(rows.slice(0, 100))
    pageNumber.current = 0
  }

  const fetchData = async () => {
    setLoading(true)
    setLoadingMsg("Please wait- running all models. This may take a few minutes.")

    // ** --------------***     [ ~ TESTING ~ ]     ***-------------- **
    // Step 1: start the fetch and obtain a reader
    // let response = await fetch("https://localhost:5001/api/BusinessModels")

    // const reader = response.body.getReader()

    // Step 2: get total length
    // const contentLength = +response.headers.get("Content-Length")

    // Step 3: read the data
    // let receivedLength = 0 // received that many bytes at the moment
    // let chunks = [] // array of received binary chunks (comprises the body)
    // while (true) {
    //   const { done, value } = await reader.read()

    //   if (done) {
    //     break
    //   }

    //   chunks.push(value)
    //   receivedLength += value.length

    //   console.log(`Received ${receivedLength} of ${contentLength}`)
    // }

    // Step 4: concatenate chunks into single Uint8Array
    // let chunksAll = new Uint8Array(receivedLength) // (4.1)
    // let position = 0
    // for (let chunk of chunks) {
    //   chunksAll.set(chunk, position) // (4.2)
    //   position += chunk.length
    // }

    // Step 5: decode into a string
    // let result = new TextDecoder("utf-8").decode(chunksAll)

    // We're done!
    // let commits = JSON.parse(result)
    // console.log("commits: ", commits)

    // alert(commits[0].author.login)

    // ** --------------***     [ ~ END TESTING ~ ]     ***-------------- **

    let mhFetch = apiCalls.post("BusinessModels", null, null)
    let wlFetch = apiCalls.getAll("BusinessModels", null, null)
    let abmFetch = apiCalls.getAll("AgentBasedModel")

    let [mhResponse, wlResponse, abmResponse] = await Promise.all([mhFetch, wlFetch, abmFetch])

    let reduced = abmResponse.reduce(
      (acc, currValue) => ({
        ...acc,
        [currValue.organization]: currValue.fullTimeEquivalent,
      }),
      {}
    )

    let tmpMHWLTableRows = gridRows
    let wlRows = gridRows

    // WL
    for (const orgs of wlResponse) {
      wlRows = wlRows.map((row) => {
        for (const orgCF of orgs) {
          const coreFunction = orgCF?.coreFunction?.replace(/\s/g, "")
          const orgName = orgCF.organization
          if (row[MHWLUtils.gridFields.CORE_FUNCTION]?.includes(coreFunction) && row[MHWLUtils.gridFields.ORGANIZATION] === orgName) {
            if (coreFunction.length > 0 || row[MHWLUtils.gridFields.CORE_FUNCTION] === "Civilian includes MTX") {
              return {
                ...row,
                [MHWLUtils.gridFields.WL_FY]: orgCF?.fiscalYearOne,
                [MHWLUtils.gridFields.WL_FY_1]: orgCF?.fiscalYearTwo,
                [MHWLUtils.gridFields.WL_FY_2]: orgCF?.fiscalYearThree,
              }
            }
          }
        }
        return row
      })
    }

    // MH
    for (const orgArr of mhResponse.data) {
      tmpMHWLTableRows = tmpMHWLTableRows.map((row) => {
        for (const orgCF of orgArr) {
          const coreFunction = orgCF?.coreFunction?.replace(/\s/g, "")
          const orgName = orgCF.organization
          if (row[MHWLUtils.gridFields.CORE_FUNCTION]?.includes(coreFunction) && row[MHWLUtils.gridFields.ORGANIZATION] === orgName) {
            if (coreFunction.length > 0 || row[MHWLUtils.gridFields.CORE_FUNCTION] === "Civilian includes MTX") {
              if (row[MHWLUtils.gridFields.CORE_FUNCTION] === "Civilian includes MTX") {
                return {
                  ...row,
                  ABM: reduced[row.Organization],
                  [MHWLUtils.gridFields.MH_FY]: orgCF?.fiscalYearOne,
                  [MHWLUtils.gridFields.MH_FY_1]: orgCF?.fiscalYearTwo,
                  [MHWLUtils.gridFields.MH_FY_2]: orgCF?.fiscalYearThree,
                }
              }
              return {
                ...row,
                [MHWLUtils.gridFields.MH_FY]: orgCF?.fiscalYearOne,
                [MHWLUtils.gridFields.MH_FY_1]: orgCF?.fiscalYearTwo,
                [MHWLUtils.gridFields.MH_FY_2]: orgCF?.fiscalYearThree,
              }
            }
          }
        }
        return row
      })
    }

    let allRows = wlRows.map((item, index) => {
      let mhItem = tmpMHWLTableRows[index]
      return {
        ...mhItem,
        "WL FY(XX + 1)": item["WL FY(XX + 1)"],
        "WL FY(XX + 2)": item["WL FY(XX + 2)"],
        "WL FY(XX)": item["WL FY(XX)"],
      }
    })

    setGridRows(() => allRows)
    pageNumber.current = 0
    setSelectedGridRows(() => allRows.slice(0, 100))
    setShowSnackbarSuccess(true)
    setSnackbarMessage("Successfully executed Man Hours Models!")
    setLoading(false)

    // apiCalls
    //   .post(`BusinessModels`, null, null)
    //   .then((res) => {
    //     const manHoursData = res?.data
    //     if (manHoursData) {
    //       let tmpMHWLTableRows = MHWLTableRows
    //       for (const orgArr of manHoursData) {
    //         tmpMHWLTableRows = tmpMHWLTableRows.map((row) => {
    //           for (const orgCF of orgArr) {
    //             const coreFunction = orgCF?.coreFunction?.replace(/\s/g, "")
    //             const orgName = orgCF.organization
    //             if (row[MHWLUtils.MHWLTableFields.CORE_FUNCTION].includes(coreFunction) && row[MHWLUtils.MHWLTableFields.ORGANIZATION] === orgName) {
    //               if (coreFunction.length > 0 || row[MHWLUtils.MHWLTableFields.CORE_FUNCTION] === "Civilian includes MTX") {
    //                 return {
    //                   ...row,
    //                   [MHWLUtils.MHWLTableFields.MH_FY]: orgCF?.fiscalYearOne,
    //                   [MHWLUtils.MHWLTableFields.MH_FY_1]: orgCF?.fiscalYearTwo,
    //                   [MHWLUtils.MHWLTableFields.MH_FY_2]: orgCF?.fiscalYearThree,
    //                   [MHWLUtils.MHWLTableFields.WL_FY]: orgCF?.fiscalYearOne,
    //                   [MHWLUtils.MHWLTableFields.WL_FY_1]: orgCF?.fiscalYearTwo,
    //                   [MHWLUtils.MHWLTableFields.WL_FY_2]: orgCF?.fiscalYearThree,
    //                 }
    //               }
    //             }
    //           }
    //           return row
    //         })
    //       }
    //       setMHWLTableRows(() => tmpMHWLTableRows)
    //       pageNumber.current = 0
    //       setSelectedTableRows(() => tmpMHWLTableRows.slice(0, 100))
    //       setShowSnackbarSuccess(true)
    //       setSnackbarMessage("Successfully executed Man Hours Models!")
    //     }
    //   })
    //   .catch((e) => {
    //     setShowSnackbarError(true)
    //     if (e?.response?.status === 504) {
    //       setSnackbarMessage(
    //         "There was server timeout. The Man Hours Models may have executed successfully. You can check this by waiting a minute then refreshing your page."
    //       )
    //       return
    //     }
    //     const errMsg = returnErrorMessageFromAPIError(e)
    //     if (errMsg) {
    //       setSnackbarMessage(errMsg)
    //     } else {
    //       setSnackbarMessage("Unable to run Man Hours Models, please try again later")
    //     }
    //   })
    //   .finally(() => {
    //     setShowLoadingSpinner(false)
    //   })
  }

  const handlePageChange = (pgNumber) => {
    if (pgNumber < 0) pgNumber = 0
    pageNumber.current = pgNumber
    const totalRows = gridRows.length
    const startRow = pgNumber * 100
    const endRow = Math.min((pgNumber + 1) * 100, totalRows)
    const rows = gridRows.slice(startRow, endRow)
    setSelectedGridRows(rows)
  }

  // ** --------------***     [ ~ RENDER ~ ]     ***-------------- **
  return (
    <div className="--RFI-container">
      {/* TITLE CARD */}
      <CustomCard
        header="Man Hour/Weighted Lines/ABM Models"
        styles={{ width: "100%" }}
      />

      {/* RFI Table Actions */}
      <MHWLBtns fetchData={fetchData} />

      {/* DATA GRID */}
      <div className="--RFI-table-container">
        <div style={{ width: "100%", minHeight: 550 }}>
          {selectedGridRows.length > 0 && (
            <MHWLGrid
              data={selectedGridRows}
              columns={gridCols}
            />
          )}
          <TableControls
            pageNumber={pageNumber.current}
            pageSize={100}
            numTotalTableRows={138}
            handleOnPageChange={handlePageChange}
          />
        </div>
      </div>

      {/* LOADING MODAL */}
      <UploadProgressModal
        open={loading}
        title={loadingMsg}
        loadingProgress={null}
      />

      {/* SNACKBAR */}
      <SnackbarMessages
        showSnackbarSuccess={showSnackbarSuccess}
        setShowSnackbarSuccess={setShowSnackbarSuccess}
        showSnackbarError={showSnackbarError}
        setShowSnackbarError={setShowSnackbarError}
        snackbarMessage={snackbarMessage}
      />
    </div>
  )
}

export default ManHourWeightedLinesModelView
